import type { ChangeEvent, FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './TenderItemsFooter.module.scss';
import type {
	ExportItemProductType,
	ExportToSapBodyDataType
} from '../../interfaces/ExportToSap.types';
import type { TenderItem } from '../../interfaces/UserRefinement2.types';
import type { RootState } from '../../state';
import {
	selectIsExportColumnOn,
	toggleTenderItemsTreeEditingPrice,
	toggleTenderItemsTreeEditingSuppliers,
	toggleTenderItemsTreeExportColumn,
	useAppDispatch,
	useFetchExportsQuery,
	addSupplierForMultiselection,
	useCreateVersionMutation,
	useCallPowerAutomateMutation,
	useCreateSupplierAssignmentMutation,
	useDeleteSupplierAssignmentMutation
} from '../../state';
import { useEditProductProposalsMutation } from '../../state/supplierProductProposal/supplierProductProposal';
import { selectTenderItems } from '../../state/tenderItems/tenderItemsState';
import {
	cancelSetSuppliers,
	saveSuppliers,
	selectTemporarySuppliersForAdding,
	selectTemporarySuppliersForDeletion
} from '../../state/tenderItems/tenderItemsSuppliersState';
import type { ProductForTableType } from '../../types';
import { dropdownTypesOptions } from '../../utils/constants';
import stringToPrecisionFloat from '../../utils/stringToPrecisionFloat';
import Button from '../Button';
import Dropdown from '../Dropdown';
import Input from '../Input';

const TenderItemsFooter = ({
	contentUUID,
	fileUUID
}: {
	contentUUID: string;
	fileUUID: string;
}) => {
	const { t } = useTranslation();
	const [isOpenSetPrice, setIsOpenSetPrice] = useState<boolean>(false);
	const [isOpenSetSuppliers, setIsOpenSetSuppliers] = useState<boolean>(false);
	const [createVersion] = useCreateVersionMutation();
	const [createSupplier, { isLoading: isCreateSupplierLoading }] =
		useCreateSupplierAssignmentMutation();
	const [deleteSupplier, { isLoading: isDeleteSupplierLoading }] =
		useDeleteSupplierAssignmentMutation();
	const { projectId } = useParams();
	const tenderItemsData = useSelector((state: RootState) => selectTenderItems(state, contentUUID));
	const temporarySuppliersForAdding = useSelector(selectTemporarySuppliersForAdding);
	const temporarySuppliersForDeletion = useSelector(selectTemporarySuppliersForDeletion);
	const [callPowerAutomate] = useCallPowerAutomateMutation();

	const { data: exportVersions } = useFetchExportsQuery({ id: projectId }, { skip: !projectId });
	const currentExportSelectedVersion = useSelector(selectIsExportColumnOn);

	const dispatch = useAppDispatch();

	const removeExportFromLocalStorage = () => {
		const turnedColumns = localStorage.getItem(`tenderItemColumns`)?.split(',')!;
		const columnsWithoutExport = turnedColumns.filter((column) => column !== 'export');
		localStorage.setItem(`tenderItemColumns`, columnsWithoutExport.join(','));
	};

	const onCancelExportAnSap = () => {
		dispatch(toggleTenderItemsTreeExportColumn(undefined));
		removeExportFromLocalStorage();
		window.dispatchEvent(new Event('storage'));
	};

	useEffect(
		() => {
			const removeExportIfThere = () => {
				removeExportFromLocalStorage();
			};
			window.addEventListener('beforeunload', removeExportIfThere);
			return () => {
				onCancelExportAnSap();
				dispatch(toggleTenderItemsTreeEditingSuppliers(false));
				dispatch(toggleTenderItemsTreeEditingPrice(false));
				window.removeEventListener('beforeunload', removeExportIfThere);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const [editProduct, { isLoading: isEditProductLoading }] = useEditProductProposalsMutation();

	const selectExportedRow = (id: string) => {
		const row = document.getElementById(id) as HTMLTableRowElement;
		if (row) {
			row.click();
		} else {
			const tender = tenderItemsData.data[id];
			selectExportedRow(tender.parentItem);
		}
	};

	const onChangeVersion = (evt: ChangeEvent<HTMLInputElement>) => {
		const selectedVersion = exportVersions
			?.find((version) => version.id === evt.target.value)
			?.exportItems?.map((item) => item.userItemDocRef);
		if (selectedVersion) {
			selectExportedRow(selectedVersion[0]);
		}
		dispatch(toggleTenderItemsTreeExportColumn(selectedVersion));
	};

	const onClickSetPrice = () => {
		setIsOpenSetPrice(!isOpenSetPrice);
		dispatch(toggleTenderItemsTreeEditingPrice(!isOpenSetPrice));
	};

	const onClickCancel = () => {
		dispatch(cancelSetSuppliers());
		dispatch(
			addSupplierForMultiselection({
				type: 'RESET'
			})
		);
		setIsOpenSetSuppliers(!isOpenSetSuppliers);
		dispatch(toggleTenderItemsTreeEditingSuppliers(!isOpenSetSuppliers));
	};

	const onCancel = () => {
		dispatch(
			addSupplierForMultiselection({
				type: 'RESET'
			})
		);
	};

	const onClickSetSuppliers = () => {
		setIsOpenSetSuppliers(!isOpenSetSuppliers);
		dispatch(toggleTenderItemsTreeEditingSuppliers(!isOpenSetSuppliers));
		if (isOpenSetSuppliers) {
			onCancel();
		}
	};

	const onTriggerSetPrice = (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		const inputIds = ['price', 'rebateGroup', 'rebate1', 'rebate2', 'rebate3', 'quoteId'];
		if (isOpenSetPrice) {
			const treeButtons = Array.from(document.querySelectorAll('button[id]')).filter(
				(button) => button.id.includes('priceType') || button.id.includes('positionType')
			);
			const treeInputs = Array.from(document.querySelectorAll('input[id]')).filter((input) =>
				inputIds.includes(input.id.split(' ')[0])
			);
			const userItemRefinements = [treeButtons, treeInputs].flat().reduce((acc, curr) => {
				const [field, productId, tenderItemId, supplierProductId] = curr.id.split(' ');
				let fieldName = field;
				let fieldValue: string | number = curr.firstChild
					? (curr.firstChild as HTMLSpanElement).innerText
					: (curr as HTMLInputElement).value || (curr as HTMLInputElement).defaultValue;
				switch (field) {
					case 'positionType': {
						fieldValue = fieldValue.slice(-4);
						break;
					}
					case 'productPrice': {
						fieldName = 'supplierPrice';
						fieldValue = stringToPrecisionFloat(fieldValue);
						break;
					}
					case 'priceType': {
						fieldName = 'supplierPriceType';
						fieldValue = fieldValue.startsWith('N') ? 'NET' : 'GROSS';
						break;
					}
					case 'productRebate1':
					case 'productRebate2':
					case 'productRebate3': {
						fieldValue = stringToPrecisionFloat(fieldValue);
						break;
					}
					default:
						break;
				}

				// eslint-disable-next-line no-param-reassign
				acc = {
					...acc,
					[tenderItemId]: {
						// @ts-ignore
						...acc[tenderItemId],
						[productId]: {
							// @ts-ignore
							...(acc?.[tenderItemId]?.[productId] || {}),
							[fieldName]: fieldValue,
							supplierProductId,
							tid: productId
						}
					}
				};
				return acc;
			}, {});
			// TODO: refactor when BE is ready
			const userRefinementProducts = Object.keys(userItemRefinements)
				.map((key) => {
					// @ts-ignore
					const values = Object.values(userItemRefinements[key]).map((obj: any) => ({
						...obj,
						positionType: dropdownTypesOptions.positionType.find(
							(type) => type.id === obj.positionType
						)?.value
					}));

					return values;
				})
				.flat();

			userRefinementProducts.forEach(({ supplierProductId, priceType, ...product }) => {
				editProduct({
					body: { ...product },
					contentUUID
				});
			});
		}
		onClickSetPrice();
	};

	const onTriggerSetSuppliers = (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		if (isOpenSetSuppliers) {
			if (temporarySuppliersForDeletion.length) {
				temporarySuppliersForDeletion.forEach((supplier) => {
					deleteSupplier(supplier)
						.unwrap()
						.catch(() => {
							toast.error('error');
							cancelSetSuppliers();
						});
				});
			}
			Object.keys(temporarySuppliersForAdding).forEach((key: string) => {
				const suppliers = temporarySuppliersForAdding[key] as any;
				const tender = tenderItemsData.data[key];
				suppliers.forEach(async (supplier: any) => {
					const body = {
						contentUUID,
						itemRefinement: { tid: tender.refinementId },
						listOrderIndex: supplier.listOrderIndex,
						supplierId: supplier.supplierId,
						supplierName: supplier.supplierName,
						tenderId: key
					};
					createSupplier(body)
						.unwrap()
						.catch(() => {
							toast.error('error');
							cancelSetSuppliers();
						});
				});
			});
			dispatch(saveSuppliers());
		}
		onClickSetSuppliers();
	};

	useEffect(() => {
		const triggerSetPrice = (() => {
			if (isOpenSetSuppliers) {
				setIsOpenSetPrice(false);
			}
		}) as EventListener;
		document.body.addEventListener('triggerSetPrice', triggerSetPrice);
		return () => {
			document.body.removeEventListener('triggerSetPrice', triggerSetPrice);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCreateNewVersion = (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		const form = evt.target as HTMLFormElement;
		const versionName = form.versionName.value || new Date();

		if (!currentExportSelectedVersion?.length || !currentExportSelectedVersion[0]) {
			toast.warning(t('onePager.createVersionWarning'), { toastId: 'createVersionWarning' });
			return;
		}

		const getType = (item: ProductForTableType | TenderItem | undefined) => {
			if ((item as any).product) {
				return 'PRODUCT';
			}

			if ((item as TenderItem)?.isTenderGroup) {
				return 'GROUP';
			}

			return 'ITEM';
		};

		const bodyData: ExportToSapBodyDataType = {
			createdBy: localStorage.getItem('HULK-CURRENT-NTUSER')!,
			exportDate: new Date(),
			exportItems: currentExportSelectedVersion.map((id: string) => {
				const item = tenderItemsData?.data[id];
				const parent = tenderItemsData?.data[item.parentItem];
				const typeOfItem = getType(tenderItemsData?.data[id]);
				if (typeOfItem === 'PRODUCT') {
					return {
						getxMLIdOFPosition: parent.xmlId,
						longText: item.longText,
						materialNumber: item.productId,
						quantity: item.quantity,
						shortText: item.shortText,
						supplierId: '',
						tTLineItemType: item.positionType,
						tTSupplierContact: '',
						tTSupplierMaterialNumber: item.materialCategory,
						tTSupplierPrice: item.price,
						tTSupplierPriceType: item.supplierPriceType,
						tTSupplierQuoteDate: item.quoteDate,
						tTSupplierQuoteId: item.quoteId,
						tTSupplierRebate1: item.rebate1,
						tTSupplierRebate2: item.rebate2,
						tTSupplierRebate3: item.rebate3,
						tTSupplierRebateGroup: item.rebateGroup,
						tTSupplierTradingGroup: item.tradingGroup,
						typeOfItem,
						unitOfMeasure: item.unit,
						userItemDocRef: id
					} as unknown as ExportItemProductType;
				}
				return {
					typeOfItem,
					userItemDocRef: id
				};
			}),
			name: versionName,
			projectIdRef: projectId!
		};

		createVersion(bodyData)
			.unwrap()
			.then((response) => {
				document.getElementById('tenderItemsContainer')?.setAttribute('selectedIds', '');
				callPowerAutomate({
					body: {
						csvExportName: response.name,
						environment: process.env.REACT_APP_ENVIRONMENT,
						gaebExportFileUUID: fileUUID
					},
					context: projectId,
					useCase: 'exportCSVFileToSAP'
				});
				onCancelExportAnSap();
			});
	};

	const onClickExportAnSap = () => {
		dispatch(toggleTenderItemsTreeExportColumn([]));
		setIsOpenSetPrice(false);
		const turnedColumns = localStorage.getItem(`tenderItemColumns`)?.split(',')!;
		turnedColumns.push('export');
		localStorage.setItem(`tenderItemColumns`, turnedColumns.join(','));
		window.dispatchEvent(new Event('storage'));
	};

	const versionOptions = exportVersions?.length
		? exportVersions.map((version) => ({
				id: version.id,
				value: version.name
			}))
		: [];

	useEffect(() => {
		const removeAllSuppliers = (() => {
			setIsOpenSetSuppliers(false);
			dispatch(toggleTenderItemsTreeEditingSuppliers(false));
		}) as EventListener;
		document.body.addEventListener('removeAllSuppliers', removeAllSuppliers);
		return () => {
			document.body.removeEventListener('removeAllSuppliers', removeAllSuppliers);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<footer className={styles.tenderItemsFooterWrapper}>
			{!currentExportSelectedVersion && !isOpenSetPrice && (
				<form onSubmit={onTriggerSetSuppliers}>
					{isOpenSetSuppliers && (
						<Button
							disabled={isCreateSupplierLoading || isDeleteSupplierLoading}
							id="cancelSetSuppliers"
							onClick={onClickCancel}
							optionalClass={styles.tenderItemsFooterButton}
							text={t('common.cancelButtonText')}
							variant="cta"
						/>
					)}
					<Button
						disabled={isOpenSetSuppliers && (isCreateSupplierLoading || isDeleteSupplierLoading)}
						optionalClass={styles.tenderItemsFooterButton}
						text={isOpenSetSuppliers ? t('onePager.save') : t('onePager.setSuppliers')}
						type="submit"
						variant="cta"
					/>
				</form>
			)}
			<hr />
			{currentExportSelectedVersion && (
				<form
					className={styles.tenderItemsFooterExportForm}
					id="export2SAP"
					onSubmit={onCreateNewVersion}
				>
					<Button
						onClick={onCancelExportAnSap}
						optionalClass={styles.tenderItemsFooterButton}
						text={t('common.cancelButtonText')}
						type="button"
						variant="cta"
					/>
					<Dropdown
						data={versionOptions}
						direction="up"
						inactive={!versionOptions.length}
						label=""
						name="previousVersions"
						onChange={onChangeVersion}
						placeholder={t('onePager.previousVersions')}
					/>
					<Input bordered name="versionName" />
					<Button
						form="export2SAP"
						optionalClass={styles.tenderItemsFooterButton}
						text={t('onePager.createVersion')}
						type="submit"
						variant="cta"
					/>
				</form>
			)}
			{!isOpenSetPrice && !currentExportSelectedVersion && (
				<>
					<Button
						onClick={onClickExportAnSap}
						optionalClass={styles.tenderItemsFooterButton}
						text={t('onePager.exportAnSAP')}
						variant="cta"
					/>
					<hr />
				</>
			)}
			{!currentExportSelectedVersion && (
				<form onSubmit={onTriggerSetPrice}>
					{isOpenSetPrice && (
						<Button
							onClick={onClickSetPrice}
							optionalClass={styles.tenderItemsFooterButton}
							text={t('common.cancelButtonText')}
							variant="cta"
						/>
					)}
					<Button
						inactive={isEditProductLoading}
						optionalClass={styles.tenderItemsFooterButton}
						text={isOpenSetPrice ? t('onePager.save') : t('inquires.setPrice')}
						type="submit"
						variant="cta"
					/>
				</form>
			)}
			{/* This exists here because we cannot show full dropdown in products due to the natural stacking order of DOM elements */}
			<div id="divForDropdownPortal" style={{ position: 'fixed' }} />
		</footer>
	);
};
export default TenderItemsFooter;
