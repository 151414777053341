import { createApi } from '@reduxjs/toolkit/query/react';

import type { UserSettingsType } from '../../interfaces/UserSettings.types';
import { USER_SETTINGS, baseQuery } from '../stateUtils';

const userSettingsApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createUserSettingsProperties: builder.mutation<
				UserSettingsType,
				{ body: UserSettingsType; userId: string; propertyName: string }
			>({
				query: ({ body, userId, propertyName }) => ({
					body,
					method: 'POST',
					url: `${USER_SETTINGS}property-settings/${userId}/${propertyName}`
				})
			}),
			editUserSettingsProperties: builder.mutation<
				UserSettingsType,
				{ body: UserSettingsType; userId: string; propertyName: string }
			>({
				query: ({ body, userId, propertyName }) => ({
					body,
					method: 'PUT',
					url: `${USER_SETTINGS}property-settings/${userId}/${propertyName}`
				})
			}),
			fetchUserSettingsProperties: builder.query<
				UserSettingsType,
				{ userId: string; propertyName: string }
			>({
				query: ({ userId, propertyName }) => ({
					url: `${USER_SETTINGS}property-settings/${userId}/${propertyName}`
				})
			})
		};
	},
	reducerPath: 'userSettings'
});

export const {
	useCreateUserSettingsPropertiesMutation,
	useEditUserSettingsPropertiesMutation,
	useFetchUserSettingsPropertiesQuery
} = userSettingsApi;

export const { select: selectFetchedUserSettingsProperties } =
	userSettingsApi.endpoints.fetchUserSettingsProperties;

export { userSettingsApi };
