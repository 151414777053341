import classNames from 'classnames';
import type { KeyboardEvent } from 'react';
import { useRef } from 'react';

import styles from './Checkbox.module.scss';
import type { CheckboxType } from '../../types';
import Icon from '../Icon';
import InformationText from '../InformationText';

const Checkbox = ({ error, label, name, optionalClass, ...rest }: CheckboxType) => {
	const checkboxLabelRef = useRef<HTMLLabelElement>(null);
	const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
		if (checkboxLabelRef.current && ['Enter', 'Space'].includes(e.code)) {
			checkboxLabelRef.current.click();
		}
	};

	return (
		<div className={classNames(styles.checkboxWrapper, optionalClass)}>
			<input id={name} type="checkbox" {...rest} />
			<label ref={checkboxLabelRef} className={styles.label} htmlFor={name}>
				<div
					aria-label="Checkbox"
					className={classNames(styles.customCheckbox, { [styles.error]: error })}
					onKeyUp={onKeyUp}
					role="button"
					tabIndex={0}
				>
					<Icon
						height="16px"
						name="checkmark"
						optionalClass={styles.checkedIcon}
						variant="white"
						width="16px"
					/>
				</div>
				<span className={styles.labelText}>{label}</span>
			</label>
			{error && <InformationText error text={error} />}
		</div>
	);
};

export default Checkbox;
