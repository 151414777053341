import { useEffect } from 'react';

import type { UseOnClickHandlerArgsType } from '../types';

function useOnClickHandler({
	ref,
	outsideHandler,
	insideHandler
}: UseOnClickHandlerArgsType): void {
	useEffect(() => {
		const listener = (event: MouseEvent) => {
			if (!!ref.current && ref.current.contains(event.target as Node) && insideHandler) {
				insideHandler();
			} else if (!!ref.current && outsideHandler) {
				outsideHandler(event);
			}
		};

		document.addEventListener('mousedown', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
		};
	}, [ref, outsideHandler, insideHandler]);
}

export default useOnClickHandler;
