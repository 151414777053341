import classNames from 'classnames';
import debounce from 'lodash.debounce';
import type { MouseEvent } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { NodeApi, TreeApi } from 'react-arborist';
import { Tree } from 'react-arborist';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './TenderTree.module.scss';
import type {
	TenderItem,
	UserItemRefinementSupplier
} from '../../interfaces/UserRefinement2.types';
import getRoutes from '../../routes';
import { toggleMultiselection, useDeleteSupplierAssignmentBulkMutation } from '../../state';
import { removeAllSuppliers } from '../../state/tenderItems/tenderItemsSuppliersState';
import type { ProductForTableType, TenderTreeFiltersType } from '../../types';
import compose from '../../utils/composeUtilFunction';
import findDataOccurrence from '../../utils/findDataOccurence';
import findFirstFilteredTenderItem from '../../utils/findFirstFilteredTenderItem';
import Checkbox from '../Checkbox';
import SearchRecords from '../SearchRecords';
import tableStyles from '../Table/Table.module.scss';
import TenderItemsTableHeader from '../TenderItemsTableHeader';

export function setCheckboxVisibility(visibility: 'hidden' | 'visible', checked: boolean = false) {
	const checkbox = document.querySelector('#tenderCheckbox') as HTMLInputElement;
	const checkboxWrapper = checkbox?.parentElement as HTMLDivElement;
	if (checkboxWrapper) {
		if (checkboxWrapper.style.getPropertyValue('visibility') !== visibility) {
			if (checkbox.checked !== checked) {
				checkbox.checked = checked;
			}
			checkboxWrapper.style.visibility = visibility;
		} else {
			checkbox.checked = checked;
		}
	}
}

const TenderTree = ({
	initialData,
	renderer,
	selector
}: {
	// We need to have any here since renderer and selector functions can be widely defined
	initialData: any;
	renderer: any;
	selector: any;
}) => {
	const { t } = useTranslation();
	const treeRef = useRef<TreeApi<TenderItem | ProductForTableType>>(null);
	const tableRef = useRef<HTMLTableElement>(null);
	const [treeWidth, setTreeWidth] = useState<number>(0);
	const { ONE_PAGER_AI_ANALYSIS, PROJECTS } = getRoutes(t);
	const { tenderItemId, projectId } = useParams();
	const [foundItems, setFoundItems] = useState<number>(0);
	const [selectedColumns, setSelectedColumns] = useState<string[]>(
		localStorage.getItem(`tenderItemColumns`)?.split(',') ?? []
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [filters, setFilters] = useState<TenderTreeFiltersType>({
		booleanFilter: '',
		searchTerm: '',
		suppliers: []
	});
	const data = useSelector(selector);

	const [key, setKey] = useState<number>(0);

	const checkbox = document.querySelector('#tenderCheckbox') as HTMLInputElement;

	// 1. useEffect is here due to the strange bug of first item disappearing on return to this screen after navigating out
	useEffect(() => {
		const timeout = setTimeout(() => setKey(1), 50);

		return () => clearTimeout(timeout);
	}, []);

	const debounceUpdateWidthOfTree = () => {
		const tenderTreeTable = document.getElementById('treeOfTenderItems') as HTMLTableElement;
		setTimeout(() => {
			setTreeWidth(tenderTreeTable?.clientWidth);
			const activeColumns = localStorage.getItem(`tenderItemColumns`)?.split(',') ?? [];
			setSelectedColumns(activeColumns);
		}, 500);
	};

	// 2. useEffect for calculating width of tree
	useLayoutEffect(() => {
		if (tableRef?.current?.clientWidth) {
			window.addEventListener('storage', debounceUpdateWidthOfTree);
			window.addEventListener('resize', debounceUpdateWidthOfTree);
			debounceUpdateWidthOfTree();
		}
		return () => {
			window.removeEventListener('storage', debounceUpdateWidthOfTree);
			window.addEventListener('resize', debounceUpdateWidthOfTree);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const returnIfContainsSearchWord = (node: NodeApi) => {
		const { searchTerm } = filters;
		return node &&
			(node.data.shortText?.toLowerCase().includes(searchTerm) ||
				node.data.longText?.toLowerCase().includes(searchTerm) ||
				(!node?.parent?.data?.isTenderGroup &&
					node?.parent?.data?.shortText?.toLowerCase().includes(searchTerm)) ||
				node?.parent?.data?.longText?.toLowerCase().includes(searchTerm))
			? node
			: false;
	};

	const returnIfHasCorrectStatus = (node: NodeApi) => {
		const { booleanFilter } = filters;
		const statusArray =
			booleanFilter === 'unrefined' ? ['OPEN', 'IN_PROGRESS'] : ['DECLINED', 'CONFIRMED'];
		if (
			node &&
			node.data.status === 'DECLINED' &&
			!node.data.isTenderGroup &&
			!node.parent?.data.isTenderGroup
		) {
			return false;
		}

		return node && statusArray.includes(node.data.status) ? node : false;
	};

	const returnIfHasCorrectSupplier = (node: NodeApi) => {
		const { suppliers } = filters;

		if (node.data.status === 'DECLINED') {
			return false;
		}

		if (
			node.data.userItemRefinementSuppliers?.some((supplier: any) =>
				suppliers.includes(supplier.label)
			)
		) {
			return node;
		}

		return node &&
			!node?.parent?.data.isTenderGroup &&
			node?.parent?.data.userItemRefinementSuppliers?.some((supplier: any) =>
				suppliers.includes(supplier.label)
			)
			? node
			: false;
	};

	useEffect(() => {
		const removeAllSuppliers = (() => {
			setFilters({ ...filters, suppliers: [] });
		}) as EventListener;
		document.body.addEventListener('removeAllSuppliers', removeAllSuppliers);
		return () => {
			document.body.removeEventListener('removeAllSuppliers', removeAllSuppliers);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// 3. useEffect for filtering items in tree based on filtering button state
	useEffect(() => {
		const filterItemsListenerFunction = ((e: CustomEvent) => {
			const { id, type, value } = e.detail;
			if (id === 'tenderItemsSupplierFilter') {
				if (type.includes('reset')) {
					setFilters({ ...filters, suppliers: [] });
					return;
				}
				const checkedSuppliers = document.querySelectorAll(
					'#tenderItemsSupplierFilter input:checked'
				);
				const suppliers = Array.from(checkedSuppliers).map((input: any) => input.id);

				setFilters({
					...filters,
					suppliers
				});
			} else {
				const newTreeItem = findFirstFilteredTenderItem((data as any).treeData, { [type]: value });
				setFilters({
					...filters,
					booleanFilter: value ? type : ''
				});

				if (newTreeItem) {
					navigate(
						`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${(newTreeItem as TenderItem).tid}`
					);
				}
			}
		}) as EventListener;

		document.body.addEventListener('filteringTenderItems', filterItemsListenerFunction);

		if (tenderItemId && (data as any)?.data) {
			treeRef.current?.select(tenderItemId);
			treeRef.current?.scrollTo(tenderItemId, 'center');
		}

		return () => {
			document.body.removeEventListener('filteringTenderItems', filterItemsListenerFunction);
		};
		// no need for the navigateTo to be a dependency for this
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, filters]);

	const searchNodes = (node: NodeApi) => {
		const { booleanFilter, searchTerm, suppliers } = filters;
		const functionsToCompose = [];

		if (searchTerm) {
			functionsToCompose.push(returnIfContainsSearchWord);
		}
		if (booleanFilter) {
			functionsToCompose.push(returnIfHasCorrectStatus);
		}
		if (suppliers.length) {
			functionsToCompose.push(returnIfHasCorrectSupplier);
		}
		const composedFunction = compose(...functionsToCompose);

		return functionsToCompose.length ? composedFunction(node) : node;
	};

	const onTenderItemSearch = debounce((searchQuery: string) => {
		setFoundItems(0);
		setFilters({
			...filters,
			searchTerm: searchQuery.toLowerCase()
		});
	}, 500);
	const tableWrapper = document.getElementById('tenderItemsContainer');

	const tableHeight = (tableWrapper?.clientHeight ?? 0) - 104;

	const areFiltersActive = () =>
		filters.suppliers.length > 0 || !!filters.searchTerm || filters.booleanFilter;

	const onClickCheckbox = (evt: MouseEvent<HTMLButtonElement>) => {
		evt.stopPropagation();
		evt.preventDefault();
		const visibleNodes = treeRef.current?.visibleNodes;

		if (checkbox.checked) {
			treeRef.current?.deselectAll();
		} else {
			treeRef.current?.deselectAll();
			visibleNodes?.forEach((visibleNode) => {
				if (
					!(visibleNode.data as TenderItem).isTenderGroup &&
					!(visibleNode.data as ProductForTableType).product
				) {
					treeRef.current?.selectMulti(visibleNode.id);
				}
			});
			checkbox.checked = true;
		}
	};

	const getVisiblePositions = (visibleNodes: NodeApi[]): NodeApi[] =>
		visibleNodes.filter((node) => node.level === 2);

	const onSelect = (nodes: NodeApi<TenderItem | ProductForTableType>[]) => {
		dispatch(toggleMultiselection({ data: nodes.map((node) => node.data), type: 'SELECT' }));
		if (treeRef.current) {
			const { selectedIds, visibleNodes } = treeRef.current;
			const positions = getVisiblePositions(visibleNodes);
			if (nodes.length) {
				if (nodes[0].level !== 2 && !positions.length) {
					setCheckboxVisibility('hidden');
					return;
				}
				setCheckboxVisibility('visible');
				const isExist = positions.every((position) => selectedIds.has(position.id));
				if (isExist && checkbox) {
					checkbox.checked = true;
					return;
				}

				if (
					checkbox?.checked === true ||
					nodes.length <= 1 ||
					nodes.find((node) => (node.data as TenderItem).isTenderGroup)
				) {
					checkbox.checked = false;
				}
			} else if (positions.length) {
				setCheckboxVisibility('visible');
			}
		}
	};

	const onToggle = (id: string) => {
		if (treeRef.current) {
			const { root, selectedIds, visibleNodes } = treeRef.current;
			const row = root.tree.get(id);
			if (row) {
				const { level, children, isClosed } = row;
				if (children) {
					const visiblePositions = getVisiblePositions(visibleNodes);
					// CHECKING IF IS TOGGLED ROW GROUP THAT CONTAINS POSITIONS
					if (level === 1) {
						setCheckboxVisibility('visible');
						if (isClosed) {
							// DESELECTING EACH CHILD FROM TOGGLED ROW
							children.forEach((child) => {
								treeRef.current?.deselect(child.id);
								selectedIds.delete(child.id);
							});

							// CHECKING IF ALL GROUPS ARE CLOSED IN ORDER TO HIDE CHECKBOX
							if (visiblePositions.length <= children.length) {
								setCheckboxVisibility('hidden');
							} else {
								const selected = visiblePositions.find((position) => selectedIds.has(position.id));
								if (selected && selectedIds.size >= visiblePositions.length - children.length) {
									checkbox.checked = true;
								}
							}
						} else {
							checkbox.checked = false;
						}
					} else if (level === 0) {
						if (isClosed) {
							let openedChildrenCount = 0;
							row.children?.forEach((child) => {
								if (child.isOpen && child.children) {
									openedChildrenCount += child.children.length;
									child.close();
								}
							});

							if (visiblePositions.length === openedChildrenCount) {
								setCheckboxVisibility('hidden');
							}
						}
					}
				}
			}
		}
	};

	function handleCheckboxVisibiltyOnFiltering() {
		if (treeRef.current) {
			const { visibleNodes, selectedIds } = treeRef.current;
			if (checkbox) {
				const visiblePositions = getVisiblePositions(visibleNodes);
				if (!visiblePositions.length || !selectedIds.size) {
					setCheckboxVisibility('hidden');
				} else {
					const areAllPositionsSelected = visiblePositions.every(
						(position) => treeRef.current?.selectedIds.has(position.id)
					);
					setCheckboxVisibility('visible');
					if (!areAllPositionsSelected) {
						checkbox.checked = false;
					} else {
						checkbox.checked = true;
					}
				}
			}
		}
	}

	useEffect(() => {
		if (treeRef.current) {
			if (!treeRef.current.visibleNodes.length && (data as any)?.treeData?.length) {
				setFilters({ ...filters, suppliers: [] });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (!filters.booleanFilter) {
			const value = findDataOccurrence(
				treeRef.current as TreeApi<TenderItem>,
				data as any,
				filters
			);
			setFoundItems(value.allItems);
			const setTotalItemsEvent = new CustomEvent('setTotalItems', {
				detail: {
					value
				}
			});
			document.body.dispatchEvent(setTotalItemsEvent);
		}

		handleCheckboxVisibiltyOnFiltering();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, filters]);

	// 3. useEffect for filtering items in tree based on filtering button state
	useEffect(() => {
		const filterItemsListenerFunction = ((e: CustomEvent) => {
			const { id, type, value } = e.detail;
			if (!id) {
				const newTreeItem = findFirstFilteredTenderItem((data as any).treeData, { [type]: value });
				setFilters({
					...filters,
					booleanFilter: value ? type : ''
				});

				if (newTreeItem) {
					navigate(
						`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${(newTreeItem as TenderItem).tid}`
					);
				}
			}
		}) as EventListener;

		document.body.addEventListener('filteringTenderItems', filterItemsListenerFunction);

		if (tenderItemId && data) {
			treeRef.current?.scrollTo(tenderItemId, 'auto');
		}

		return () => {
			document.body.removeEventListener('filteringTenderItems', filterItemsListenerFunction);
		};
		// no need for the navigateTo to be a dependency for this
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, filters]);

	const emptyHeaderCell = document.querySelector('#emptyTableHeaderCell');
	const [deleteSuppliers] = useDeleteSupplierAssignmentBulkMutation();

	const deleteSuppliersInBulk = (
		suppliers: UserItemRefinementSupplier[],
		tenderId: string,
		tenderItemId: string
	) => {
		deleteSuppliers({
			supplierIds: suppliers.map((supplier) => supplier.tid),
			tenderId
		})
			.unwrap()
			.then(() => {
				dispatch(
					removeAllSuppliers({
						tenderItems: [tenderItemId]
					})
				);
			})
			.catch(() => {
				toast.error(t('common.submissionError'));
			});
	};

	useEffect(() => {
		const handleKeyPress = ((event: CustomEvent) => {
			const button = document.querySelector('#cancelSetSuppliers');
			if ((event as unknown as KeyboardEvent).key === 'Delete' && button && treeRef?.current) {
				const { selectedNodes } = treeRef.current;
				if (selectedNodes.length) {
					selectedNodes.forEach((node, index) => {
						deleteSuppliersInBulk(
							(node.data as TenderItem).userItemRefinementSuppliers,
							(node.data as TenderItem).refinementId!,
							node.id
						);
						if (selectedNodes.length - 1 === index) {
							const informAboutRemovingAllSuppliers = new CustomEvent('removeAllSuppliers');
							document.body.dispatchEvent(informAboutRemovingAllSuppliers);
						}
					});
				}
			}
		}) as EventListener;
		window.addEventListener('keydown', handleKeyPress);

		return () => window.removeEventListener('keydown', handleKeyPress);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className={styles.container} id="tenderItemsContainer">
			<button
				aria-label="Select All Products"
				className={styles.checkbox}
				onClick={onClickCheckbox}
				style={{ width: emptyHeaderCell?.clientWidth }}
				tabIndex={0}
				type="button"
			>
				<Checkbox name="tenderCheckbox" />
			</button>
			<table
				ref={tableRef}
				className={classNames(tableStyles.table, tableStyles.outerTable, styles.scrollableYTable)}
				id="treeOfTenderItems"
			>
				<thead>
					<TenderItemsTableHeader />
				</thead>
				<caption>
					<SearchRecords
						name="tenderItem"
						records={foundItems ? t('common.records', { count: foundItems }) : ''}
						setKeyword={onTenderItemSearch}
					/>
				</caption>
			</table>
			<div key={key} className={styles.treeWrapper}>
				<Tree
					ref={treeRef}
					data={initialData}
					disableDrag
					disableDrop
					disableEdit
					disableMultiSelection
					height={tableHeight}
					idAccessor="tid"
					indent={12}
					onSelect={onSelect}
					onToggle={onToggle}
					openByDefault={false}
					renderRow={renderer({
						selectedColumns,
						treeWidth
					})}
					rowHeight={32}
					searchMatch={searchNodes}
					searchTerm={areFiltersActive() ? 'filterTriggeringString' : ''}
					width={treeWidth ? `${treeWidth}px` : '100%'}
				/>
			</div>
		</div>
	);
};

export default TenderTree;
