import type { ChangeEvent } from 'react';
import { useRef } from 'react';

import styles from './SearchProjects.module.scss';
import globalStyles from '../../scss/global.module.scss';
import type { SearchProjectsType } from '../../types';
import Icon from '../Icon';
import Input from '../Input';

const SearchRecords = ({ setKeyword, records, ...rest }: SearchProjectsType) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		if (inputRef.current) {
			inputRef.current.value = evt.target.value;
			setKeyword(evt.target.value);
		}
	};

	const onClickRemove = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
			inputRef.current.focus();
			setKeyword('');
		}
	};

	return (
		<div className={styles.wrapper}>
			<div>
				<Icon height="35px" name="search" width="35px" />
			</div>

			<Input
				ref={inputRef}
				aria-label="SearchRecords"
				autoComplete="off"
				bordered={false}
				onChange={onChange}
				{...rest}
			/>

			<button
				aria-label="navigation_no-label"
				className={globalStyles.iconBtn}
				onClick={onClickRemove}
				type="button"
			>
				<Icon name="navigation_no" />
			</button>
			<span className={styles.records}>{records}</span>
		</div>
	);
};

export default SearchRecords;
