import type { Dispatch } from '@reduxjs/toolkit';
import type { NodeApi } from 'react-arborist';

import type { TenderItem } from '../interfaces';
import { toggleMultiselection } from '../state';

const handleCTRLKey = (node: NodeApi<TenderItem>, dispatch: Dispatch) => {
	if (node.tree.selectedIds.has(node.id)) {
		node.deselect();
		dispatch(toggleMultiselection({ data: node.data, type: 'DESELECT' }));
	} else {
		node.tree.selectMulti(node.id);
	}
};

export default handleCTRLKey;
