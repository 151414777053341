import classNames from 'classnames';

import styles from './Icon.module.scss';
import type { IconType } from '../../types';

const Icon = ({
	height = '24',
	name,
	optionalClass,
	title = '',
	variant = 'black',
	width = '24'
}: IconType) => (
	<svg className={classNames(styles[variant], optionalClass)} height={height} width={width}>
		{title && <title>{title}</title>}
		<use href={`/icons.svg#${name}`} />
	</svg>
);

export default Icon;
