import { useEffect, useState } from 'react';

import {
	useFetchAKTsQuery,
	useFetchCountriesQuery,
	useFetchProjectStatusQuery,
	useFetchTenderTypesQuery
} from '../../state';

const CachingComponent = () => {
	const [shouldFetch, setShouldFetch] = useState<boolean>(false);
	useFetchAKTsQuery({}, { skip: !shouldFetch });
	useFetchTenderTypesQuery({}, { skip: !shouldFetch });
	useFetchProjectStatusQuery({}, { skip: !shouldFetch });
	useFetchCountriesQuery({}, { skip: !shouldFetch });
	useEffect(() => {
		setTimeout(() => {
			setShouldFetch(true);
		}, 1000);
	}, []);

	return null;
};

export default CachingComponent;
