import { createApi } from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';

import type { PostalCode } from '../../interfaces';
import type { GenericObjectType } from '../../types';
import { baseQuery } from '../stateUtils';

export type PostalCodeAdressesType = {
	id: string;
	city: string;
	postalCode: string;
};

const postalCodesApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchCities: builder.query<PostalCode, string | undefined>({
				query: (city) => `/services/postcode/api/postcode/${city}`
			}),
			fetchPostalCode: builder.query<string[][], string | undefined>({
				query: (postalCode) => `/services/postcode/api/cities/${postalCode}`
			}),
			fetchPostalCodeAdresses: builder.query<
				{ byIds: GenericObjectType<PostalCodeAdressesType>; ids: string[] },
				{
					city: string;
					cip: string;
				}
			>({
				query: (query) =>
					query.city
						? `/services/postcode/api/postcode/${query.city}`
						: `/services/postcode/api/cities/${query.cip}`,
				transformResponse: (response) =>
					(response as string[]).reduce(
						(acc, curr) => {
							const id = uuidv4();
							acc.byIds[id] = { city: curr[1], id, postalCode: curr[0].toString() };
							acc.ids.push(id);
							return acc;
						},
						{
							byIds: {} as GenericObjectType<PostalCodeAdressesType>,
							ids: [] as string[]
						}
					)
			}),
			fetchPostalCodeAkt: builder.query<PostalCode, string | undefined>({
				query: (postalCode) => `/services/postcode/api/akt/${postalCode}`
			})
		};
	},
	reducerPath: 'postalCodes'
});

export const {
	useFetchPostalCodeQuery,
	useFetchCitiesQuery,
	useFetchPostalCodeAdressesQuery,
	useFetchPostalCodeAktQuery
} = postalCodesApi;

export const { select } = postalCodesApi.endpoints.fetchPostalCode;
export const { select: selectPostalCodeAdresses } =
	postalCodesApi.endpoints.fetchPostalCodeAdresses;

export { postalCodesApi };
