import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, SUPPLIER_PRODUCT_PROPOSAL } from '../stateUtils';
import { updateTenderItem } from '../tenderItems/tenderItemsState';

export interface ProductProposal {
	tid: string;
	supplierId: string;
	itemRefinementId: string;
	supplierProductId: string;
	productId: string;
	longText: string;
	shortText: string;
	positionType: string;
	quantity: number;
	unit: string;
	listOrderIndex: number;
	price: number;
	supplierPrice: number;
	supplierPriceType: string;
	rebateGroup: string;
	rebate1: number;
	rebate2: number;
	rebate3: number;
	quoteId: string;
	quoteDate: string;
	tradingGroup: string;
	proposal: {
		tid: string;
	};
}

const supplierProductProposalApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createProductProposals: builder.mutation<any, Omit<ProductProposal, 'tid'>>({
				invalidatesTags: ['SupplierProductProposals'],
				query: (body) => ({
					body,
					method: 'POST',
					url: `${SUPPLIER_PRODUCT_PROPOSAL}product-proposals`
				})
			}),
			deleteProductProposals: builder.mutation<string, string>({
				invalidatesTags: ['SupplierProductProposals'],
				query: (tid) => ({
					method: 'DELETE',
					url: `${SUPPLIER_PRODUCT_PROPOSAL}product-proposals/${tid}`
				})
			}),
			editProductProposals: builder.mutation<any, { contentUUID: string; body: any }>({
				async onQueryStarted({ contentUUID }, { queryFulfilled, getState, dispatch }) {
					try {
						const { data } = await queryFulfilled;
						// @ts-ignore
						const tenderItems = getState().tenderItems.tenderItems[contentUUID];
						const tenderItem = tenderItems.data[data.tid];
						const { supplierPriceType, priceType, ...rest } = tenderItem;
						dispatch(
							updateTenderItem({
								contentUUID,
								updated: {
									...rest,
									...data,
									priceType: data.supplierPriceType,
									supplierPriceType: data.supplierPriceType
								}
							})
						);
					} catch {
						//
					}
				},
				query: ({ body }) => ({
					body,
					method: 'PATCH',
					url: `${SUPPLIER_PRODUCT_PROPOSAL}product-proposals/${body.tid}`
				})
			}),
			fetchSupplierProductProposals: builder.query<any, string>({
				providesTags: ['SupplierProductProposals'],
				query: (id) => `${SUPPLIER_PRODUCT_PROPOSAL}proposals/ensureFor/${id}`
			})
		};
	},
	reducerPath: 'supplierProductProposal',
	tagTypes: ['SupplierProductProposals']
});

export const {
	useFetchSupplierProductProposalsQuery,
	useCreateProductProposalsMutation,
	useEditProductProposalsMutation,
	useDeleteProductProposalsMutation
} = supplierProductProposalApi;

export { supplierProductProposalApi };
