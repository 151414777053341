import classNames from 'classnames';

import styles from './Button.module.scss';
import type { ButtonType } from '../../types';

const Button = ({
	inactive,
	optionalClass,
	text,
	type = 'button',
	variant,
	...rest
}: ButtonType) => (
	<button
		className={classNames(styles.button, styles[variant], optionalClass)}
		/* eslint-disable-next-line react/button-has-type */
		type={type}
		{...rest}
	>
		{text}
	</button>
);

export default Button;
