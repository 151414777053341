import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import type { NodeApi } from 'react-arborist';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './TenderItemsAIRow.module.scss';
import useTenderRefinementId from '../../hooks/useTenderRefinementId';
import type { TenderItem, UserRefinementTree } from '../../interfaces';
import type { ProductAssigment } from '../../interfaces/UserRefinement2.types';
import getRoutes from '../../routes';
import type { RootState } from '../../state';
import {
	selectFetchedProject,
	selectMultiselectedTenders,
	useDeleteSupplierAssignmentBulkMutation,
	useEditItemRefinementMutation
} from '../../state';
import { useLazyFetchProductsForTenderQuery } from '../../state/productMasterData/productMasterDataSlice';
import { selectTenderItems } from '../../state/tenderItems/tenderItemsState';
import { removeAllSuppliers } from '../../state/tenderItems/tenderItemsSuppliersState';
import type { ProductForTableType } from '../../types';
import getProductsThreeDotsMenuOptions from '../../utils/getProductsThreeDotsMenuOptions';
import getTenderItemThreeDotsMenuOptions from '../../utils/getTenderItemThreeDotsMenuOptions';
import handleALTKey from '../../utils/handleTenderItemALTKey';
import handleCTRLKey from '../../utils/handleTenderItemCTRLKey';
import shouldRender3DotsMenu from '../../utils/shouldRender3DotsMenu';
import TenderItemsAiPredictionRow from '../TenderItemAiPredictionRow/TenderItemsAiPredictionRow';
import { setCheckboxVisibility } from '../TenderTree/TenderTree';
import ThreeDotsMenu from '../ThreeDotsMenu';
import TreeViewRow from '../TreeViewRow';

const TenderItemsAIRow = ({
	selectedColumns,
	treeWidth
}: {
	selectedColumns: string[];
	treeWidth: number;
}) => {
	const { t } = useTranslation();
	const { projectId, tenderItemId } = useParams();
	const { data: project } = useSelector(selectFetchedProject(projectId));
	const dispatch = useDispatch();
	const [openedItemID, setOpenedItemID] = useState<string>('');
	const { PROJECTS, ONE_PAGER_AI_ANALYSIS } = getRoutes(t);
	const contentUUID = project!.gaeb?.contentUUID || '';

	const { tenderRefinementId } = useTenderRefinementId();

	const [fetchProducts, { isLoading }] = useLazyFetchProductsForTenderQuery();

	const [editOneTenderItem, { isLoading: isEditingSingleItem }] = useEditItemRefinementMutation({
		fixedCacheKey: 'editingTenderItem'
	});
	const navigate = useNavigate();
	const tenderItemsData = useSelector((state: RootState) =>
		selectTenderItems(state, contentUUID as string)
	);
	const handleMultipleSelection = (
		evt: MouseEvent<HTMLDivElement>,
		node: NodeApi,
		holdingAltOrCTRLKey: boolean
	) => {
		if (!holdingAltOrCTRLKey) {
			if (node.tree.selectedIds.size === 1) {
				node.tree.deselectAll();
			}
			node.select();
		}

		if (evt.ctrlKey) {
			handleCTRLKey(node, dispatch);
		}

		if (evt.altKey) {
			handleALTKey(node, tenderItemsData as unknown as UserRefinementTree);
		}
	};

	const onClickTreeRow = (evt: MouseEvent<HTMLDivElement>, node: NodeApi) => {
		const holdingAltOrCTRLKey = evt.ctrlKey || evt.altKey;
		evt.preventDefault();
		if (
			holdingAltOrCTRLKey &&
			(node.tree.selectedNodes[0]?.data.isTenderGroup ||
				node.data.isTenderGroup ||
				node.data.product)
		) {
			toast.warning(t('toastify.warning.multiselection'));
			return;
		}

		if ((evt.target as HTMLElement).closest('div')?.getAttribute('role') !== 'button') {
			if (
				!(node.data as ProductForTableType).product &&
				node.id !== tenderItemId &&
				!holdingAltOrCTRLKey
			) {
				navigate(`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${node.id}`);
			}
		}
		if (!node.data.isTenderGroup && !node.data.product) {
			handleMultipleSelection(evt, node, holdingAltOrCTRLKey);
			if (!node.isSelected) {
				setCheckboxVisibility('visible');
			}
		} else if (node.isSelected) {
			node.deselect();
		} else {
			node.select();
		}
	};

	const tableWrapper = document.getElementById('tenderItemsContainer');

	const onClickToggle = (node: NodeApi) => (id: string) => {
		navigate(`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${id}`);
		setOpenedItemID(id);
		fetchProducts({
			contentUUID,
			docRefinementId: tenderRefinementId,
			ids: node.data.productAssigments
				.map((product: ProductAssigment) => product.productId)
				.join(','),
			tid: node.id
		});
	};

	const isEditingItem = (node: NodeApi) => {
		if (isEditingSingleItem) {
			return [node.data.id, node.data.parentUUID].includes(tenderItemId);
		}

		if (isLoading) {
			return node.id === tenderItemId || node.id === openedItemID;
		}

		return false;
	};

	const tableHeight = (tableWrapper?.clientHeight ?? 0) - 104;
	const [deleteSuppliers] = useDeleteSupplierAssignmentBulkMutation();
	const multiselectedTenders = useSelector(selectMultiselectedTenders);

	const deleteSuppliersInBulk = (suppliers: any, tenderId: string, tenderItemId: string) => {
		deleteSuppliers({
			supplierIds: suppliers.map((supplier: any) => supplier.tid),
			tenderId
		})
			.unwrap()
			.then(() => {
				dispatch(
					removeAllSuppliers({
						tenderItems: [tenderItemId]
					})
				);
				const informAboutRemovingAllSuppliers = new CustomEvent('removeAllSuppliers');
				document.body.dispatchEvent(informAboutRemovingAllSuppliers);
			})
			.catch(() => {
				toast.error(t('common.submissionError'));
			});
	};

	const onClickRemoveAllSuppliers = (node: NodeApi<TenderItem>) => {
		if (multiselectedTenders.length > 1) {
			multiselectedTenders.forEach((tender) => {
				deleteSuppliersInBulk(tender.userItemRefinementSuppliers, tender.refinementId!, tender.tid);
			});
		} else {
			deleteSuppliersInBulk(
				node.data.userItemRefinementSuppliers,
				node.data.refinementId!,
				node.id
			);
		}
	};

	const editTenderItem = ({ id, status, tid }: { id: string; status: string; tid: string }) => {
		editOneTenderItem({ contentUUID, id, status, tid });
	};

	return ({ attrs, node }: any) => (
		<TreeViewRow
			attrs={{
				...attrs,
				'aria-checked': node.isSelected
			}}
			handleRowClick={onClickTreeRow}
			informAboutToggle={onClickToggle(node)}
			isEditing={isEditingItem(node)}
			node={node}
		>
			{treeWidth ? (
				<TenderItemsAiPredictionRow
					contentUUID={contentUUID}
					node={node}
					selectedColumns={selectedColumns}
					treeWidth={treeWidth}
				>
					{shouldRender3DotsMenu(node) && (
						<ThreeDotsMenu
							containerHeight={tableHeight > 400 ? tableHeight : 400}
							optionalClass={classNames({
								[styles.animatedActionMenu]: node.tree.selectedIds.size > 1
							})}
							options={
								'product' in node.data
									? getProductsThreeDotsMenuOptions(node, navigate, t, projectId as string)
									: getTenderItemThreeDotsMenuOptions(
											node,
											t,
											projectId as string,
											navigate,
											editTenderItem,
											contentUUID,
											onClickRemoveAllSuppliers
										)
							}
						/>
					)}
				</TenderItemsAiPredictionRow>
			) : (
				<div />
			)}
		</TreeViewRow>
	);
};

export default TenderItemsAIRow;
