import type { EntityId, EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { TenderItem } from '../../interfaces';
import type { AKTType, GenericObjectType, SupplierForShoppingCartType } from '../../types';
import { baseQuery, USER_REFINEMENT_URL } from '../stateUtils';

const transformResponse = (response: TenderItem[]) => ({
	data: {},
	totalItems: response.length,
	treeData: response
		.sort((a, b) => (a.sorting > b.sorting ? 1 : -1))
		.map((item: any) => ({
			...item,
			children: undefined,
			group: item.is_group,
			hasParent: !!item.parent_item_tid,
			longText: item.long_text,
			parentUUID: item.parent_item_tid,
			shortText: item.short_text,
			status: item.status,
			tid: item.tid
		}))
});

const shoppingCartsApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchAllShoppingCarts: builder.query<
				EntityState<SupplierForShoppingCartType, EntityId>,
				{ akt: AKTType; id: string }
			>({
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'ShoppingCart'
							}))
						: [{ type: 'ShoppingCart' }],
				query: ({ id, akt }) => `${USER_REFINEMENT_URL}suppliers/distinct/${id}/${akt}`,
				transformResponse: (
					response: [SupplierForShoppingCartType]
				): EntityState<SupplierForShoppingCartType, EntityId> =>
					response
						.filter((supplier) => Boolean(supplier.supplierName))
						.sort((a, b) => {
							if (a.supplierName < b.supplierName) {
								return -1;
							}
							if (a.supplierName > b.supplierName) {
								return 1;
							}

							return 0;
						})
						.reduce(
							(acc, curr) => {
								acc.entities[curr.supplierID as string] = curr;
								acc.ids.push(curr.supplierID);
								return acc;
							},
							{
								entities: {} as GenericObjectType<SupplierForShoppingCartType>,
								ids: [] as string[]
							}
						)
			}),
			fetchOneShoppingCart: builder.query<
				{ data: {}; totalItems: number; treeData: TenderItem[] },
				string
			>({
				query: (id) => `${USER_REFINEMENT_URL}items/supplier/${id}`,
				// @ts-ignore // TODO remove this once BE fixes status issue
				transformResponse
			})
		};
	},
	reducerPath: 'shoppingCarts',
	tagTypes: ['ShoppingCart']
});

export const { useFetchAllShoppingCartsQuery, useFetchOneShoppingCartQuery } = shoppingCartsApi;

export const selectShoppingCarts = (theQueryArg: { akt: AKTType; id: string }) =>
	shoppingCartsApi.endpoints.fetchAllShoppingCarts.select(theQueryArg);
export const selectItemsFromSupplierShoppingCart = (theQueryArg: string) =>
	shoppingCartsApi.endpoints.fetchOneShoppingCart.select(theQueryArg);

export { shoppingCartsApi };
