import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import { toast } from 'react-toastify';

import type { ItemRefinement2 } from './types';
import { type RootState } from '..';
import type {
	ItemRefinement,
	ProductAssigment,
	ProductType,
	ShoppingCard,
	TenderItem,
	TenderRefinementType,
	UserItemRefinement
} from '../../interfaces/UserRefinement2.types';
import type { AKTType } from '../../types';
import { baseQuery } from '../stateUtils';
import getArePositionsChecked from '../tenderDocument/utils';
import {
	deleteProductTenderItem,
	setTenderItems,
	setTenderItemsTotal,
	updateTenderItem
} from '../tenderItems/tenderItemsState';
import { setSuppliers, updateSuppliers } from '../tenderItems/tenderItemsSuppliersState';
import { createInitialTreeStructure, mapOverItems } from '../tenderItems/treeDataUtils';

const USER_REFINEMENT_URL = 'services/userrefinement2/api/';

export type ProductAssignmentBodyType = {
	id: string;
	productId: string;
	productStatus: string;
	listOrderIndex: number;
	itemRefinement: {
		tid: string;
	};
};

const userRefinementApi = createApi({
	baseQuery,
	endpoints: (builder) => ({
		createProductAssigment: builder.mutation<ProductAssignmentBodyType, any>({
			async onQueryStarted({ id, contentUUID }, { dispatch, queryFulfilled, getState }) {
				try {
					const { data } = await queryFulfilled;
					const { itemRefinement, ...rest } = data;
					// @ts-ignore
					const tenderItems = getState().tenderItems.tenderItems[contentUUID];
					const tenderItem = tenderItems.data[id];
					dispatch(
						updateTenderItem({
							contentUUID,
							updated: {
								...tenderItem,
								productAssigments: [...tenderItem.productAssigments, rest]
							}
						})
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ id, contentUUID, tenderId, ...body }) => ({
				body,
				method: 'POST',
				url: `${USER_REFINEMENT_URL}product-assigments`
			})
		}),
		createProductAssigmentBulk: builder.mutation<
			any,
			{
				contentUUID: string;
				products: ProductAssignmentBodyType[];
			}
		>({
			async queryFn({ contentUUID, products }, _queryApi) {
				const response: any = await Promise.all(
					products.map((arg) =>
						_queryApi.dispatch(
							userRefinementApi.endpoints.createProductAssigment.initiate({ ...arg, contentUUID })
						)
					)
				);
				const error = response.some((data: any) => 'error' in data);
				if (error) {
					throw new Error();
				}
				const productsData = response.map((product: any) => product.data);
				return { data: { products: productsData } };
			}
		}),
		createSupplierAssignment: builder.mutation<
			{
				supplierId: string;
				supplierName: string;
				listOrderIndex: number;
				tid: string;
				itemRefinement: { tid: string };
			},
			{
				supplierId: string;
				supplierName: string;
				listOrderIndex: number;
				itemRefinement: { tid: string };
				tenderId: string;
				contentUUID: string;
			}
		>({
			invalidatesTags: ['ShoppingCard'],
			async onQueryStarted({ contentUUID, tenderId }, { dispatch, queryFulfilled, getState }) {
				try {
					const { data } = await queryFulfilled;
					// @ts-ignore
					const tenderItems = getState().tenderItems.tenderItems[contentUUID];
					// @ts-ignore
					const { tenderSuppliers } = getState();
					const tenderItemSuppliers = tenderSuppliers.currentState[tenderId];
					const newSuppliers = tenderItemSuppliers.map((supplier: any) => {
						if (supplier.supplierId === data.supplierId) {
							return { ...supplier, tid: data.tid };
						}
						return supplier;
					});
					const tenderItem = tenderItems.data[tenderId];
					dispatch(
						updateTenderItem({
							contentUUID,
							updated: {
								...tenderItem,
								userItemRefinementSuppliers: newSuppliers
							}
						})
					);

					dispatch(updateSuppliers({ tenderId, userItemRefinementSuppliers: newSuppliers }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ tenderId, contentUUID, ...body }) => ({
				body,
				method: 'POST',
				url: `${USER_REFINEMENT_URL}supplier-assigments`
			})
		}),
		deleteProductAssigment: builder.mutation<
			ProductAssignmentBodyType,
			{ contentUUID: string; parentId: string; tid: string; id: string }
		>({
			async onQueryStarted(
				{ contentUUID, tid, parentId, id },
				{ dispatch, queryFulfilled, getState }
			) {
				try {
					await queryFulfilled;
					// @ts-ignore
					const tenderItems = getState().tenderItems.tenderItems[contentUUID];
					const parentItem = tenderItems.data[parentId];
					const entity = {
						...parentItem,
						children: parentItem.children.filter((child: string) => child !== id),
						productAssigments: parentItem.productAssigments.filter(
							(product: any) => product.tid !== tid
						)
					};
					dispatch(
						deleteProductTenderItem({
							contentUUID,
							entity
						})
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ tid }) => ({
				method: 'DELETE',
				url: `${USER_REFINEMENT_URL}product-assigments/${tid}`
			})
		}),
		deleteSupplierAssignment: builder.mutation<string, { supplierId: string; tenderId: string }>({
			invalidatesTags: ['ShoppingCard'],
			async onQueryStarted({ tenderId, supplierId }, { dispatch, queryFulfilled, getState }) {
				try {
					await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tenderId];
					dispatch(
						updateTenderItem({
							...tenderItem,
							userItemRefinementSuppliers: tenderItem.userItemRefinementSuppliers.filter(
								(supplier: any) => supplier.id !== supplierId
							)
						})
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ supplierId }) => ({
				method: 'DELETE',
				url: `${USER_REFINEMENT_URL}supplier-assigments/${supplierId}`
			})
		}),
		deleteSupplierAssignmentBulk: builder.mutation<
			string,
			{ supplierIds: string[]; tenderId: string }
		>({
			// @ts-ignore
			async queryFn({ supplierIds, tenderId }, _queryApi) {
				const response = await Promise.all(
					supplierIds.map((supplierId: string) =>
						_queryApi.dispatch(
							userRefinementApi.endpoints.deleteSupplierAssignment.initiate({
								supplierId,
								tenderId
							})
						)
					)
				);
				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}

				return { data: {} };
			}
		}),
		editItemRefinement: builder.mutation<
			TenderRefinementType,
			{ status: string; tid: string; id: string; contentUUID: string }
		>({
			async onQueryStarted({ contentUUID, id, status }, { dispatch, queryFulfilled, getState }) {
				try {
					const { data } = await queryFulfilled;
					// @ts-ignore
					const { tenderItems: tenderItemsData } = getState().tenderItems;
					const tenderItems = tenderItemsData[contentUUID];
					const tenderItem = tenderItems.data[id];
					const parentItem = tenderItems.data[tenderItem.parentItem];
					if (parentItem && parentItem?.children?.length) {
						const { children } = parentItem as unknown as any;
						const shouldUpdateParent = children
							.filter((child: string) => child !== id)
							.every((child: string) => {
								const tenderItem = tenderItems.data[child];
								return tenderItem.status === status;
							});

						if (parentItem.status !== status && shouldUpdateParent) {
							dispatch(
								userRefinementApi.endpoints.editItemRefinement.initiate({
									contentUUID,
									id: parentItem.tid,
									status,
									tid: parentItem.refinementId!
								})
							);
						}
					}

					dispatch(
						updateTenderItem({
							contentUUID,
							updated: { ...tenderItem, status: data.status }
						})
					);

					dispatch(setTenderItemsTotal({ contentUUID }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ id, ...body }) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}item-refinements/${body.tid}`
			})
		}),
		editProductAssigment: builder.mutation<
			ProductType,
			{ tid: string; quantity?: number; positionType: string }
		>({
			async onQueryStarted({ tid, ...rest }, { dispatch, queryFulfilled, getState }) {
				try {
					await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tid];
					dispatch(updateTenderItem({ ...tenderItem, ...rest }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: (body) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}product-assigments/${body.tid}`
			})
		}),
		fetchItemRefinementsFromDoc: builder.query<
			UserItemRefinement,
			{ docRefinementId: string; tenderId: string }
		>({
			query: ({ docRefinementId, tenderId }) =>
				`${USER_REFINEMENT_URL}item-refinements/from/${docRefinementId}/${tenderId}`
		}),
		fetchProductAssigment: builder.query<ProductAssigment, string>({
			query: (tid) => `${USER_REFINEMENT_URL}product-assigments/${tid}`
		}),
		fetchProductAssigments: builder.query({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			async onQueryStarted({ tenderId }, { queryFulfilled, dispatch, getState }) {
				try {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { data } = await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tenderId];
					dispatch(updateTenderItem({ ...tenderItem, productAssigments: data.products }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			async queryFn({ ids }, _queryApi) {
				const response = await Promise.all(
					ids.map((id: string) =>
						_queryApi.dispatch(userRefinementApi.endpoints.fetchProductAssigment.initiate(id))
					)
				);

				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}

				const products = response.map((product: any) => product.data);
				return { data: { products } };
			}
		}),
		fetchShoppingCards: builder.query<ShoppingCard[], string>({
			providesTags: ['ShoppingCard'],
			query: (tenderRefinementId) =>
				`${USER_REFINEMENT_URL}shopping-cards/${tenderRefinementId}/shopping-cards`,
			transformResponse: (response: any) =>
				response.filter((shoppingCard: ShoppingCard) =>
					Boolean(shoppingCard.supplierInfo.supplierName)
				)
		}),
		fetchSupplierAssigment: builder.query<any, string>({
			query: (tid) => `${USER_REFINEMENT_URL}supplier-assigments/${tid}`
		}),
		fetchTenderRefinements: builder.query<TenderRefinementType, { akt: AKTType; id: string }>({
			async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
				const { data } = await queryFulfilled;
				// @ts-ignore
				const tenderItems = getState().tenderItems.tenderItems[id];
				const recursive = (itemRefinements: ItemRefinement2[]) => {
					const entities: { [key: string]: any } = {};
					const positions: { [key: string]: any } = {};
					const innerFunction = (itemRefinements: ItemRefinement2[]) => {
						itemRefinements.forEach((itemRefinement) => {
							const oldTender = tenderItems.data[itemRefinement.tenderItemTid];
							let userItemRefinementSuppliers: any = [];
							if (!oldTender) {
								return;
							}
							if (!oldTender.isTenderGroup) {
								userItemRefinementSuppliers = itemRefinement.supplierAssigments
									.map((supplierAssigment) => ({
										addedByUser: supplierAssigment.valueSource !== 'AI',
										id: supplierAssigment.tid,
										label: supplierAssigment.supplierName,
										probability: 1,
										supplierId: supplierAssigment.supplierId,
										tid: supplierAssigment.tid,
										value: supplierAssigment.supplierName
									}))
									.filter((supplier) => Boolean(supplier.label));
							}
							const tenderItem = {
								...oldTender,
								productAssigments: itemRefinement.productAssigments,
								refinementId: itemRefinement.tid,
								status: itemRefinement.status,
								userItemRefinementSuppliers
							};
							entities[oldTender.tid] = tenderItem;
							if (!oldTender.isTenderGroup) {
								positions[oldTender.tid] = tenderItem;
							}
							if (itemRefinement.itemRefinements.length) {
								innerFunction(itemRefinement.itemRefinements);
							}
						});
					};
					innerFunction(itemRefinements);
					return { entities, positions };
				};

				if (!data.itemRefinements.length) {
					if (data.status === 'STRUCTERED') {
						toast.error(t('common.fetchingError'));
					}
				} else {
					const { entities, positions } = recursive(
						data.itemRefinements as unknown as ItemRefinement2[]
					);
					const { treeStructure, parentlessIds } = createInitialTreeStructure({
						entities,
						ids: tenderItems.ids
					});
					if (treeStructure) {
						const { totalCheckedItems, totalUncheckedItems } = getArePositionsChecked({
							entities: treeStructure
						});
						const normalizedData = {
							[id]: {
								data: treeStructure,
								fetchingStatus: 'DONE',
								parentlessIds,
								status: 'DONE',
								totalCheckedItems,
								totalItems: tenderItems.totalItems,
								totalUncheckedItems,
								treeData: parentlessIds?.length ? mapOverItems(treeStructure, parentlessIds) : []
							}
						};
						dispatch(setSuppliers(positions));
						dispatch(setTenderItems(normalizedData as any));
					}
				}
			},
			query: ({ akt, id }) => `${USER_REFINEMENT_URL}tender-refinements/ensure/for/${akt}/${id}`
		})
	}),
	reducerPath: 'userRefinement',
	tagTypes: ['ShoppingCard']
});

export const {
	useFetchTenderRefinementsQuery,
	useFetchItemRefinementsFromDocQuery,
	useEditItemRefinementMutation,
	useCreateSupplierAssignmentMutation,
	useDeleteSupplierAssignmentMutation,
	useEditProductAssigmentMutation,
	useCreateProductAssigmentMutation,
	useDeleteProductAssigmentMutation,
	useCreateProductAssigmentBulkMutation,
	useFetchProductAssigmentsQuery,
	useDeleteSupplierAssignmentBulkMutation,
	useFetchShoppingCardsQuery
} = userRefinementApi;

export const { select: selectShoppingCards } = userRefinementApi.endpoints.fetchShoppingCards;
export const { select: selectTenderRefinements } =
	userRefinementApi.endpoints.fetchTenderRefinements;
export const selectItemsFromShoppingCards = createSelector(
	(state: RootState) => state,
	(state: RootState, tenderRefinementId: string, shoppingCardId: string) => [
		selectShoppingCards(tenderRefinementId)(state),
		shoppingCardId
	],
	(state: RootState, shoppingCardsData: any) => {
		const [{ data }, selectorId] = shoppingCardsData;
		const [contentUUID, supplierId] = selectorId ? selectorId.split('/') : [];
		const tenderItems = state.tenderItems.tenderItems[contentUUID];
		if (data?.length && tenderItems?.treeData?.length) {
			const supplier = data?.find(
				(card: ShoppingCard) => card.supplierInfo.supplierId === supplierId
			);
			if (supplier?.itemRefinements) {
				const tenderItemTids = supplier.itemRefinements.map(
					(itemRefinement: ItemRefinement) => itemRefinement.tenderItemTid
				);
				const tenderData = tenderItemTids?.map((tenderItemTid: string) => ({
					...tenderItems.data[tenderItemTid],
					productAssigments: []
				}));
				return tenderData.filter(
					(tender: TenderItem) => 'tid' in tender && tender.status !== 'DECLINED'
				);
			}
		}
		return [];
	}
);
export { userRefinementApi };
