import { createApi } from '@reduxjs/toolkit/query/react';

import type { ExportToSap, ExportToSapBodyDataType } from '../../interfaces/ExportToSap.types';
import { baseQuery, EXPORTTOSAP_URL } from '../stateUtils';

const exportToSapApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createVersion: builder.mutation<ExportToSapBodyDataType, ExportToSapBodyDataType>({
				invalidatesTags: ['Export'],
				query: (body) => ({
					body,
					method: 'POST',
					url: `${EXPORTTOSAP_URL}export-to-saps`
				})
			}),
			fetchExports: builder.query<ExportToSap[], { id: string | undefined }>({
				providesTags: () => [{ type: 'Export' as const }],
				query: ({ id }) => ({
					url: `${EXPORTTOSAP_URL}export-to-saps/projectId/${id}?size=50`
				})
			})
		};
	},
	reducerPath: 'exportToSap',
	tagTypes: ['Export']
});

export const { useFetchExportsQuery, useCreateVersionMutation } = exportToSapApi;

export { exportToSapApi };
