const findFirstFilteredTenderItem = (tenderItems: any[], filter: { [key: string]: boolean }) => {
	const filterKey = Object.keys(filter)[0];
	let foundItem = {};
	const filteringItems = (item: any) => {
		if (filterKey === 'refined') {
			return ['DECLINED', 'CONFIRMED'].includes(item.status);
		}
		return item.status === 'OPEN';
	};

	const findFirstEditedItem = (items: any[]) => {
		const firstLevel = items.filter(filteringItems);
		if (firstLevel.length) {
			foundItem = {
				...firstLevel[0]
			};
		} else {
			items.forEach((item) => {
				if (item.children) {
					findFirstEditedItem(item.children);
				}
			});
		}
	};
	if (filter[filterKey]) {
		findFirstEditedItem(tenderItems);
	} else {
		foundItem = {
			...tenderItems[0]
		};
	}
	return foundItem;
};

export default findFirstFilteredTenderItem;
