import { type EntityId, type EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { GenericObjectType } from '../../types';
import { baseQuery, SUPPLIER_COUMMUNICATION } from '../stateUtils';

export type EmailType = {
	accessToken: string;
	additionalEmails: string;
	attachments: { fileId: string; fileName: string }[];
	creatorDisplayName: string;
	creatorNtId: string;
	emailAddress: string;
	emailBody: string;
	gender: string;
	projectData: { id: string };
	reminderDate: string;
	signature: string;
	supplierId: string;
	supplierName: string;
	templateName: string;
};

const supplierCommunicationApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createProjectData: builder.mutation({
				query: (body) => ({
					body,
					method: 'POST',
					url: `${SUPPLIER_COUMMUNICATION}project-data`
				})
			}),
			fetchSentSupplierInquires: builder.query({
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'SupplierInquire'
							}))
						: [{ type: 'SupplierInquire' }],

				query: (id) => `${SUPPLIER_COUMMUNICATION}sent-supplier-inquiries/${id}`,
				transformResponse: (response: [any]): EntityState<any, EntityId> =>
					response
						.filter((supplier) => Boolean(supplier.supplierName))
						.reduce(
							(acc, curr) => {
								acc.entities[curr.supplierId as string] = curr;
								acc.ids.push(curr.supplierId);
								return acc;
							},
							{
								entities: {} as GenericObjectType<any>,
								ids: [] as string[]
							}
						)
			}),
			generateEml: builder.mutation({
				query: (body) => ({
					body,
					method: 'POST',
					url: `${SUPPLIER_COUMMUNICATION}generate-eml`
				})
			}),
			sendBulkEmail: builder.mutation({
				invalidatesTags: ['SupplierInquire'],
				query: (body) => ({
					body,
					method: 'POST',
					url: `${SUPPLIER_COUMMUNICATION}send-bulk-email`
				})
			}),
			sendEmail: builder.mutation({
				invalidatesTags: ['SupplierInquire'],
				query: (body) => ({
					body,
					method: 'POST',
					url: `${SUPPLIER_COUMMUNICATION}send-email`
				})
			})
		};
	},
	reducerPath: 'supplierCommunication',
	tagTypes: ['SupplierInquire']
});

export const {
	useGenerateEmlMutation,
	useCreateProjectDataMutation,
	useSendEmailMutation,
	useSendBulkEmailMutation,
	useFetchSentSupplierInquiresQuery
} = supplierCommunicationApi;

export const { select: selectSuppliersInquiries } =
	supplierCommunicationApi.endpoints.fetchSentSupplierInquires;

export { supplierCommunicationApi };
