import styles from './Filter.module.scss';

const TextFilter = ({ id, subType }: { id: string; subType: string }) => (
	<div className={styles.textFilter}>
		<div>
			<input id={`${id}-asc`} name={`${id}${subType}`} type="radio" value="asc" />
			<label htmlFor={`${id}-asc`}>A &gt; Z</label>
		</div>
		<div>
			<input id={`${id}-desc`} name={`${id}${subType}`} type="radio" value="desc" />
			<label htmlFor={`${id}-desc`}>Z &gt; A</label>
		</div>
	</div>
);

export default TextFilter;
