import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './ErrorFallback.module.scss';
import Button from '../Button';

const ErrorFallback = ({
	error,
	resetErrorBoundary
}: {
	error: Error;
	resetErrorBoundary: () => void;
}) => {
	const { t } = useTranslation();
	const newDeploymentError = error.message.includes('Loading chunk');

	const buttonText = newDeploymentError ? t('common.newDeployment') : t('common.errorHasOccurred');

	const onClick = () => {
		if (newDeploymentError) {
			window.location.reload();
		} else {
			resetErrorBoundary();
		}
	};
	return (
		<div className={styles.fallbackErrorWrapper}>
			<div
				className={classNames(styles.fallbackErrorImage, {
					[styles.hulkSad]: !newDeploymentError,
					[styles.hulkHappy]: newDeploymentError
				})}
			/>
			<Button
				onClick={onClick}
				optionalClass={styles.fallbackErrorButton}
				text={buttonText}
				variant="primary"
			/>
		</div>
	);
};

export default ErrorFallback;
