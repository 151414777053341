import type { EntityId, EntityState } from '@reduxjs/toolkit';
import { current as RTKCurrent } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type {
	Address,
	ContactPerson,
	Customer,
	PMDSupplier,
	Product,
	Project
} from '../../interfaces';
import type { GenericObjectType } from '../../types';
import {
	contactPersonAdapter,
	initialContactPersonState,
	initialProductsState,
	initialProjectsState,
	productsAdapter,
	projectsAdapter
} from '../adapters';
import { baseQuery, CONTACTS_URL } from '../stateUtils';

const SEARCH_PATH = '/services/projects/api/_search/';
const SEARCH_PRODUCT_ITEMS_PATH = '/services/productmasterdata/api/_search/hulk-products';

const searchApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			searchAddresses: builder.query<{ byIds: GenericObjectType<Address>; ids: string[] }, any>({
				keepUnusedDataFor: Infinity,
				providesTags: (result) =>
					result
						? result.ids.map((id: string) => ({
								id,
								type: 'Address'
							}))
						: [{ type: 'Address' }],
				query: ({ page, query, size, sort }) => ({
					params: {
						page,
						size,
						sort,
						...query
					},
					url: `${SEARCH_PATH}addresses/suggested`
				}),
				transformResponse: (response) =>
					(response as Address[]).reduce(
						(acc, curr) => {
							acc.byIds[curr.id as string] = curr;
							acc.ids.push(curr.id);
							return acc;
						},
						{
							byIds: {} as GenericObjectType<Address>,
							ids: [] as string[]
						}
					)
			}),
			searchContacts: builder.query<
				EntityState<ContactPerson, string>,
				{ page?: string; query: string; sort?: string }
			>({
				forceRefetch: () => true,
				keepUnusedDataFor: Infinity,
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'Contact'
							}))
						: [{ type: 'Contact' }],
				query: ({ page, query, sort }) => ({
					params: {
						page,
						query,
						sort
					},
					url: `${CONTACTS_URL}_search/contact-people?page=${page || 0}&size=20`
				}),
				transformResponse: (response) =>
					contactPersonAdapter.setAll(initialContactPersonState, response as ContactPerson[])
			}),
			searchCustomers: builder.query<
				EntityState<Customer, string>,
				{ page?: string; query: string; size?: string; sort?: string; type?: string }
			>({
				forceRefetch: () => true,
				keepUnusedDataFor: Infinity,
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'Customer'
							}))
						: [{ type: 'Customer' }],
				query: ({ page, query, size, sort, type }) => ({
					params: {
						page,
						query,
						size,
						sort,
						type
					},
					url: `${CONTACTS_URL}_search/customers`
				}),
				transformResponse: (response) =>
					(response as Customer[]).reduce(
						(acc, curr) => {
							acc.entities[curr.cRMID as string] = curr;
							if (!acc.ids.includes(curr.cRMID)) {
								acc.ids.push(curr.cRMID);
							}
							return acc;
						},
						{
							entities: {} as GenericObjectType<Customer>,
							ids: [] as string[]
						}
					)
			}),
			searchProducts: builder.query<EntityState<Product, string>, string>({
				forceRefetch({ currentArg, previousArg }) {
					return currentArg !== previousArg;
				},
				keepUnusedDataFor: Infinity,
				merge: (current, response, otherArgs) => {
					if (!otherArgs.arg.includes('page=0')) {
						const ids = RTKCurrent(current.ids);
						const uniqueIds = Array.from(new Set([...ids, ...response.ids]));
						current.ids.splice(0);
						current.ids.push(...uniqueIds);
						Object.assign(current.entities, response.entities);
					} else {
						current.ids.splice(0);
						current.ids.push(...response.ids);
						Object.assign(current.entities, response.entities);
					}
				},
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'Products'
							}))
						: [{ type: 'Products' }],
				query: (query) => `${SEARCH_PRODUCT_ITEMS_PATH}?${query}`,
				serializeQueryArgs: ({ endpointName }) => endpointName,
				transformResponse: (response) =>
					productsAdapter.setAll(initialProductsState, response as Product[])
			}),
			searchProjects: builder.query<EntityState<Project, string>, string>({
				forceRefetch({ currentArg, previousArg }) {
					return currentArg !== previousArg;
				},
				keepUnusedDataFor: Infinity,
				merge: (current, response, otherArgs) => {
					if (!otherArgs.arg.includes('page=0')) {
						const ids = RTKCurrent(current.ids);
						const uniqueIds = Array.from(new Set([...ids, ...response.ids]));
						current.ids.splice(0);
						current.ids.push(...uniqueIds);
						Object.assign(current.entities, response.entities);
					} else {
						current.ids.splice(0);
						current.ids.push(...response.ids);
						Object.assign(current.entities, response.entities);
					}
				},
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'Projects'
							}))
						: [{ type: 'Projects' }],
				query: (query) => `${SEARCH_PATH}projects?${query}`,
				serializeQueryArgs: ({ endpointName }) => endpointName,
				transformResponse: (response) =>
					projectsAdapter.setAll(initialProjectsState, response as Project[])
			}),
			searchSuppliers: builder.query<EntityState<PMDSupplier, string>, any>({
				forceRefetch({ currentArg, previousArg }) {
					return currentArg !== previousArg;
				},
				keepUnusedDataFor: Infinity,
				providesTags: (result) =>
					result
						? result.ids.map((id: EntityId) => ({
								id,
								type: 'Supplier'
							}))
						: [{ type: 'Supplier' }],
				query: (query) => `services/contacts/api/_search/suppliers?query=${query}`,
				transformResponse: (response) =>
					(response as Customer[]).reduce(
						(acc, curr) => {
							acc.entities[curr.cRMID as string] = {
								id: curr.id,
								kT1: '',
								kT2: '',
								labor: '',
								lieferant: curr.cRMID,
								lvorm: null,
								mArt: '',
								material: '',
								matSt: '',
								nachfolgerPTR: null,
								name1: curr.name1,
								ort: curr.address?.city,
								rG: '',
								werk: ''
							};
							if (!acc.ids.includes(curr.cRMID)) {
								acc.ids.push(curr.cRMID);
							}
							return acc;
						},
						{
							entities: {} as GenericObjectType<PMDSupplier>,
							ids: [] as string[]
						}
					)
			})
		};
	},
	reducerPath: 'search',
	tagTypes: ['Address', 'Customer', 'Contact', 'Projects', 'Products', 'Supplier']
});

export const {
	useSearchAddressesQuery,
	useSearchContactsQuery,
	useSearchCustomersQuery,
	useSearchProjectsQuery,
	useSearchProductsQuery,
	useSearchSuppliersQuery
} = searchApi;
export const selectSearchedAddresses = searchApi.endpoints.searchAddresses.select;
export const selectSearchedContacts = searchApi.endpoints.searchContacts.select;
export const selectSearchedCustomers = searchApi.endpoints.searchCustomers.select;
export const selectSearchedSuppliers = searchApi.endpoints.searchSuppliers.select;
export const selectSearchedProducts = searchApi.endpoints.searchProducts.select;
export const selectSearchedProjects = searchApi.endpoints.searchProjects.select;

export { searchApi };
