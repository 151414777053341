const getArePositionsChecked = (data: { [key: string]: any }) => {
	let totalUncheckedItems = 0;
	let totalCheckedItems = 0;
	Object.values(data.entities).forEach((tender: any) => {
		if (!tender.isTenderGroup) {
			if (['OPEN', 'IN_PROGRESS'].includes(tender.status) || !tender.status) {
				totalUncheckedItems += 1;
			} else {
				totalCheckedItems += 1;
			}
		}
	});

	return { totalCheckedItems, totalUncheckedItems };
};

export default getArePositionsChecked;
