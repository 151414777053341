import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import styles from './PortaledDropdown.module.scss';
import useOnClickHandler from '../../hooks/useOnClickHandler';
import type { SelectionItemType } from '../../types';
import { dropdownTypesOptions } from '../../utils/constants';
import Dropdown from '../Dropdown';
import Icon from '../Icon';

const PortaledDropdown = ({
	cellId,
	portalId,
	type,
	value
}: {
	cellId: string;
	portalId: string;
	type: 'positionType' | 'priceType';
	value: string;
}) => {
	const [shouldOpenPortal, setShouldOpenPortal] = useState<boolean>(false);
	const [newValue, setNewValue] = useState<string>('');
	const buttonRef = useRef<HTMLButtonElement>(null);
	const { t } = useTranslation();

	const domNodeForPortal = document.getElementById(portalId) as HTMLDivElement;

	const closePortal = () => {
		setShouldOpenPortal(false);
		domNodeForPortal.style.removeProperty('top');
		domNodeForPortal.style.removeProperty('left');
		domNodeForPortal.style.removeProperty('width');
		domNodeForPortal.style.removeProperty('height');
	};
	const onClickDropdownItem = (newValue: SelectionItemType[]) => {
		setNewValue(newValue[0].value);
		closePortal();
	};
	const translatedValue = type === 'priceType' ? t(value ? `onePager.${value}` : '') : value;
	const dropdown = (
		<Dropdown
			data={dropdownTypesOptions[type]}
			isOpen
			label=""
			name={cellId}
			onClickDropdownItem={onClickDropdownItem}
			optionalClass={styles.editableTenderItemDropdown}
			placeholder={newValue || translatedValue}
		/>
	);

	const setPortalPositionAndCreateIt = () => {
		const { x, y } = (document.getElementById(cellId) as HTMLButtonElement).getBoundingClientRect();
		const { clientWidth } = document.getElementById(
			'tenderItemHeaderCell-positionType'
		) as HTMLTableHeaderCellElement;
		domNodeForPortal.style.top = `${Math.floor(y)}px`;
		domNodeForPortal.style.left = `${Math.floor(x)}px`;
		domNodeForPortal.style.width = `${clientWidth}px`;
		domNodeForPortal.style.height = '48px';
		setShouldOpenPortal(true);
	};

	useOnClickHandler({
		insideHandler: () => {},
		// @ts-ignore
		outsideHandler: (e: MouseEvent) => {
			const [, ...id] = cellId.split('-');
			const parentElement = (e.target as HTMLElement)?.parentElement;
			if (parentElement?.classList[0]?.includes('Dropdown')) {
				if (['SPAN', 'svg'].includes((e.target as HTMLElement)?.tagName)) {
					closePortal();
				}
				if ((e.target as HTMLElement).id === id.join('-')) {
					closePortal();
				}
			}
		},
		ref: buttonRef
	});

	return (
		<>
			<button
				ref={buttonRef}
				className={classNames(styles.portaledDropdown, { [styles.borderless]: shouldOpenPortal })}
				id={cellId}
				onClick={setPortalPositionAndCreateIt}
				type="button"
			>
				<span>{newValue || translatedValue}</span>
				<Icon name="navigation_down" />
			</button>
			{shouldOpenPortal && createPortal(dropdown, domNodeForPortal)}
		</>
	);
};

export default PortaledDropdown;
