import classNames from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import styles from './Input.module.scss';
import type { InputType } from '../../types';

const Input = forwardRef((props: InputType, ref: ForwardedRef<HTMLInputElement>) => {
	const { bordered = true, dimensions = 'auto', error, name, optionalClass, ...rest } = props;
	return (
		<input
			ref={ref}
			className={classNames(
				styles.input,
				optionalClass,
				{
					[styles.bordered]: bordered,
					[styles.error]: error
				},
				styles[dimensions]
			)}
			id={name}
			{...rest}
		/>
	);
});

export default Input;
