import { createApi } from '@reduxjs/toolkit/query/react';

import type {
	PollingDataType,
	TenderDocContentResponseType,
	TenderDocumentHeaderContentType
} from '../../types/TenderDocument.types';
import { baseQuery, TENDER_DOC_URL, TENDER_MANAGEMENT_URL } from '../stateUtils';
import { toggleShouldOpenBlockerModal } from '../ui/uiSlice';

const tenderDocContentApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			deleteTenderDocContentAttachment: builder.query<string, string>({
				query(id) {
					return {
						method: 'DELETE',
						url: `${TENDER_MANAGEMENT_URL}document-responses/${id}`
					};
				}
			}),
			fetchTemplate: builder.query<string, string | null>({
				query: (id) => ({
					responseHandler: (response: any) => response.text(),
					url: `${TENDER_DOC_URL}gaebs/freemarker/${id}/default`
				})
			}),
			fetchTenderDocumentHeaderContent: builder.query<
				TenderDocumentHeaderContentType,
				string | null
			>({
				query: (id) => `${TENDER_DOC_URL}gaebs/header/data/${id}`
			}),
			pollingTenderDocStatus: builder.query<PollingDataType, string | null>({
				query: (id) => `${TENDER_MANAGEMENT_URL}document-responses/${id}`
			}),
			uploadTenderDocContent: builder.mutation<TenderDocContentResponseType, FormData>({
				async onQueryStarted(_, { dispatch }) {
					dispatch(toggleShouldOpenBlockerModal(true));
				},
				query(body) {
					return {
						body,
						method: 'POST',
						url: `${TENDER_MANAGEMENT_URL}file-upload`
					};
				}
			}),
			uploadTenderDocContentAttachments: builder.mutation<TenderDocContentResponseType, FormData>({
				async onQueryStarted(_, { dispatch }) {
					dispatch(toggleShouldOpenBlockerModal(true));
				},
				query(body) {
					return {
						body,
						method: 'POST',
						url: `${TENDER_MANAGEMENT_URL}attachment-upload`
					};
				}
			})
		};
	},
	reducerPath: 'tenderDocument'
});

export const {
	useFetchTenderDocumentHeaderContentQuery,
	useLazyFetchTemplateQuery,
	usePollingTenderDocStatusQuery,
	useUploadTenderDocContentMutation,
	useUploadTenderDocContentAttachmentsMutation,
	useLazyDeleteTenderDocContentAttachmentQuery
} = tenderDocContentApi;

export const { select: selectTemplate } = tenderDocContentApi.endpoints.fetchTemplate;
export { tenderDocContentApi };
