import { t } from 'i18next';

import type { TableHeaderCellType } from '../types';

const getTenderItemsHeaderOptions = (): Omit<TableHeaderCellType, 'width'>[] => [
	{
		dataStructure: 'export',
		headerCellText: 'Export'
	},
	{
		dataStructure: 'status',
		headerCellText: t('thCell.status')
	},
	{
		dataStructure: 'position',
		headerCellText: t('thCell.position')
	},
	{
		dataStructure: 'isTenderGroup',
		headerCellText: t('thCell.tenderItemType')
	},
	{
		dataStructure: 'ttItemId',
		headerCellText: t('thCell.ttItemId')
	},
	{
		dataStructure: 'productCategory',
		headerCellText: t('thCell.productCategory')
	},
	{
		dataStructure: 'materialType',
		headerCellText: t('thCell.materialType')
	},
	{
		dataStructure: 'positionType',
		headerCellText: t('thCell.positionType')
	},
	{
		dataStructure: 'bomItemList',
		headerCellText: t('thCell.bomItemList')
	},
	{
		dataStructure: 'shortText',
		headerCellText: t('thCell.shortText')
	},
	{
		dataStructure: 'longText',
		headerCellText: t('thCell.longText')
	},
	{
		dataStructure: 'supplier',
		headerCellText: t('thCell.supplier')
	},
	{
		dataStructure: 'quantity',
		headerCellText: t('thCell.quantity')
	},
	{
		dataStructure: 'unit',
		headerCellText: t('thCell.unitOfMeasure')
	},
	// Initially hidden fields
	{
		dataStructure: 'price',
		headerCellText: t('thCell.price')
	},
	{
		dataStructure: 'priceType',
		headerCellText: t('thCell.priceType')
	},
	{
		dataStructure: 'rebateGroup',
		headerCellText: t('thCell.rebateGroup')
	},
	{
		dataStructure: 'rebate1',
		headerCellText: t('thCell.rebate1')
	},
	{
		dataStructure: 'rebate2',
		headerCellText: t('thCell.rebate2')
	},
	{
		dataStructure: 'rebate3',
		headerCellText: t('thCell.rebate3')
	},
	// {
	// 	dataStructure: 'materialNumber',
	// 	headerCellText: t('thCell.materialNumber')
	// },
	{
		dataStructure: 'quoteId',
		headerCellText: t('thCell.quoteId')
	},
	{
		dataStructure: 'quoteDate',
		headerCellText: t('thCell.quoteDate')
	},
	{
		dataStructure: 'tradingGroup',
		headerCellText: t('thCell.tradingGroup')
	}
];

export default getTenderItemsHeaderOptions;
