import type { EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { AddNewSupplierType } from '../../components/AddNewSupplier/AddNewSupplier';
import type { Customer, Supplier } from '../../interfaces';
import type { ContactPersonType, GenericObjectType } from '../../types';
import { baseQuery, CONTACTS_URL } from '../stateUtils';

const contactsApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createCustomer: builder.mutation<
				Customer,
				{
					cRMID: string;
					isPlanner: boolean;
					name1: string;
					address: {
						city: string;
						country: string;
						number: string | undefined;
						postalCode: string;
						street: string;
					};
				}
			>({
				query: (customer) => ({
					body: {
						...customer
					},
					method: 'POST',
					url: `${CONTACTS_URL}customers`
				})
			}),
			createSupplier: builder.mutation<Supplier, AddNewSupplierType>({
				query: (customer) => ({
					body: {
						...customer
					},
					method: 'POST',
					url: `${CONTACTS_URL}suppliers`
				})
			}),
			fetchContactPerson: builder.query<ContactPersonType, string | undefined>({
				query: (id) => `${CONTACTS_URL}contact-people/${id}`
			}),
			fetchCustomer: builder.query<Customer, string | undefined | null>({
				query: (id) => `${CONTACTS_URL}customers/${id}`
			}),
			fetchCustomerByIds: builder.query<EntityState<Customer, string>, string[] | undefined>({
				async queryFn(args: string[] | undefined, _queryApi, _extraOptions, fetchWithBQ) {
					if (args?.length) {
						const data = await Promise.all(
							// @ts-ignore
							args?.map((id: string) => fetchWithBQ(`${CONTACTS_URL}customers/${id}`))
						).then((res) =>
							// @ts-ignore
							res.map((item) => ({ ...item.data }) as Customer)
						);

						return {
							data: (data as Customer[]).reduce(
								(acc, curr) => {
									acc.entities[curr.cRMID as string] = curr;
									acc.ids.push(curr.cRMID);
									return acc;
								},
								{
									entities: {} as GenericObjectType<Customer>,
									ids: [] as string[]
								}
							)
						};
					}
					return { data: { entities: {}, ids: [] } };
				}
			})
		};
	},
	reducerPath: 'customers'
});

export const {
	useCreateCustomerMutation,
	useCreateSupplierMutation,
	useFetchContactPersonQuery,
	useFetchCustomerQuery,
	useFetchCustomerByIdsQuery
} = contactsApi;

export const { select: selectFetchedCustomerById } = contactsApi.endpoints.fetchCustomerByIds;
export const { select: selectFetchedCustomer } = contactsApi.endpoints.fetchCustomer;

export { contactsApi };
