import classNames from 'classnames';
import { useState, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { ActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';

import styles from './SupplierMultiSelect.module.scss';
import type { PMDSupplier, SupplierProductPredictions } from '../../interfaces';
import { selectIsEditingSuppliersOn, selectMultiselectedTenders } from '../../state';
import { searchApi } from '../../state/search/searchSlice';
import {
	deleteSupplier,
	selectSuppliersOfTenderItem,
	updateSuppliersInMultiselectedTenderItems,
	updateSuppliersInTenderItem
} from '../../state/tenderItems/tenderItemsSuppliersState';
import type { TenderItemSupplierTypes } from '../../types';

// TODO: ASK WERNER WHAT TO DO AFTER DELETION OF AI PREDICTED SUPPLIER???
const SupplierMultiSelect = ({
	isDisabled,
	isOffer,
	selectorId
}: {
	isDisabled: boolean;
	isOffer: boolean;
	selectorId: string;
}) => {
	const { t } = useTranslation();
	const isEditingPSuppliers = useSelector(selectIsEditingSuppliersOn);
	const sortedSuppliers = useSelector(selectSuppliersOfTenderItem(selectorId));
	const [trigger] = searchApi.endpoints.searchSuppliers.useLazyQuery();
	const dispatch = useDispatch();
	const multiselectedTenders = useSelector(selectMultiselectedTenders);
	const supplierNames = sortedSuppliers
		?.map((supplier: TenderItemSupplierTypes) => supplier.label)
		.join(', ');
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
	const onChange = (
		selectedOptions: TenderItemSupplierTypes[],
		action: ActionMeta<TenderItemSupplierTypes>
	) => {
		const { option, removedValue } = action;
		dispatch(
			updateSuppliersInTenderItem({
				selectorId,
				suppliers: selectedOptions
			})
		);

		if (option) {
			if (multiselectedTenders.length > 1) {
				dispatch(
					updateSuppliersInMultiselectedTenderItems({
						supplier: option,
						tenderItems: multiselectedTenders
							.filter((tenderItem) => tenderItem.tid !== selectorId)
							.map((tenderItem) => tenderItem.tid),
						type: 'ADD'
					})
				);
			}
		}

		if (removedValue) {
			dispatch(deleteSupplier({ supplierId: removedValue.tid, tenderId: selectorId }));
			if (multiselectedTenders.length > 1) {
				dispatch(
					updateSuppliersInMultiselectedTenderItems({
						supplier: removedValue,
						tenderItems: multiselectedTenders
							.filter((tenderItem) => tenderItem.tid !== selectorId)
							.map((tenderItem) => tenderItem.tid),
						type: 'REMOVE'
					})
				);
			}
		}
	};

	const preventBubbling = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const promiseOptions = (inputValue: string) =>
		trigger(`${inputValue}*`)
			.unwrap()
			.then((data: any) => {
				if (data.ids.length) {
					setMenuIsOpen(true);
				} else {
					setMenuIsOpen(false);
				}
				return (
					Object.values(data.entities)
						// .filter((data) => !supplierNames.includes(data?.name1))
						.map((supplier) => ({
							addedByUser: true,
							id: (supplier as PMDSupplier).id,
							label: (supplier as PMDSupplier).name1,
							parentId: selectorId.split('/')[1],
							probability: 1,
							supplierId: (supplier as PMDSupplier).lieferant,
							supplierName: (supplier as PMDSupplier).name1,
							tid: (supplier as PMDSupplier).lieferant,
							value: (supplier as PMDSupplier).name1
						}))
				);
			});

	return isEditingPSuppliers ? (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onClick={preventBubbling} title={supplierNames}>
			<AsyncSelect
				backspaceRemovesValue
				cacheOptions
				captureMenuScroll
				classNames={{
					container: () => styles.container,
					control: () => classNames(styles.control, { [styles.disabled]: isDisabled }),
					indicatorsContainer: () => styles.indicatorsContainer,
					input: () => styles.input,
					loadingMessage: () => styles.loadingMessage,
					menuList: () => styles.menuList,
					menuPortal: () => styles.menuPortal,
					multiValue: (node) => {
						// eslint-disable-next-line no-nested-ternary
						const nodeColor = node.index ? (node.index === 1 ? 'one' : 'two') : 'zero';
						return classNames(styles.multiValue, {
							[styles[nodeColor]]: !(node.data as SupplierProductPredictions).addedByUser,
							[styles.nonOffer]: !isOffer && !(node.data as SupplierProductPredictions).addedByUser
						});
					},
					multiValueRemove: (value) =>
						classNames(styles.multiValueRemove, {
							[styles.disabled]: value.data.probability !== 1
						}),
					noOptionsMessage: () => styles.noOptionMessage,
					option: () => styles.option,
					placeholder: () => styles.placeholder,
					valueContainer: () => styles.valueContainer
				}}
				closeMenuOnSelect={false}
				// @ts-ignore
				defaultOptions={sortedSuppliers}
				isClearable={false}
				isDisabled={isDisabled}
				isMulti
				loadingMessage={() => t('inquires.lookingForSupplier')}
				// @ts-ignore
				loadOptions={promiseOptions}
				maxMenuHeight={400}
				menuIsOpen={menuIsOpen}
				menuPortalTarget={document.body}
				menuPosition="fixed"
				// @ts-ignore
				onChange={onChange}
				onInputChange={(inputValue, { action }) => {
					if (!inputValue.length) {
						setMenuIsOpen(false);
					}

					if (action === 'input-change' || action === 'input-blur') {
						promiseOptions(inputValue);
					}
				}}
				placeholder={t('inquires.selectSupplierLabel')}
				unstyled
				// @ts-ignore
				value={sortedSuppliers}
			/>
		</div>
	) : (
		<div title={supplierNames}>
			<span>{supplierNames}</span>
		</div>
	);
};

export default SupplierMultiSelect;
