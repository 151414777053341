import classNames from 'classnames';
import format from 'date-fns/format';
import type { ChangeEvent, ForwardedRef, KeyboardEvent } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import usePrevious from '../../hooks/usePrevious';
import type { BuderusDatePickerTriggerType, DatePickerType } from '../../types';
import changeToPreviousFriday from '../../utils/changeToPreviousFriday';
import createValidDate from '../../utils/createValidDate';
import isWeekday from '../../utils/isWeekday';
import { filterWeekDay } from '../../utils/projectDatesSectionReducer';
import simulateUserSetValue from '../../utils/simulateUserEvent';
import Icon from '../Icon';
// no modular scss is allowed here in order to be able to do the overriding of styles
import './datePicker.scss';

const BuderusDatePickerTrigger = forwardRef(
	(props: BuderusDatePickerTriggerType, ref: ForwardedRef<HTMLDivElement>) => {
		const {
			disabled,
			formatPlaceholder,
			mandatory,
			nameForInput,
			onClick,
			value,
			readonly,
			onChange
		} = props;

		const [innerValue, setInnerValue] = useState<string | Date | undefined | null>(value);
		const previousInnerValue = usePrevious(value);

		useEffect(() => {
			if (previousInnerValue !== value) {
				setInnerValue(value);
			}
		}, [value, previousInnerValue]);

		const openOnEnter = (e: KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Enter' && onClick) {
				onClick();
			}
		};

		const preventManualWeekendSelection = (event: ChangeEvent) => {
			const { value } = event.target as HTMLInputElement;
			const validValue =
				value &&
				value?.length > 7 &&
				value.includes('.') &&
				value.split('.')[0] &&
				value.split('.')[0].length > 1 &&
				value.split('.')[1] &&
				value.split('.')[1].length > 1 &&
				value.split('.')[2] &&
				value.split('.')[2].length > 3;
			if (validValue && onChange) {
				const date = !isWeekday(new Date(createValidDate(value)))
					? format(changeToPreviousFriday(createValidDate(value)), 'MM/dd/yyyy')
					: createValidDate(value);
				onChange(event);
				simulateUserSetValue(
					event.target as HTMLInputElement,
					`${date.split('/')[1]}.${date.split('/')[0]}.${date.split('/')[2]}`
				);
			}
		};

		const onChangeInput = (event: ChangeEvent) => {
			setInnerValue((event.target as HTMLInputElement).value);
			preventManualWeekendSelection(event);
		};

		return (
			<div
				ref={ref}
				aria-label="DatePicker"
				className={classNames('buderusDatePickerTrigger', {
					disabledDatePicker: disabled,
					readonlyDatePicker: readonly
				})}
				onClick={onClick}
				onKeyUp={openOnEnter}
				role="button"
				tabIndex={0}
			>
				<input
					autoComplete="off"
					className={classNames({ selectedDateInTrigger: value })}
					disabled={disabled}
					id={nameForInput}
					onChange={onChangeInput}
					pattern="(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}"
					placeholder={formatPlaceholder}
					readOnly={readonly}
					required={mandatory}
					type="text"
					value={innerValue as string}
				/>
				<Icon name="calendar" />
			</div>
		);
	}
);

const BuderusDatePicker = ({
	disabled = false,
	formatPlaceholder,
	label,
	mandatory,
	name,
	optionalClass,
	testingId,
	readOnly,
	selected,
	...rest
}: DatePickerType) => {
	const [value, setValue] = useState<Date | undefined | null>(selected);

	useEffect(() => {
		setValue(selected);
	}, [selected]);

	const onSelectDate = (date: Date) => {
		setValue(date);

		const dateInputField = document.getElementById(name) as HTMLInputElement;
		if (dateInputField) {
			simulateUserSetValue(dateInputField, date.toISOString());
		}
	};

	const isValidDateFormat = (dateString: string) => {
		const datePattern = /^(0[1-9]?|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;

		return datePattern.test(dateString);
	};

	const onClickOutside = (evt: any) => {
		const inputValue = document.getElementById(name) as HTMLInputElement;
		if (isValidDateFormat(inputValue.value) && rest.onChange) {
			const [day, month, year] = inputValue.value.split('.') as unknown as number[];

			const date = filterWeekDay(new Date(year, month - 1, day));

			rest.onChange(date, evt);
		}
	};

	return (
		<div className="buderusDatePickerWrapper" data-cy={testingId}>
			<label className={optionalClass} htmlFor={name}>
				{label}
			</label>
			<DatePicker
				calendarStartDay={1}
				customInput={
					<BuderusDatePickerTrigger
						disabled={disabled}
						formatPlaceholder={formatPlaceholder}
						mandatory={mandatory}
						nameForInput={name}
						readonly={readOnly}
						value={value}
					/>
				}
				dateFormat="dd.MM.yyyy"
				disabled={disabled}
				filterDate={isWeekday}
				formatWeekDay={(nameOfDay: string) => nameOfDay.slice(0, 1)}
				onChange={onSelectDate}
				onClickOutside={onClickOutside}
				popperModifiers={[
					{
						name: 'offset',
						options: {
							offset: [0, 0]
						}
					}
				]}
				readOnly={readOnly}
				selected={value}
				showPopperArrow={false}
				{...rest}
			/>
		</div>
	);
};

export default BuderusDatePicker;
