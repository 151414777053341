import { createSlice, current } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';

import { projectsApi } from './projectsSlice';
import { type RootState } from '..';
import type { FileTagType } from '../../interfaces/Project.types';
import type { AttachedFileType } from '../../types/ProjectFormDataType.types';

const attachmentsSlice = createSlice({
	extraReducers: (builder) => {
		builder.addMatcher(projectsApi.endpoints?.fetchProject.matchFulfilled, (state, { payload }) => {
			if (payload.attachedFiles?.length) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				state.projectAttachments = payload.attachedFiles;
			}
		});
	},
	initialState: {
		projectAttachments: []
	} as { projectAttachments: AttachedFileType[] },
	name: 'attachments',
	reducers: {
		deleteProjectAttachments: (state, action) => ({
			...state,
			projectAttachments: state.projectAttachments.filter(
				(attachment) => attachment.fileUUID !== action.payload.id
			)
		}),
		editProjectAttachment: (state, action) => {
			const attachment = state.projectAttachments.find((obj) => obj.fileUUID === action.payload.id);
			const index = state.projectAttachments.findIndex((obj) => obj.fileUUID === action.payload.id);

			// @ts-ignore
			// eslint-disable-next-line no-param-reassign
			state.projectAttachments[index] = { ...attachment, tags: [...action.payload.tags] };
			const translatedTags = action.payload.tags
				.map((tag: FileTagType) => {
					const key = t(`tags.${tag.id}-shortText`);

					return `#${key}`;
				})
				.join(', ');

			toast.success(`File: ${current(attachment)!.name} is updated. Tags: ${translatedTags}`);

			return state;
		},
		updateProjectAttachments: (state, action) => {
			if (action.payload.type === 'UPDATE') {
				const attachments = current(state.projectAttachments);
				const attachment = attachments.find(
					(attachment) => attachment.fileUUID === action.payload.data.fileUUID
				);

				if (attachment) {
					return state;
				}

				return {
					...state,
					projectAttachments: [...state.projectAttachments, action.payload.data]
				};
			}

			return {
				...state,
				projectAttachments: []
			};
		}
	}
});

export const { updateProjectAttachments, deleteProjectAttachments, editProjectAttachment } =
	attachmentsSlice.actions;
export const selectProjectAttachments = (state: RootState) => state.attachments.projectAttachments;

export default attachmentsSlice.reducer;
