import classNames from 'classnames';
import { useEffect, type MouseEvent } from 'react';
import type { NodeApi, RowRendererProps } from 'react-arborist';

import styles from './TreeViewRow.module.scss';
import globalStyles from '../../scss/global.module.scss';
import { useEditItemRefinementMutation } from '../../state';
import Icon from '../Icon';
import Loader from '../Loader';

const TreeViewRow = ({
	children,
	attrs,
	node,
	isEditing,
	informAboutToggle,
	handleRowClick
}: Omit<RowRendererProps<any>, 'innerRef'> & {
	handleRowClick: (evt: MouseEvent<HTMLDivElement>, node: NodeApi) => void;
	isEditing: boolean;
	informAboutToggle: (rowId: string) => void;
}) => {
	const { isClosed, isLeaf } = node as NodeApi;
	const onClickRow = (evt: MouseEvent<HTMLDivElement>) => {
		evt.stopPropagation();
		evt.preventDefault();
		const checkbox = evt.target as HTMLDivElement;
		const attr = checkbox.getAttribute('aria-label');
		if (!node.data.product || attr === 'Checkbox') {
			handleRowClick(evt, node);
		}
	};

	const className = classNames(styles.treeViewRow, {
		[styles.selected]: attrs['aria-checked'],
		[styles.opened]: !isClosed,
		[styles.editing]: false,
		[styles.product]: node.data.product,
		[styles.selectedProduct]: node.data.product && attrs['aria-checked']
	});

	const [, { data, isLoading }] = useEditItemRefinementMutation({
		fixedCacheKey: 'editingTenderItem'
	});

	const onClickToggle = (e: MouseEvent | KeyboardEvent) => {
		e.stopPropagation();
		node.tree.toggle(node.id);
		if (
			!node.data.isTenderGroup &&
			(['CONFIRMED', 'IN_PROGRESS', 'OPEN'].includes(node.data.status) || !node.data.status)
		) {
			informAboutToggle(node.id);
		}
	};

	const onKeyDown = (e: KeyboardEvent) => {
		e.stopPropagation();
	};

	const emptyCellWidth = (
		document.getElementById('emptyTableHeaderCell') as HTMLTableHeaderCellElement
	)?.clientWidth;

	const shouldRenderToggleButton = () => {
		if (node.data.isTenderGroup && !node.children?.length) {
			return true;
		}

		if (node.data.isTenderGroup && node.children?.length) {
			return isLeaf && node.data.isTenderGroup;
		}

		return (
			(!node.data.isTenderGroup && !node.data.productAssigments?.length) ||
			node.data.status === 'DECLINED'
		);
	};

	useEffect(() => {
		const clearDates = ((evt: CustomEvent) => {
			const { value } = evt.detail;
			if (value === node.id) {
				informAboutToggle(node.id);
			}
		}) as EventListener;

		document.body.addEventListener('addProduct', clearDates);

		return () => {
			document.body.removeEventListener('addProduct', clearDates);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			{...attrs}
			className={className}
			data-level={node.level}
			id={node.id}
			onClick={onClickRow}
			// @ts-ignore
			onKeyDown={onKeyDown}
		>
			{shouldRenderToggleButton() ? (
				<div style={{ height: '32px', minWidth: emptyCellWidth, width: emptyCellWidth }} />
			) : (
				<div
					className={styles.toggleButtonWrapper}
					style={{ height: '32px', minWidth: emptyCellWidth, width: emptyCellWidth }}
				>
					<button
						aria-label="ThreeDotsMenu Arrow"
						className={classNames(globalStyles.iconBtn, styles.toggleButton)}
						onClick={onClickToggle}
						tabIndex={-1}
						type="button"
					>
						<Icon name={!node.isOpen ? 'navigation_forward_right' : 'navigation_down'} />
					</button>
				</div>
			)}
			{children}
			{((node.id === (data as any)?.tenderItemTid && isLoading) || isEditing) && (
				<Loader optionalClass={styles.editingLoader} />
			)}
		</div>
	);
};

export default TreeViewRow;
