import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './AIFilterButtons.module.scss';
import type { RootState } from '../../state';
import { selectTenderItems } from '../../state/tenderItems/tenderItemsState';
import Button from '../Button';

const AIFilterButtons = ({ contentUUID }: { contentUUID: string }) => {
	const { t } = useTranslation();
	const { data, totalItems, totalCheckedItems, totalUncheckedItems } = useSelector(
		(state: RootState) => selectTenderItems(state, contentUUID)
	);

	const [filterState, setFilterState] = useState<{ unrefined: boolean; refined: boolean }>({
		refined: false,
		unrefined: false
	});
	const [numberOfItems, setNumberOfItems] = useState<{
		allItems: number;
		correctItems: number;
		toCheckItems: number;
	}>({
		allItems: 0,
		correctItems: 0,
		toCheckItems: 0
	});

	useEffect(() => {
		if (data) {
			setNumberOfItems({
				allItems: totalItems,
				correctItems: totalUncheckedItems,
				toCheckItems: totalCheckedItems
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		const setTotalItemNumber = ((e: CustomEvent) => {
			const { value } = e.detail;
			setNumberOfItems(value);
		}) as EventListener;
		document.body.addEventListener('setTotalItems', setTotalItemNumber);

		return () => {
			document.body.removeEventListener('setTotalItems', setTotalItemNumber);
		};
	}, []);

	const onClick = (type: 'unrefined' | 'refined') => () => {
		const filterAITenderItemsEvent = new CustomEvent('filteringTenderItems', {
			detail: {
				type,
				value: !filterState[type]
			}
		});
		document.body.dispatchEvent(filterAITenderItemsEvent);
		setFilterState({
			refined: type === 'refined' ? !filterState.refined : false,
			unrefined: type === 'unrefined' ? !filterState.unrefined : false
		});
	};

	return (
		<div className={styles.aiFilterButtonsWrapper}>
			<p>{t('onePager.foundItems', { numberOfItems: totalItems })}</p>
			<div className={styles.aiFilterButtons}>
				<Button
					onClick={onClick('unrefined')}
					optionalClass={classNames({ [styles.activeButton]: filterState.unrefined })}
					text={t('onePager.forChecking', { numberForChecking: numberOfItems.toCheckItems })}
					variant="ghost"
				/>
				<Button
					onClick={onClick('refined')}
					optionalClass={classNames({ [styles.activeButton]: filterState.refined })}
					text={t('onePager.correct', { numberOfCorrect: numberOfItems.correctItems })}
					variant="ghost"
				/>
			</div>
		</div>
	);
};

export default AIFilterButtons;
