import type { NodeApi } from 'react-arborist';

import type { TenderItem, UserRefinementTree } from '../interfaces';
import type { ProductForTableType } from '../types';

const handleALTKey = (node: NodeApi<TenderItem>, tenderItemsData: UserRefinementTree) => {
	if (!node.tree.selectedNodes.length) {
		node.tree.select(node);
	} else {
		node.tree.selectContiguous(node);
	}
	if (node.tree.selectedNodes.length > 1) {
		const selectedIds = node.tree.selectedNodes.map((nodes) => nodes.id);
		node.tree.deselectAll();
		selectedIds.forEach((id) => {
			if (
				!(tenderItemsData.data[id] as TenderItem).isTenderGroup &&
				!(tenderItemsData.data[id] as ProductForTableType).product
			) {
				node.tree.selectMulti(id);
			}
		});
	}
};

export default handleALTKey;
