import isSaturday from 'date-fns/isSaturday';
import subDays from 'date-fns/subDays';

const changeToPreviousFriday = (value: string) => {
	if (isSaturday(new Date(value))) {
		return subDays(new Date(value), 1);
	}
	return subDays(new Date(value), 2);
};

export default changeToPreviousFriday;
