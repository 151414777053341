import type { AKTType } from '../types';

const getAktNameBySgArea = (sgArea: AKTType) => {
	switch (sgArea) {
		case 'SGC':
			return 'AKT Mainz';
		case 'SGE':
			return 'AKT Berlin/Brandenburg';
		case 'SGV':
			return 'AKT München';
		case 'SGW':
			return 'AKT Düsseldorf';
		case 'SGS':
			return 'AKT Esslingen';
		case 'SGN':
			return 'AKT Hamburg';
		default:
			return '';
	}
};

export default getAktNameBySgArea;
