import { useTranslation } from 'react-i18next';

import styles from './Filter.module.scss';
import TextFilter from './TextFilter';

const NumericFilter = ({ id }: { id: string }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.numericFilterWrap}>
			<TextFilter id={id} subType="NumericFilter" />
			<div className={styles.formHeader}>
				<span>{t(`filters.selectRange`)}</span>
			</div>
			<hr />
			<div>
				<div>
					<label htmlFor={`${id}-from`}>{t('filters.from')}</label>
					<input id={`${id}-from`} placeholder="0" type="number" />
				</div>
				<div>
					<label htmlFor={`${id}-to`}>{t('filters.to')}</label>
					<input id={`${id}-to`} placeholder="99999999" type="number" />
				</div>
			</div>
		</div>
	);
};

export default NumericFilter;
