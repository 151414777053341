import { createSlice, current } from '@reduxjs/toolkit';

import { type RootState } from '..';
import type { TenderItem } from '../../interfaces';
import type { AKTType } from '../../types';

export interface UIStateInterface {
	isTenderItemsTreeEditingPrice: boolean;
	isTenderItemsTreeEditingSuppliers: boolean;
	isTenderItemsTreeExportColumn?: string[];
	shouldOpenBlockerModal: boolean;
	selectedTenders: TenderItem[];
	addedSuppliersForMultiselection: any[];
	deletedSuppliersForMultiselection: any[];
	projectsForAiPredictionStatus: { id: string; akt: AKTType; contentUUID: string; name: string }[];
}

const initialState: UIStateInterface = {
	addedSuppliersForMultiselection: [],
	deletedSuppliersForMultiselection: [],
	isTenderItemsTreeEditingPrice: false,
	isTenderItemsTreeEditingSuppliers: false,
	isTenderItemsTreeExportColumn: undefined,
	projectsForAiPredictionStatus: [],
	selectedTenders: [],
	shouldOpenBlockerModal: false
};

export const uiSlice = createSlice({
	initialState,
	name: 'ui',
	reducers: {
		addProjectForAiPredictionStatus: (state, action) => ({
			...state,
			projectsForAiPredictionStatus: [...state.projectsForAiPredictionStatus, action.payload]
		}),
		addSupplierForMultiselection: (state, action) => {
			const { type, data } = action.payload;

			if (type === 'RESET') {
				return { ...state, addedSuppliersForMultiselection: [] };
			}

			if (type === 'REMOVE') {
				return {
					...state,
					addedSuppliersForMultiselection: state.addedSuppliersForMultiselection.filter(
						(supplier) => supplier.supplierName !== data.supplierName
					)
				};
			}

			if (type === 'UPDATE') {
				return {
					...state,
					addedSuppliersForMultiselection: [...state.addedSuppliersForMultiselection, data]
				};
			}

			return state;
		},
		deleteProjectForAiPredictionStatus: (state, action) => ({
			...state,
			projectsForAiPredictionStatus: state.projectsForAiPredictionStatus.filter(
				(project) => project.id !== action.payload
			)
		}),
		deleteSupplierForMultiselection: (state, action) => {
			if (action.payload.type === 'RESET') {
				return { ...state, deletedSuppliersForMultiselection: [] };
			}

			if (action.payload.type === 'UPDATE') {
				return {
					...state,
					deletedSuppliersForMultiselection: [
						...state.deletedSuppliersForMultiselection,
						action.payload.data
					]
				};
			}

			return state;
		},
		toggleMultiselection: (state, action) => {
			const { data, type } = action.payload;

			if (type === 'DESELECT') {
				return {
					...state,
					selectedTenders: current(state.selectedTenders).filter(
						(tender) => tender.tid !== data.tid
					)
				};
			}

			if (type === 'SELECT') {
				return { ...state, selectedTenders: data };
			}

			return state;
		},
		toggleShouldOpenBlockerModal: (state, action) => ({
			...state,
			shouldOpenBlockerModal: action.payload
		}),
		toggleTenderItemsTreeEditingPrice: (state, action) => ({
			...state,
			isTenderItemsTreeEditingPrice: action.payload
		}),
		toggleTenderItemsTreeEditingSuppliers: (state, action) => ({
			...state,
			isTenderItemsTreeEditingSuppliers: action.payload
		}),
		toggleTenderItemsTreeExportColumn: (state, action) => ({
			...state,
			isTenderItemsTreeExportColumn: action.payload
		})
	}
});

export const {
	toggleTenderItemsTreeEditingPrice,
	toggleTenderItemsTreeEditingSuppliers,
	toggleTenderItemsTreeExportColumn,
	toggleShouldOpenBlockerModal,
	toggleMultiselection,
	addSupplierForMultiselection,
	deleteSupplierForMultiselection,
	addProjectForAiPredictionStatus,
	deleteProjectForAiPredictionStatus
} = uiSlice.actions;

export const selectIsEditingPriceOn = (state: RootState) => state.ui.isTenderItemsTreeEditingPrice;
export const selectIsEditingSuppliersOn = (state: RootState) =>
	state.ui.isTenderItemsTreeEditingSuppliers;
export const selectIsExportColumnOn = (state: RootState) => state.ui.isTenderItemsTreeExportColumn;
export const selectIsOpenBlockerModal = (state: RootState) => state.ui.shouldOpenBlockerModal;
export const selectMultiselectedTenders = (state: RootState) => state.ui.selectedTenders;
export const selectAddedSupplieForMultiselection = (state: RootState) =>
	state.ui.addedSuppliersForMultiselection;
export const selectProjectsForAiPredictionStatus = (state: RootState) =>
	state.ui.projectsForAiPredictionStatus;

export default uiSlice.reducer;
