import type { HTMLElementsWithValueType } from '../types';

const simulateUserSetValue = (passedElement: HTMLElementsWithValueType, settingValue: string) => {
	Object.getOwnPropertyDescriptor(Object.getPrototypeOf(passedElement), 'value')!.set?.call(
		passedElement,
		settingValue
	);
	passedElement.dispatchEvent(new Event('input', { bubbles: true }));
};

export default simulateUserSetValue;
