import type { NodeApi } from 'react-arborist';

import type { TenderItem } from '../interfaces';
import type { ProductForTableType } from '../types';

const shouldRender3DotsMenu = (node: NodeApi<TenderItem | ProductForTableType>) => {
	const isMultiSelectionOn = node.tree.selectedNodes.length > 1;
	return (
		(node.parent?.data.status !== 'CONFIRMED' && !isMultiSelectionOn) ||
		(node.parent?.data.status !== 'CONFIRMED' &&
			isMultiSelectionOn &&
			node.tree.selectedNodes[0].id === node.id)
	);
};

export default shouldRender3DotsMenu;
