import classNames from 'classnames';

import styles from './Loader.module.scss';
import type { LoaderType } from '../../types';

const Loader = ({ nodeType, text, optionalClass, style }: LoaderType) => {
	const OuterElement = nodeType ?? 'div';
	const Spinner = nodeType === 'tbody' ? 'tr' : 'div';
	return (
		// @ts-ignore Need to ignore it since we know that React will turn this type into ReactNode out of string;
		<OuterElement className={classNames(styles.wrapper, optionalClass)} style={style}>
			<Spinner className={styles.loader} />
			{text && <span>{text}</span>}
		</OuterElement>
	);
};

export default Loader;
