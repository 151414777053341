import classNames from 'classnames';
import memoize from 'lodash.memoize';
import type { PropsWithChildren, ReactNode } from 'react';
import type { NodeApi } from 'react-arborist';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './TenderItemsAiPredictionRow.module.scss';
import TitlePortalTooltip from './TitlePortalTooltip';
import type { TenderItem } from '../../interfaces';
import getRoutes from '../../routes';
import { selectMultiselectedTenders } from '../../state';
import { selectTenderItem } from '../../state/tenderItems/tenderItemsState';
import type { GenericObjectType, ProductForTableType, TableHeaderCellType } from '../../types';
import type iconData from '../../types/icons';
import getTenderItemsHeaderOptions from '../../utils/getTenderItemsHeaderOptions';
import isString from '../../utils/isString';
import EditableTenderItemAIPredictionRowCell from '../EditableTenderItemAIPredictionRowCell';
import ExportVersionCheckbox from '../ExportVersionCheckbox';
import Icon from '../Icon';
import SupplierMultiSelect from '../SupplierMultiSelect';
import rowStyles from '../TenderItemsTableHeader/TenderItemsTableHeader.module.scss';

// This unused param is here in order to trigger memoized function
const getColumnCellWidth = memoize(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	(selectedColumns: string[], treeWidth: number) => {
		const columnProps = [...selectedColumns];
		if (columnProps?.length) {
			columnProps.push('settings');
		}
		return columnProps?.reduce((acc, curr) => {
			// eslint-disable-next-line no-param-reassign
			acc = {
				...acc,
				[curr]: document.getElementById(`tenderItemHeaderCell-${curr}`)?.offsetWidth
			};
			return acc;
		}, {});
	},
	(...args) => JSON.stringify(args)
);

const TenderItemsAiPredictionRow = ({
	contentUUID,
	children,
	node,
	selectedColumns,
	treeWidth
}: {
	contentUUID: string;
	node: NodeApi;
	selectedColumns: string[];
	treeWidth: number;
} & PropsWithChildren) => {
	const { data } = node;
	const { t } = useTranslation();
	const widths: GenericObjectType<number> | undefined = getColumnCellWidth(
		selectedColumns,
		treeWidth
	);
	const { ONE_PAGER_SUPPLIER_INQUIRIES } = getRoutes(t);

	const returnTextForIsGroup = (value: string) => {
		const nonProductText = t(`${value ? 'onePager.group' : 'onePager.position'}`);
		return (data as ProductForTableType).product ? t('onePager.product') : nonProductText;
	};
	const multiselectedTenders = useSelector(selectMultiselectedTenders);
	const parentItem = useSelector(selectTenderItem(contentUUID, data.parentItem));
	const returnEditableCells = (dataStructure: string, value: string) =>
		(data as ProductForTableType).product ? (
			<EditableTenderItemAIPredictionRowCell
				cellId={`${dataStructure} ${data.tid} ${data.parentItem} ${data.productId}`}
				field={dataStructure}
				product={data}
				shouldShowEditingFields={
					['IN_PROGRESS', 'OPEN'].includes(parentItem?.status) &&
					(data as ProductForTableType)?.product
				}
				showDropdown={['positionType', 'priceType'].includes(dataStructure)}
				type={dataStructure as 'positionType' | 'priceType'}
				value={`${value ?? ''}`}
			/>
		) : (
			''
		);

	const getCellContent = (option: Omit<TableHeaderCellType, 'width'>): string | ReactNode => {
		const value = data[option.dataStructure as keyof (TenderItem | ProductForTableType)];
		const isArticlePage = window.location.pathname.includes(ONE_PAGER_SUPPLIER_INQUIRIES);

		switch (option.dataStructure) {
			case 'isTenderGroup':
				return returnTextForIsGroup(value);
			case 'positionType':
			case 'price':
			case 'priceType':
			case 'quoteId':
			case 'rebate1':
			case 'rebate2':
			case 'rebate3':
			case 'rebateGroup':
				return returnEditableCells(option.dataStructure, value);
			case 'export':
				return (
					<div key={option.dataStructure} className={styles.checkbox}>
						<ExportVersionCheckbox id={node.id} />
					</div>
				);
			case 'supplier': {
				const showMultiSelect =
					data.status !== 'DECLINED' &&
					!('product' in data) &&
					!data.isTenderGroup &&
					!isArticlePage &&
					!data.product;
				const isDisabled =
					multiselectedTenders.length > 1 && data.tid !== multiselectedTenders[0].tid;

				return showMultiSelect ? (
					<SupplierMultiSelect
						isDisabled={isDisabled}
						isOffer={(data as TenderItem).isOffer}
						selectorId={data.tid}
					/>
				) : (
					(data as ProductForTableType).supplier
				);
			}
			case 'productCategory':
				return (data as ProductForTableType).productCategory;
			case 'status':
			case 'productStatus': {
				const iconName: GenericObjectType<keyof typeof iconData.ALL_ICONS> = {
					CONFIRMED: 'added_to_cart',
					DECLINED: 'no_cart'
				};
				const status = data.status || data.productStatus;
				return (
					<div className={styles.statusIconWrapper}>
						{['DECLINED', 'CONFIRMED'].includes(status) && (
							<>
								<Icon
									key="first"
									name={iconName[status]}
									title={t(`onePager.${status}`)!}
									variant="black"
								/>
								{!(data as ProductForTableType).product && (
									<Icon key="second" name="lock" title={t('onePager.LOCKED')} variant="black" />
								)}
							</>
						)}
						{status === 'IN_PROGRESS' && (
							<Icon
								key="first"
								name="cart_change"
								title={t(`onePager.${status}`)!}
								variant="black"
							/>
						)}
					</div>
				);
			}
			default:
				return value ?? '';
		}
	};

	const rowElements = getTenderItemsHeaderOptions()
		.filter((option) => selectedColumns?.includes(option.dataStructure))
		.map((option) => {
			const cellContent = getCellContent(option);
			const isCellContentString = isString(cellContent);

			return option.dataStructure === 'longText' ? (
				<TitlePortalTooltip
					key={option.dataStructure}
					text={String(cellContent)}
					width={widths?.[option.dataStructure]}
				/>
			) : (
				<div
					key={option.dataStructure}
					className={classNames(rowStyles[option.dataStructure], {
						// @ts-ignore
						[styles.hideEmptyText]: !cellContent
					})}
					style={{
						maxWidth: widths?.[option.dataStructure],
						width: widths?.[option.dataStructure]
					}}
					title={isCellContentString ? (cellContent as string) : option.dataStructure}
				>
					{cellContent}
				</div>
			);
		});

	return (
		<div className={styles.tenderItemRow}>
			{rowElements}
			<div
				className={styles.tenderItemThreeDotsMenu}
				style={{
					maxWidth: widths?.settings ? widths.settings - 2 : undefined,
					width: widths?.settings ? widths.settings - 2 : undefined
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default TenderItemsAiPredictionRow;
