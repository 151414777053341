import { createSlice, current } from '@reduxjs/toolkit';

import { type RootState } from '..';
import type { GenericObjectType, ProductForTableType } from '../../types';

const searchAiPredictionsProductsSlice = createSlice({
	initialState: {
		data: {
			entities: {},
			ids: []
		},
		products: []
	} as {
		data: {
			entities: GenericObjectType<ProductForTableType>;
			ids: string[];
		};
		products: ProductForTableType[];
	},
	name: 'aiPredictonsProducts',
	reducers: {
		searchProducts: (state, action) => {
			const { products } = current(state);
			const query: string = action.payload.toLowerCase();
			const entities = products
				.filter(
					(product) =>
						product.longText.toLowerCase().includes(query) ||
						product.shortText.toLowerCase().includes(query) ||
						product.idnlf?.toLowerCase().includes(query)
				)
				.reduce((acc: any, curr: ProductForTableType) => {
					// eslint-disable-next-line no-param-reassign
					acc = { ...acc, [curr.id]: curr };
					return acc;
				}, {});

			return {
				...state,
				data: {
					entities,
					ids: entities ? Object.keys(entities) : []
				}
			};
		},
		setAiProducts: (state, action) => {
			const { data } = action.payload;
			// eslint-disable-next-line no-param-reassign
			state.data = data;
			const products = data?.entities
				? Object.keys(data?.entities).map((id: string) => data?.entities[id])
				: [];
			// eslint-disable-next-line no-param-reassign
			state.products = products;
		}
	}
});

export const { searchProducts, setAiProducts } = searchAiPredictionsProductsSlice.actions;
export const selectAiProducts = (state: RootState) => state.aiPredictionsProducts;

export default searchAiPredictionsProductsSlice.reducer;
