import classNames from 'classnames';
import type { KeyboardEvent, MouseEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import styles from './ThreeDotsMenu.module.scss';
import useOnClickHandler from '../../hooks/useOnClickHandler';
import useOnFocusOutHandler from '../../hooks/useOnFocusOutHandler';
import type { ThreeDotsMenuKeyboardHandlerFunctionType } from '../../hooks/useThreeDotsMenuKeyboardHandler';
import useThreeDotsMenuKeyboardHandler from '../../hooks/useThreeDotsMenuKeyboardHandler';
import type {
	ThreeDotsMenuOptionType,
	ThreeDotsMenuType
} from '../../types/ThreeDotsMenuType.types';

const ThreeDotsMenu = ({ containerHeight, options, optionalClass }: ThreeDotsMenuType) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const [currentOpenSubmenu, setCurrentOpenSubmenu] = useState<number | string>(0);

	const onClick = (evt: MouseEvent<HTMLDivElement>) => {
		evt.stopPropagation();
		setIsMenuOpen(!isMenuOpen);

		if (currentOpenSubmenu) {
			setCurrentOpenSubmenu(0);
		}
	};

	const onClickOption = (option: ThreeDotsMenuOptionType) => (evt: MouseEvent<HTMLDivElement>) => {
		evt.stopPropagation();
		if (isMenuOpen) {
			if (option.submenu) {
				setCurrentOpenSubmenu(option.id);
				return;
			}

			if (option.onClick) {
				option.onClick(evt);
				setCurrentOpenSubmenu(0);
				setIsMenuOpen(false);
			}
		}
	};

	const onClickSubmenuOption =
		(option: ThreeDotsMenuOptionType) => (evt: MouseEvent<HTMLButtonElement>) => {
			if (evt.detail) {
				if (option.onClick) {
					option.onClick(evt);
					setIsMenuOpen(false);
					setCurrentOpenSubmenu(0);
				}
			}
		};

	const onKeyUp = (evt: KeyboardEvent<HTMLDivElement>) => {
		if (evt.key === 'Enter') {
			evt.stopPropagation();
			if (!isMenuOpen) {
				setIsMenuOpen(true);
			}
		}

		if (evt.key === 'Escape' && isMenuOpen) {
			setIsMenuOpen(false);
			if (currentOpenSubmenu) {
				setCurrentOpenSubmenu(0);
			}
		}
	};

	const onKeyUpOption =
		(option: ThreeDotsMenuOptionType) => (evt: KeyboardEvent<HTMLDivElement>) => {
			if (evt.key === 'Enter' && !option.submenu && option.onClick) {
				option.onClick(evt);
				setIsMenuOpen(false);
				setCurrentOpenSubmenu(0);
			}
		};

	const onKeyUpSubmenuOption =
		(option: ThreeDotsMenuOptionType) => (evt: KeyboardEvent<HTMLButtonElement>) => {
			if (evt.key === 'Enter' && option.onClick) {
				option.onClick(evt);
				setIsMenuOpen(false);
				setCurrentOpenSubmenu(0);
			}
		};

	function selectElementWithKeyboardArrows<T extends HTMLDivElement>({
		ref,
		newPosition,
		oldPosition,
		setLength
	}: ThreeDotsMenuKeyboardHandlerFunctionType<T>): void {
		const option = ref?.current?.childNodes[1]?.childNodes[
			oldPosition || newPosition
		] as HTMLButtonElement;

		if (!option) {
			return;
		}

		const { submenu, id } = options[oldPosition || newPosition];

		if (submenu) {
			setCurrentOpenSubmenu(id);

			if (!oldPosition) {
				option.focus();
				return;
			}

			if (setLength) {
				setLength(submenu.length);
			}

			if (option?.childNodes[1].childNodes[newPosition]) {
				(option?.childNodes[1].childNodes[newPosition] as HTMLButtonElement).focus();
			}
		} else {
			setCurrentOpenSubmenu(0);
			option.focus();
		}
	}

	useThreeDotsMenuKeyboardHandler(ref, selectElementWithKeyboardArrows, options.length);

	useOnClickHandler({
		insideHandler: () => {},
		outsideHandler: () => {
			if (ref.current && isMenuOpen) {
				setIsMenuOpen(false);
				if (currentOpenSubmenu) {
					setCurrentOpenSubmenu(0);
				}
			}
		},
		ref
	});

	useOnFocusOutHandler(ref, () => setIsMenuOpen(false));

	useEffect(() => {
		if (isMenuOpen) {
			const menu = ref.current?.childNodes[1] as HTMLDivElement;
			const menuCoords = menu.getBoundingClientRect();

			if (menuCoords?.top && menuCoords.top - menuCoords.height >= containerHeight) {
				menu.style.top = `-${28 * options.length}px`;
			}
		}
	}, [containerHeight, isMenuOpen, options.length]);

	return (
		<div
			ref={ref}
			className={classNames(styles.actionMenu, optionalClass)}
			onClick={onClick}
			onKeyUp={onKeyUp}
			role="menu"
			tabIndex={0}
		>
			<div className={styles.dots} />
			{isMenuOpen && (
				<div className={styles.menu}>
					{options.map((option: ThreeDotsMenuOptionType) => (
						<div
							key={option.id}
							className={classNames(styles.menuOption, { [styles.disabled]: option.disabled })}
							onClick={onClickOption(option)}
							onKeyUp={onKeyUpOption(option)}
							role="button"
							tabIndex={0}
						>
							<span id={`${option.id}`}>{option.title}</span>

							<div
								className={classNames(styles.submenu, {
									[styles.active]: option.id === currentOpenSubmenu
								})}
							>
								{option.submenu?.map((opt: ThreeDotsMenuOptionType) => (
									<button
										key={opt.id}
										className={styles.menuOption}
										onClick={onClickSubmenuOption(opt)}
										onKeyUp={onKeyUpSubmenuOption(opt)}
										type="button"
									>
										<span>{opt.title}</span>
									</button>
								))}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ThreeDotsMenu;
