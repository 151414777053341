import { useSelector } from 'react-redux';

import {
	selectIsExportColumnOn,
	toggleTenderItemsTreeExportColumn,
	useAppDispatch
} from '../../state';
import Checkbox from '../Checkbox';

const ExportVersionCheckbox = ({ id }: { id: string }) => {
	const currentExportSelectedVersion = useSelector(selectIsExportColumnOn);
	const dispatch = useAppDispatch();

	const onClickExportCheckbox = () => {
		const thisOneIsInExport = currentExportSelectedVersion!.includes(id);

		let newExported = [];
		if (thisOneIsInExport) {
			newExported = currentExportSelectedVersion!.filter((exportedId) => exportedId !== id);
		} else {
			newExported = [...currentExportSelectedVersion!, id];
		}
		dispatch(toggleTenderItemsTreeExportColumn(newExported));
	};

	return (
		// This needs to be here in order to attach event listener
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onClick={onClickExportCheckbox}>
			<Checkbox
				defaultChecked={currentExportSelectedVersion?.includes(id)}
				name={`export-sap-${id}`}
			/>
		</div>
	);
};

export default ExportVersionCheckbox;
