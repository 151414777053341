import type { TFunction } from 'i18next';
import type { NodeApi } from 'react-arborist';
import type { NavigateFunction } from 'react-router-dom';

import getRoutes from '../routes';
import type { ThreeDotsMenuOptionType } from '../types';

const getProductsThreeDotsMenuOptions = (
	node: NodeApi,
	navigate: NavigateFunction,
	t: TFunction,
	projectId: string
): ThreeDotsMenuOptionType[] => {
	const { ONE_PAGER_AI_ANALYSIS, PROJECTS } = getRoutes(t);
	const isMultiSelectionOn = node.tree.selectedNodes.length > 1;

	const options = [
		{
			id: 3,
			onClick: () => {
				navigate(
					`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${node.data.parentItem}/edit/${node.id}`
				);
			},
			title: t('onePager.edit')
		},
		{
			id: 4,
			onClick: () => {
				navigate(
					`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${node.data.parentItem}/delete/${node.id}`,
					{
						state: {
							isLastItemInPosition: node.parent!.children!.length === 1
						}
					}
				);
			},
			title: t('onePager.deleteItem')
		}
	];

	return options.filter((option) => {
		if (node.data.status === 'CONFIRMED') {
			return option.id !== 1 && option.id !== 3;
		}

		if (isMultiSelectionOn) {
			return option.id !== 3;
		}

		if (node.data.status === 'DECLINED') {
			return option.id !== 2 && option.id !== 3;
		}

		return option;
	});
};

export default getProductsThreeDotsMenuOptions;
