import type { RefObject } from 'react';
import { useEffect } from 'react';

const useOnFocusOutHandler = (ref: RefObject<HTMLElement>, callback: () => void): void => {
	useEffect(() => {
		const listener = (evt: KeyboardEvent) => {
			if (evt.key === 'Tab') {
				if (!ref.current?.contains(document.activeElement as Node)) {
					callback();
				}
			}
		};
		const focusOutListener = () => {
			callback();
		};

		document.addEventListener('keyup', listener);
		window.addEventListener('blur', focusOutListener);
		return () => {
			document.removeEventListener('keyup', listener);
			window.removeEventListener('blur', focusOutListener);
		};
	}, [callback, ref]);
};

export default useOnFocusOutHandler;
