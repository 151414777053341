import type { RefObject } from 'react';
import { useEffect } from 'react';

const useOnEscapeHandler = (ref: RefObject<HTMLElement>, callback: () => void): void => {
	useEffect(() => {
		const refCopy = ref.current || null;
		const listener = (evt: KeyboardEvent) => {
			if (evt.key === 'Escape' && ref.current) {
				callback();
			}
		};

		ref.current?.addEventListener('keydown', listener);
		return () => {
			refCopy?.removeEventListener('keydown', listener);
		};
	}, [callback, ref]);
};

export default useOnEscapeHandler;
