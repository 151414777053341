import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import endOfYear from 'date-fns/endOfYear';
import previousFriday from 'date-fns/previousFriday';

import isWeekday from './isWeekday';
import type { ProjectDatesReducerActionsType, ProjectDatesReducerStateType } from '../types';

export const createFinishProjectDate = (date: Date | undefined) => {
	const endOfNextYear = endOfYear(addYears(date || new Date(), 1));
	return isWeekday(endOfNextYear) ? endOfNextYear : previousFriday(endOfNextYear);
};

export const createReminderDate = (date: Date | undefined) => {
	const twentiethDays = addDays(date || new Date(), 20);
	return isWeekday(twentiethDays) ? twentiethDays : previousFriday(twentiethDays);
};

export const filterWeekDay = (date: Date) => {
	const weekDayNum = date.getDay();

	if (weekDayNum === 6 || weekDayNum === 0) {
		date.setDate(weekDayNum === 0 ? date.getDate() - 2 : date.getDate() - 1);
	}

	return date;
};

const projectDatesSectionReducer = (
	state: ProjectDatesReducerStateType,
	action: ProjectDatesReducerActionsType
) => {
	let stateSupplierAppointment;
	switch (action.type) {
		case 'finishOfConstruction':
			return { ...state, stateFinishOfConstruction: action.value };
		case 'tenderReceivedDate':
			return { ...state, stateTenderReceivedDate: action.value };
		case 'offerFinishedDate':
			stateSupplierAppointment = new Date(action.value);
			stateSupplierAppointment.setDate(stateSupplierAppointment.getDate() - 3);
			stateSupplierAppointment = state.stateSupplierAppointment
				? state.stateSupplierAppointment
				: filterWeekDay(stateSupplierAppointment);

			return {
				...state,
				stateOfferFinishedDate: action.value,
				stateSupplierAppointment
			};
		case 'reminderDate':
			return { ...state, stateReminderDate: action.value };
		case 'setAll':
			return { ...state, ...action.value };
		case 'submissionDate':
			return {
				...state,
				stateFinishOfConstruction: createFinishProjectDate(action.value),
				stateReminderDate: createReminderDate(action.value),
				stateSubmissionDate: action.value
			};
		case 'supplierAppointment':
			return { ...state, stateSupplierAppointment: action.value };
		default:
			throw new Error();
	}
};

export default projectDatesSectionReducer;
