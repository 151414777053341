import classNames from 'classnames';

import styles from './NavLink.module.scss';
import type { NavLinkType } from '../../types';

const NavLink = ({ active, children }: NavLinkType) => (
	<div className={classNames(styles.navlink, { [styles.active]: active })}>{children}</div>
);

export default NavLink;
