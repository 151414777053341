import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export type ThreeDotsMenuKeyboardHandlerFunctionType<T> = {
	ref: RefObject<T>;
	newPosition: number;
	oldPosition: number | undefined;
	setLength?: (value: number) => void;
};

function useThreeDotsMenuKeyboardHandler<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: any, // considering that we do not know the future signature of handler function, we need to use any here,
	dataLength: number
): void {
	const [position, setPosition] = useState<number>(-1);
	const [oldPosition, setOldPosition] = useState<number | undefined>(undefined);
	const [length, setLength] = useState<number>(dataLength);

	useEffect(() => {
		const refCopy = ref.current || null;
		const listener = (event: KeyboardEvent) => {
			if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(event.key)) {
				if (event.key === 'Enter') {
					if (oldPosition) {
						setPosition(-1);
						setOldPosition(undefined);
						setLength(dataLength);
					}
					return;
				}

				if (event.key === 'ArrowRight') {
					setLength(dataLength);
					if (oldPosition) {
						handler({
							newPosition: oldPosition,
							oldPosition: undefined,
							ref
						});
						setPosition(oldPosition || 0);
						setOldPosition(undefined);
					} else {
						handler({
							newPosition: position,
							oldPosition,
							ref
						});
					}
					return;
				}

				if (event.key === 'ArrowLeft') {
					if (oldPosition) {
						return;
					}

					handler({
						newPosition: 0,
						oldPosition: position,
						ref
					});
					setPosition(0);
					setOldPosition(position);
					return;
				}
				const selectedElementPosition =
					event.key === 'ArrowUp' ? Math.max(position - 1, 0) : Math.min(position + 1, length - 1);

				setPosition(selectedElementPosition);
				handler({
					newPosition: selectedElementPosition,
					oldPosition,
					ref,
					setLength
				});
			}
		};
		if (ref.current) {
			ref.current.addEventListener('keydown', listener);
		}
		return () => {
			if (refCopy) {
				refCopy.removeEventListener('keydown', listener);
			}
		};
	}, [dataLength, handler, oldPosition, position, ref, length]);
}

export default useThreeDotsMenuKeyboardHandler;
