import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import AIFilterButtons from '../../components/AIFilterButtons';
import Loader from '../../components/Loader';
import TenderItemsAIRow from '../../components/TenderItemsAIRow';
import TenderItemsFooter from '../../components/TenderItemsFooter';
import TenderTree from '../../components/TenderTree';
import useFetchTenderRefinementHook from '../../hooks/useFetchTenderRefinementHook';
import getRoutes from '../../routes';
import globalStyles from '../../scss/global.module.scss';
import type { RootState } from '../../state';
import { selectFetchedProject, useFetchShoppingCardsQuery } from '../../state';
import { useFetchAIPredictionsFirstGenerationQuery } from '../../state/aiPredictions/aiPredictionsSlice';
import { useFetchSupplierProductProposalsQuery } from '../../state/supplierProductProposal/supplierProductProposal';
import { clearTenderItems, selectTenderItems } from '../../state/tenderItems/tenderItemsState';
import type { AKTType } from '../../types';
import styles from '../AiAnalysis/AiAnalysis.module.scss';

const TenderItems = () => {
	const { projectId, tenderItemId } = useParams();
	const { t } = useTranslation();
	const [pollingInterval, setPollingInterval] = useState<number>(5_000);
	const { data: project } = useSelector(selectFetchedProject(projectId));
	const akt = project?.responsibleOrganisation.sGArea as AKTType;
	const id = project?.gaeb?.contentUUID as string;
	const { data } = useFetchAIPredictionsFirstGenerationQuery(
		{ akt, id },
		{ pollingInterval, skip: !project }
	);
	const dispatch = useDispatch();
	const tenderItemsData = useSelector((state: RootState) => selectTenderItems(state, id as string));
	const { docRefinementId } = useFetchTenderRefinementHook({
		akt,
		id,
		skip: data?.predictionStatus !== 'DONE' || !tenderItemsData || false
	});

	const { data: supplierProductProposalData } = useFetchSupplierProductProposalsQuery(
		docRefinementId!,
		{ skip: !docRefinementId }
	);
	const { PROJECTS } = getRoutes(t);
	const loaderText = t('onePager.fetchingData', { totalCount: data?.itemCount });
	const navigate = useNavigate();
	useFetchShoppingCardsQuery(docRefinementId as string, { skip: !docRefinementId });
	useEffect(() => {
		if (data?.predictionStatus === 'DONE') {
			setPollingInterval(0);
		}
		if (data && tenderItemsData?.treeData?.length && tenderItemsData?.fetchingStatus === 'DONE') {
			const position = tenderItemId ?? '';
			navigate(`/${PROJECTS}/${projectId}/tender-items-2/${position}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (tenderItemsData) {
			dispatch(clearTenderItems({ contentUUID: id }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const isLoading =
		!tenderItemsData ||
		data?.predictionStatus !== 'DONE' ||
		!tenderItemsData?.treeData?.length ||
		tenderItemsData?.fetchingStatus === 'LOADING';

	return (
		<main className={classNames(globalStyles.mainContainer, styles.aiAnalysisWrapper)}>
			{tenderItemsData && tenderItemsData?.treeData?.length > 0 && (
				<AIFilterButtons contentUUID={id} />
			)}
			<article className={styles.aiItemsWrapper}>
				{isLoading && <Loader optionalClass={globalStyles.predictingLoader} text={loaderText} />}
				<TenderTree
					initialData={tenderItemsData?.treeData || []}
					renderer={TenderItemsAIRow}
					selector={(state: RootState) => selectTenderItems(state, id as string)}
				/>
				<TenderItemsFooter contentUUID={id} fileUUID={project?.gaeb?.fileUUID as string} />
			</article>
			<Outlet
				context={{ docRefinementId, supplierProductProposalsId: supplierProductProposalData?.tid }}
			/>
		</main>
	);
};

export default TenderItems;
