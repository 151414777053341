import type { CachedDataNames } from '../types';

const dbVersion = parseInt(process.env.REACT_APP_INDEXEDDB_VERSION!, 10);
const request: IDBOpenDBRequest = indexedDB.open('hulk', dbVersion);
request.onupgradeneeded = (event) => {
	const { newVersion, oldVersion } = event;
	if (oldVersion !== 0 && oldVersion !== newVersion) {
		indexedDB.deleteDatabase('hulk');
		window.location.reload();
	}

	const db: IDBDatabase = request.result;
	const dataBases = ['akts', 'countries', 'project-statuses', 'tender-types'] as const;
	dataBases.forEach((service: CachedDataNames) => {
		if (!db.objectStoreNames.contains(service)) {
			db.createObjectStore(service, { autoIncrement: true });
		}
	});
};

export const addDataToIndexedDB = <T>({
	data,
	storeName
}: {
	data: T;
	storeName: CachedDataNames;
}): Promise<T | string | null> =>
	new Promise((resolve) => {
		const request: IDBOpenDBRequest = indexedDB.open('hulk', dbVersion);

		request.onsuccess = () => {
			const db: IDBDatabase = request.result;
			const tx: IDBTransaction = db.transaction([storeName], 'readwrite');
			const store: IDBObjectStore = tx.objectStore(storeName);

			store.add(data);
			resolve('dataIsSet');
		};

		request.onerror = () => {
			resolve('');
		};
	});

export const getDataFromIndexedDB = <T>(storeName: CachedDataNames): Promise<T[]> =>
	new Promise((resolve) => {
		const request: IDBOpenDBRequest = indexedDB.open('hulk', dbVersion);

		request.onsuccess = () => {
			const db: IDBDatabase = request.result;
			const tx = db.transaction([storeName], 'readonly');
			const store = tx.objectStore(storeName);
			const res = store.getAll();
			res.onsuccess = () => {
				resolve(res.result);
			};
		};
	});

export const removeDataFromIndexedDB = (storeName: CachedDataNames): Promise<string> =>
	new Promise((resolve) => {
		const request: IDBOpenDBRequest = indexedDB.open('hulk', dbVersion);

		request.onsuccess = () => {
			const db: IDBDatabase = request.result;
			const tx = db.transaction([storeName], 'readwrite');
			const store = tx.objectStore(storeName);
			const res = store.clear();
			res.onsuccess = () => {
				resolve('success');
			};
		};
	});
