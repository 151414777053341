import type { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { AKT, Country, ProjectStatus } from '../../interfaces';
import type { CachedDataNames } from '../../types';
import getAktNameBySgArea from '../../utils/getAktNameBySgArea';
import { addDataToIndexedDB } from '../indexedDB';
import { indexedDBBaseQuery } from '../stateUtils';

const handleAddingToCache = ({
	cacheDataLoaded,
	storeName
}: {
	cacheDataLoaded: PromiseWithKnownReason<any, any>;
	storeName: CachedDataNames;
}) => {
	cacheDataLoaded.then(({ meta, data }) => {
		if (meta !== 'fromCache') {
			addDataToIndexedDB({
				data,
				storeName
			});
		}
	});
};

const cacheApi = createApi({
	baseQuery: indexedDBBaseQuery(),
	endpoints(builder) {
		return {
			fetchAKTs: builder.query<AKT[], {}>({
				async onCacheEntryAdded(arg, { cacheDataLoaded }) {
					handleAddingToCache({ cacheDataLoaded, storeName: 'akts' });
				},
				query: () => ({ url: 'services/projects/api/akts' }),
				transformResponse: (response) =>
					(response as AKT[]).map((akt) => ({
						...akt,
						name: getAktNameBySgArea(akt.sGArea)
					}))
			}),
			fetchCountries: builder.query<Country[], {}>({
				async onCacheEntryAdded(arg, { cacheDataLoaded }) {
					handleAddingToCache({ cacheDataLoaded, storeName: 'countries' });
				},
				query: () => ({ url: 'services/country/api/countries' })
			}),
			fetchProjectStatus: builder.query<ProjectStatus[], {}>({
				async onCacheEntryAdded(arg, { cacheDataLoaded }) {
					handleAddingToCache({ cacheDataLoaded, storeName: 'project-statuses' });
				},
				query: () => ({ url: 'services/projects/api/project-statuses' })
			}),
			fetchTenderTypes: builder.query({
				async onCacheEntryAdded(arg, { cacheDataLoaded }) {
					handleAddingToCache({ cacheDataLoaded, storeName: 'tender-types' });
				},
				query: () => ({ url: 'services/projects/api/tender-types' })
			})
		};
	},
	reducerPath: 'cache'
});
export const {
	useFetchAKTsQuery,
	useFetchCountriesQuery,
	useFetchProjectStatusQuery,
	useFetchTenderTypesQuery
} = cacheApi;

export const { select: selectAKTs } = cacheApi.endpoints.fetchAKTs;
export const { select: selectCountries } = cacheApi.endpoints.fetchCountries;
export const { select: selectProjectStatus } = cacheApi.endpoints.fetchProjectStatus;
export const { select: selectTenderTypes } = cacheApi.endpoints.fetchTenderTypes;

export { cacheApi };
