import styles from './AppLoader.module.scss';
import Loader from '../Loader';

const AppLoader = () => (
	<div className={styles.appLoader}>
		<div />
		<div />
		<div />
		<Loader optionalClass={styles.loader} />
	</div>
);

export default AppLoader;
