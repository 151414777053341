import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

function useKeyboardHandler<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: any, // considering that we do not know the future signature of handler function, we need to use any here,
	dataLength: number
): void {
	const [position, setPosition] = useState<number>(-1);

	useEffect(() => {
		const refCopy = ref.current || null;
		const listener = (event: KeyboardEvent) => {
			if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
				const selectedElementPosition =
					event.key === 'ArrowUp'
						? Math.max(position - 1, 0)
						: Math.min(position + 1, dataLength - 1);
				setPosition(selectedElementPosition);
				handler({
					key: event.key,
					newPosition: selectedElementPosition,
					ref
				});
			}
		};
		if (ref.current) {
			ref.current.addEventListener('keydown', listener);
		}
		return () => {
			if (refCopy) {
				refCopy.removeEventListener('keydown', listener);
			}
		};
	}, [dataLength, handler, position, ref]);
}

export default useKeyboardHandler;
