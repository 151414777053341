import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { RootState } from '../state';
import {
	selectFetchedProject,
	selectTenderRefinements,
	useFetchTenderRefinementsQuery
} from '../state';
import { selectTenderItems } from '../state/tenderItems/tenderItemsState';
import type { AKTType } from '../types';

const useTenderRefinementId = () => {
	const { projectId } = useParams();
	const { data: project } = useSelector(selectFetchedProject(projectId));
	const tenderItemsData = useSelector((state: RootState) =>
		selectTenderItems(state, project?.gaeb?.contentUUID as string)
	);

	const { data } = useSelector(
		selectTenderRefinements({
			akt: project?.responsibleOrganisation.sGArea as AKTType,
			id: project?.gaeb?.contentUUID as string
		})
	);
	const { data: fetchedData } = useFetchTenderRefinementsQuery(
		{
			akt: project?.responsibleOrganisation.sGArea as AKTType,
			id: project?.gaeb?.contentUUID as string
		},
		{
			skip: !project || !tenderItemsData || !!data?.tid
		}
	);

	return { tenderRefinementId: data?.tid ? (data?.tid as string) : (fetchedData?.tid as string) };
};

export default useTenderRefinementId;
