import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './EditableTenderItemAIPredictionRowCell.module.scss';
import { selectIsEditingPriceOn } from '../../state';
import type { ProductForTableType } from '../../types';
import Input from '../Input';
import PortaledDropdown from '../PortaledDropdown';

const isFieldEditable = (field: string, product: ProductForTableType) => {
	const { editableStatus, eiwaHawaType } = product;

	if (editableStatus === 'EIWA_EDIT' && eiwaHawaType === 'EIWA') {
		return !['quantity', 'positionType'].includes(field);
	}

	if (editableStatus === 'READ_ONLY' && ['None', 'HAWA'].includes(eiwaHawaType)) {
		return ![
			'quantity',
			'unit',
			'price',
			'priceType',
			'positionType',
			'rebate1',
			'rebate2',
			'rebate3',
			'rebateGroup',
			'quoteId'
		].includes(field);
	}

	if (editableStatus === 'FULL_EDIT' && ['None', 'HAWA'].includes(eiwaHawaType)) {
		return ![
			'quantity',
			'unit',
			'price',
			'priceType',
			'positionType',
			'rebate1',
			'rebate2',
			'rebate3',
			'rebateGroup',
			'quoteId',
			'shortText',
			'longText'
		].includes(field);
	}

	if (editableStatus === 'TEXT_EDIT' && eiwaHawaType === 'TEXT') {
		return !['shortText', 'longText'].includes(field);
	}

	return true;
};

const EditableTenderItemAIPredictionRowCell = ({
	cellId,
	showDropdown,
	shouldShowEditingFields,
	type,
	value,
	product,
	field
}: {
	cellId: string;
	field: string;
	showDropdown: boolean;
	shouldShowEditingFields: boolean;
	type: 'positionType' | 'priceType';
	value: string;
	product: ProductForTableType;
}) => {
	const { t } = useTranslation();
	const translatedValue = type === 'priceType' ? t(value ? `onePager.${value}` : '') : value;
	const isEditingPrices = useSelector(selectIsEditingPriceOn);
	const isEditable = !isFieldEditable(field, product);
	const isEditing = shouldShowEditingFields && isEditingPrices && isEditable;

	return (
		<>
			{!isEditing && translatedValue}
			{isEditing && showDropdown && (
				<PortaledDropdown
					cellId={cellId}
					portalId="divForDropdownPortal"
					type={type}
					value={value}
				/>
			)}
			{isEditing && !showDropdown && (
				<Input
					bordered
					defaultValue={value}
					name={cellId}
					optionalClass={styles.editableTenderItemInput}
					placeholder={value === 'undefined' ? '' : value}
				/>
			)}
		</>
	);
};

export default EditableTenderItemAIPredictionRowCell;
