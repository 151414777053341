import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import styles from './TenderItemsAiPredictionRow.module.scss';
import rowStyles from '../TenderItemsTableHeader/TenderItemsTableHeader.module.scss';

import 'rc-tooltip/assets/bootstrap_white.css';

const TitlePortalTooltip = ({ text, width }: { text: string; width: number }) => (
	<div
		className={classNames(rowStyles.longText, styles.longTextColumn)}
		style={{
			maxWidth: width,
			width
		}}
	>
		<Tooltip
			id="longtextTooltip"
			mouseEnterDelay={1}
			mouseLeaveDelay={0.2}
			overlay={<div style={{ maxHeight: '45vh', overflowY: 'auto' }}>{text}</div>}
			overlayInnerStyle={{ fontSize: '14px', maxWidth: width }}
			overlayStyle={{ opacity: 1 }}
			placement="topLeft"
			trigger={['hover']}
		>
			<div aria-describedby="longtextTooltip">{text}</div>
		</Tooltip>
	</div>
);

export default TitlePortalTooltip;
