import type { FormEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './TenderItemsColumnFilter.module.scss';
import useOnClickHandler from '../../hooks/useOnClickHandler';
import {
	selectIsExportColumnOn,
	useCreateUserSettingsPropertiesMutation,
	useEditUserSettingsPropertiesMutation,
	selectFetchedUserSettingsProperties
} from '../../state';
import { TENDER_ITEM_COLUMNS } from '../../utils/constants';
import getFormData from '../../utils/getFormData';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Icon from '../Icon';

const TenderItemsColumnFilter = ({
	onNewFilterSet
}: {
	onNewFilterSet: (turnedFilters: string[]) => void;
}) => {
	const filterRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();
	const [shownColumns, setShownColumns] = useState<string[]>([]);
	const [isFiltersOpened, setIsFilterOpened] = useState<boolean>(false);
	const currentExportSelectedVersion = useSelector(selectIsExportColumnOn);
	const storageName = `${TENDER_ITEM_COLUMNS}`;
	const userId = localStorage.getItem('HULK-CURRENT-NTUSER')!;
	const [createUserSettingsProperties] = useCreateUserSettingsPropertiesMutation();
	const [editUserSettingsProperties] = useEditUserSettingsPropertiesMutation();
	const { data: userSettingsProperties } = useSelector(
		selectFetchedUserSettingsProperties({
			propertyName: 'tenderItems',
			userId
		})
	);

	useEffect(() => {
		const storageValue = localStorage.getItem(storageName);
		if (!storageValue) {
			if (userSettingsProperties) {
				const columns = (userSettingsProperties.propertySettings as []).map(
					(property: { name: string; value: boolean }) => property.name
				);
				localStorage.setItem(storageName, columns.join(','));
				setShownColumns(columns);
			} else {
				localStorage.setItem(
					storageName,
					[
						'status',
						'position',
						'isTenderGroup',
						'productCategory',
						'shortText',
						'longText',
						'supplier'
					].join(',')
				);
				setShownColumns([
					'status',
					'position',
					'isTenderGroup',
					'productCategory',
					'shortText',
					'longText',
					'supplier'
				]);
			}
		} else {
			setShownColumns(storageValue.split(','));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storageName]);

	const setNewColumns = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const checkboxes: any = getFormData(event.target as HTMLFormElement);
		const clickedFilters = Object.keys(checkboxes).filter((checkbox) => checkboxes[checkbox]);
		if (currentExportSelectedVersion) {
			clickedFilters.push('export');
		}

		const propertySettings = clickedFilters.map((filter) => ({ name: filter, value: true }));
		const editData = {
			body: {
				propertySettings,
				userId
			},
			propertyName: 'tenderItems',
			userId
		};

		if (userSettingsProperties) {
			editUserSettingsProperties(editData);
		} else {
			createUserSettingsProperties(editData);
		}
		setShownColumns(clickedFilters);
		localStorage.setItem(storageName, clickedFilters.join(','));
		window.dispatchEvent(new Event('storage'));
		onNewFilterSet(clickedFilters);
		setIsFilterOpened(false);
	};

	const renderDropdownItems = () =>
		[
			'status',
			'position',
			'isTenderGroup',
			'ttItemId',
			'productCategory',
			'materialType',
			'positionType',
			'bomItemList',
			'shortText',
			'longText',
			'supplier',
			'quantity',
			'unit',
			'price',
			'priceType',
			'rebateGroup',
			'rebate1',
			'rebate2',
			'rebate3',
			'quoteId',
			'quoteDate',
			'tradingGroup'
		].map((tenderItemColumnFilter) => (
			<Checkbox
				key={tenderItemColumnFilter}
				defaultChecked={shownColumns.includes(tenderItemColumnFilter)}
				label={
					t(
						`thCell.${tenderItemColumnFilter === 'unit' ? 'unitOfMeasure' : tenderItemColumnFilter}`
					) || ''
				}
				name={tenderItemColumnFilter}
				optionalClass={styles.dropdownFilterCheckbox}
			/>
		));

	useOnClickHandler({
		insideHandler: () => {},
		outsideHandler: () => {
			if (filterRef.current) {
				setIsFilterOpened(false);
			}
		},
		ref: filterRef
	});

	const tenderItemsContainer = document.getElementById('tenderItemsContainer') as HTMLDivElement;

	return (
		<div ref={filterRef}>
			<button
				aria-label="Column Filter Settings"
				className={styles.dropdownFilterButton}
				onClick={() => setIsFilterOpened(!isFiltersOpened)}
				type="button"
			>
				<Icon name="settings" variant="white" />
			</button>
			{isFiltersOpened && (
				<form
					className={styles.dropdownFilter}
					id="applyFilters"
					onSubmit={setNewColumns}
					style={{ maxHeight: tenderItemsContainer.clientHeight - 42 }}
				>
					<div className={styles.dropdownSubmitButtonWrapper}>
						<span>{t('onePager.selectColumns')}</span>
						<Button
							form="applyFilters"
							text={t('addProject.submit')}
							type="submit"
							variant="primary"
						/>
					</div>
					<hr />
					{renderDropdownItems()}
				</form>
			)}
		</div>
	);
};

export default TenderItemsColumnFilter;
