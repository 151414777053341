const Logo = () => (
	<svg
		fill="none"
		height="145"
		viewBox="0 0 145 145"
		width="145"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			clipRule="evenodd"
			d="M0.175781 144.821H144.676V0.320312H0.175781V144.821Z"
			fill="#002D59"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M90.6487 77.0516V72.4479C90.6487 70.7 91.6784 69.5898 93.5589 69.3383C94.3054 69.2398 94.6781 69.3134 95.0757 69.3882V66.2695C92.3751 66.2695 91.0962 66.9504 90.5173 68.8591H90.4743V66.6762H86.5332V77.0539L90.6487 77.0516Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M111.223 73.7191C111.944 76.6791 114.854 77.5752 118.586 77.5752C121.506 77.5752 124.607 76.5047 124.607 73.6443C124.607 69.3113 115.726 70.9573 115.726 69.1663C115.726 68.4696 117.077 68.2884 117.742 68.2884C118.306 68.3099 119.233 68.4696 119.507 68.669C119.789 68.9035 120.129 69.1165 120.179 69.5639H124.114C123.413 67.3255 120.9 66.1055 117.914 66.1055C114.307 66.1055 111.471 67.2485 111.471 69.9378C111.471 74.0352 120.328 72.4504 120.328 74.3659C120.328 75.177 118.921 75.373 118.039 75.3356C117.442 75.3107 116.67 75.211 116.098 74.8134C115.751 74.639 115.404 74.145 115.327 73.7191H111.223Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M80.0347 74.1135C79.5023 75.0345 78.4816 75.269 77.4213 75.269C75.755 75.269 74.7218 74.3865 74.5666 72.7949H84.558C84.9749 69.81 82.9438 66.168 77.4213 66.168C73.1495 66.168 70.0762 68.1855 70.0762 72.0631C70.0762 75.7164 73.5143 77.6003 77.3692 77.6003C80.2862 77.6003 83.0095 76.5015 84.1298 74.1135H80.0347ZM74.6369 70.6052C74.8283 69.3818 75.8127 68.4166 77.3443 68.4166C78.8487 68.4166 79.8603 69.3818 80.0245 70.6052H74.6369Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M47.1919 66.7227V72.4207C47.184 73.6917 46.3446 74.9152 44.8209 74.9152C43.303 74.9152 42.4432 73.6736 42.4432 72.3086V66.7227L38.3027 66.7272V72.8183C38.3027 75.852 40.83 77.4731 43.3777 77.4731C44.8674 77.4731 46.2143 76.9508 47.2655 75.9018H47.3086V77.0754H51.3369V66.7227H47.1919Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M105.229 66.6448V72.3439C105.221 73.6138 104.382 74.8384 102.858 74.8384C101.34 74.8384 100.48 73.5968 100.48 72.2318V66.6448L96.3432 66.6289L96.3398 72.7404C96.3398 75.7741 98.8672 77.3952 101.415 77.3952C102.904 77.3952 104.251 76.8729 105.303 75.8251H105.346V76.9975H109.374V66.6448H105.229Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M20.2598 82.6049H124.004V81.4766H20.2598V82.6049Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M29.1968 70.9791C30.8507 70.9791 31.5077 71.5512 31.5077 72.795C31.5077 73.9154 30.8269 74.4127 28.175 74.4127H24.61V70.9791H29.1968ZM28.8648 65.3536C30.6898 65.3536 31.152 65.8928 31.152 66.722C31.152 67.6339 30.6898 68.3725 28.8648 68.3725H24.61V65.3536H28.8648ZM20.2441 77.2152L30.64 77.1971C34.7192 77.247 36.4615 75.1082 36.4706 72.9853C36.4785 71.1422 35.0432 69.6843 32.773 69.409L32.7538 69.4509C34.7192 69.1372 35.9766 67.839 35.8396 65.9528C35.7399 64.5946 35.118 62.4196 29.4698 62.5442H20.2441V77.2152Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M29.1968 70.9791C30.8507 70.9791 31.5077 71.5512 31.5077 72.795C31.5077 73.9154 30.8269 74.4127 28.175 74.4127H24.61V70.9791H29.1968ZM28.8648 65.3536C30.6898 65.3536 31.152 65.8928 31.152 66.722C31.152 67.6339 30.6898 68.3725 28.8648 68.3725H24.61V65.3536H28.8648ZM20.2441 77.2152L30.64 77.1971C34.7192 77.247 36.4615 75.1082 36.4706 72.9853C36.4785 71.1422 35.0432 69.6843 32.773 69.409L32.7538 69.4509C34.7192 69.1372 35.9766 67.839 35.8396 65.9528C35.7399 64.5946 35.118 62.4196 29.4698 62.5442H20.2441V77.2152Z"
			fill="white"
			fillRule="evenodd"
		/>
		<path
			clipRule="evenodd"
			d="M63.9108 67.4179C62.8618 66.4539 61.2396 66.1843 59.7908 66.1843C55.4193 66.2522 53.3496 69.5476 53.3496 72.0352C53.3496 75.5096 57.1241 77.5498 59.8474 77.5498C61.6452 77.5498 63.2368 77.0117 63.9867 75.9128H64.0366V77.168H68.1102V62.5898H63.9108V67.4179ZM57.7857 71.8415C57.7857 70.1525 59.2017 68.7841 60.9496 68.7841C62.6964 68.7841 64.1125 70.1525 64.1125 71.8415C64.1125 73.5305 62.6964 74.9001 60.9496 74.9001C59.2017 74.9001 57.7857 73.5305 57.7857 71.8415Z"
			fill="white"
			fillRule="evenodd"
		/>
	</svg>
);

export default Logo;
