import type { NodeApi, TreeApi } from 'react-arborist';

import type { TenderItem } from '../interfaces/UserRefinement2.types';
import type { TenderTreeFiltersType } from '../types';

const findDataOccurrence = (
	data: TreeApi<TenderItem>,
	originData: { totalItems: number; totalCheckedItems: number; totalUncheckedItems: number },
	filters: TenderTreeFiltersType
) => {
	const { searchTerm, suppliers } = filters;
	let allItems = 0;
	let toCheckItems = 0;
	let correctItems = 0;

	if (!searchTerm && !suppliers.length) {
		return {
			allItems: originData?.totalItems || 0,
			correctItems: originData?.totalCheckedItems,
			toCheckItems: originData?.totalUncheckedItems
		};
	}

	const checkTermOccurrence = (item: NodeApi<TenderItem>) => {
		const { isTenderGroup, longText, shortText, userItemRefinementSuppliers } = item.data;
		if (searchTerm && !suppliers.length) {
			return (
				shortText?.toLowerCase().includes(searchTerm) ||
				longText?.toLowerCase().includes(searchTerm)
			);
		}
		if (!searchTerm && suppliers.length) {
			return (
				!isTenderGroup &&
				userItemRefinementSuppliers?.some((supplier: any) =>
					suppliers.includes(supplier.supplierName)
				)
			);
		}
		return (
			(shortText?.toLowerCase().includes(searchTerm) ||
				longText?.toLowerCase().includes(searchTerm)) &&
			!isTenderGroup &&
			userItemRefinementSuppliers?.some((supplier: any) =>
				suppliers.includes(supplier.supplierName)
			)
		);
	};

	const createNewFilteredNumbers = (node: NodeApi<TenderItem>) => {
		allItems += 1;
		if (['DECLINED', 'CONFIRMED'].includes(node.data.status)) {
			correctItems += 1;
		} else {
			toCheckItems += 1;
		}
	};

	const filteredNodes = data.visibleNodes.filter(checkTermOccurrence);
	filteredNodes.forEach(createNewFilteredNumbers);
	return {
		allItems,
		correctItems,
		toCheckItems
	};
};

export default findDataOccurrence;
