import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../stateUtils';

const powerAutomateApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			callPowerAutomate: builder.mutation({
				query: ({ body, context, useCase }) => ({
					body: {
						...body,
						environment: process.env.REACT_APP_ENVIRONMENT
					},
					method: 'POST',
					url: `services/powerautomateconnector/api/power-automate/call/${useCase}/${context}`
				})
			})
		};
	},
	reducerPath: 'powerAutomate'
});

export const { useCallPowerAutomateMutation } = powerAutomateApi;

export { powerAutomateApi };
