import { useTranslation } from 'react-i18next';

import styles from './Filter.module.scss';

const FilterFormHeader = ({ id, type }: { id: string; type: string }) => {
	const { t } = useTranslation();
	return (
		<>
			<div className={styles.formHeader}>
				<span>{t(`filters.${type.includes('Date') ? 'text' : type}`)}</span>
				<input disabled id={`${id}-${type}`} type="reset" value={t('filters.reset') || ''} />
			</div>
			<hr />
		</>
	);
};

export default FilterFormHeader;
