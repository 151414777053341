import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './Filter.module.scss';
import { selectFetchedProject, selectShoppingCards, selectTenderRefinements } from '../../state';
import type { AKTType } from '../../types';
import type { FilterSubType } from '../../types/FilterType.types';
import { akts, tenderTypes } from '../../utils/constants';
import Checkbox from '../Checkbox';

const DropdownFilter = ({ subType }: { subType: FilterSubType }) => {
	const { t } = useTranslation();
	const { projectId } = useParams();
	const { data: project } = useSelector(selectFetchedProject(projectId));
	const { data } = useSelector(
		selectTenderRefinements({
			akt: project?.responsibleOrganisation.sGArea as AKTType,
			id: project?.gaeb?.contentUUID as string
		})
	);
	const { data: shoppingCards } = useSelector(selectShoppingCards(data?.tid as string));

	const renderDropdownItems = () => {
		switch (subType) {
			case 'tenderType':
				return tenderTypes.map((tenderType) => (
					<Checkbox
						key={tenderType}
						label={t(`tenderTypes.${tenderType}`)!}
						name={tenderType.toLowerCase()}
						optionalClass={styles.dropdownFilterCheckbox}
					/>
				));
			case 'akts':
				return akts.map((akt) => (
					<Checkbox
						key={akt}
						label={akt}
						name={akt}
						optionalClass={styles.dropdownFilterCheckbox}
					/>
				));
			case 'suppliers':
				return shoppingCards?.map((supplier) => (
					<Checkbox
						key={supplier.supplierInfo.supplierName}
						label={supplier.supplierInfo.supplierName}
						name={supplier.supplierInfo.supplierName}
						optionalClass={styles.dropdownFilterCheckbox}
					/>
				));
			case 'projectStatus':
				return ['CREATED', 'DRAFT'].map((status) => (
					<Checkbox
						key={status}
						label={t(`projectStatus.${status}`).toUpperCase()}
						name={status}
						optionalClass={styles.dropdownFilterCheckbox}
					/>
				));
			default:
				return '';
		}
	};

	return <div className={styles.dropdownFilter}>{renderDropdownItems()}</div>;
};

export default DropdownFilter;
