import type { Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';

import { getDataFromIndexedDB, removeDataFromIndexedDB } from './indexedDB';
import type { CachedDataNames } from '../types';

export const EXPORTTOSAP_URL = 'services/exporttosap/api/';
export const CONTACTS_URL = 'services/contacts/api/';
export const TENDER_DOC_URL = 'services/tenderdoccontent/api/';
export const TENDER_MANAGEMENT_URL = 'services/tenderdocmanager/api/';
export const USER_REFINEMENT_URL = 'services/userrefinement/api/';
export const SUPPLIER_COUMMUNICATION = 'services/suppliercommunication/api/';
export const FILE_PERSISTOR = 'services/filepersistor/api/';
export const USER_SETTINGS = 'services/usersettings/api/';
export const SUPPLIER_PRODUCT_PROPOSAL = 'services/supplierproductproposal/api/';

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers: Headers) => {
		headers.set('Authorization', `Bearer ${localStorage.getItem('jhi-authenticationToken')}`);
		return headers;
	}
});

export const indexedDBBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: process.env.REACT_APP_API_URL as string }
	): BaseQueryFn<{
		url: string;
	}> =>
	async ({ url }) => {
		const storeName = url.split('/')[url.split('/').length - 1] as CachedDataNames;
		const result = await getDataFromIndexedDB(storeName);
		const dataIsCorrect = result[0] && Array.isArray(result[0]) && result[0].length;
		if (!dataIsCorrect) {
			await removeDataFromIndexedDB(storeName);
		} else {
			return { data: result[0], meta: 'fromCache' };
		}

		try {
			const result = await fetch(baseUrl + url, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jhi-authenticationToken')}`
				},
				method: 'GET'
			})
				.then((result) => {
					if (result.status === 200) {
						return result.json();
					}
					return [];
				})
				.then((result) => result);
			return { data: result };
		} catch (err: any) {
			return {
				error: {
					data: err.response?.data || err.message,
					status: err.response?.status
				}
			};
		}
	};

export const rtkQueryErrorLogger: Middleware = () => (next) => (action: any) => {
	if (
		isRejectedWithValue(action) &&
		!(action.payload.status < 500 && action.payload.status >= 400) &&
		action.payload.originalStatus !== 200
	) {
		if (
			!(
				action.payload.data?.path?.includes('/api/property-settings/') &&
				action.payload.data?.detail === 'No value present'
			)
		) {
			if (action.meta.arg.endpointName === 'callPowerAutomate') {
				toast.error(
					`The CRM System seems to be unavailable Reason: ${
						action.payload.data?.error?.message || action.error?.message
					}`,
					{
						toastId: 'callPowerAutomateError'
					}
				);
			} else {
				toast.error(
					action.payload.data?.title ||
						action.payload.data?.message ||
						action.payload.data?.error ||
						action.error?.message,
					{ toastId: 'serverError' }
				);
			}
		}
	}
	return next(action);
};
