import { useEffect, useState } from 'react';

import { useFetchTenderRefinementsQuery } from '../state';
import type { AKTType } from '../types';

const useFetchTenderRefinementHook = ({
	akt,
	id,
	skip
}: {
	akt: AKTType;
	id: string;
	skip: boolean;
}) => {
	const [pollingInterval, setPollingInterval] = useState<number>(5_000);
	const { data } = useFetchTenderRefinementsQuery(
		{ akt, id },
		{
			pollingInterval,
			skip: !akt || !id || !pollingInterval || skip
		}
	);
	useEffect(() => {
		if (data?.status === 'DONE' || data?.status === 'STRUCTERED') {
			setPollingInterval(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return {
		docRefinementId: data?.tid,
		isDone: data?.status === 'DONE' || data?.status === 'STRUCTERED'
	};
};

export default useFetchTenderRefinementHook;
