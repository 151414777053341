import type {
	HTMLElementsWithValueType,
	HTMLElementsWithDefaultValueType,
	GenericObjectType
} from '../types';
// TODO find a way to test this function
const getFormData = <T>(form: HTMLFormElement): Partial<T> | {} => {
	if (form) {
		const formData: GenericObjectType<string | boolean | undefined> = {};
		Array.from(form).forEach((el) => {
			const element = el as HTMLElementsWithValueType;
			const defaultValueElement = el as HTMLElementsWithDefaultValueType;

			if (element.id) {
				const isElementCheckbox = (element as HTMLInputElement).type === 'checkbox';
				// eslint-disable-next-line no-nested-ternary
				formData[element.id] = isElementCheckbox
					? (element as HTMLInputElement).checked
					: element.getAttribute('data-id')
						? String(element.getAttribute('data-id'))
						: String(element.value) || defaultValueElement.defaultValue || undefined;
			}
		});
		return formData;
	}

	return {};
};

export default getFormData;
