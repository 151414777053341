import classNames from 'classnames';

import styles from './InformationText.module.scss';
import type { InformationTextType } from '../../types';
import Icon from '../Icon';

const InformationText = ({ error, info, optionalClass, required, text }: InformationTextType) => (
	<div className={classNames(styles.info, optionalClass, { [styles.error]: error || required })}>
		{(error || required || info) && (
			<Icon
				name={error || required ? 'error' : 'info'}
				optionalClass={styles.icon}
				variant={error || required ? 'error' : 'black'}
			/>
		)}
		{text}
	</div>
);

export default InformationText;
