import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Filter.module.scss';
import DatePicker from '../DatePicker';

const DateFilter = ({ id }: { id: string }) => {
	const { t } = useTranslation();
	const [fromValue, setFromValue] = useState<string>('');
	const [toValue, setToValue] = useState<string>('');

	useEffect(() => {
		const clearDates = ((evt: CustomEvent) => {
			const { type, value } = evt.detail;
			const receivedValue = value ? format(new Date(value), 'dd.MM.yyyy') : '';
			if (type.includes(id)) {
				if (evt.detail.type.includes('from')) {
					setFromValue(receivedValue);
				} else {
					setToValue(receivedValue);
				}
			}
		}) as EventListener;

		document.body.addEventListener('setDates', clearDates);

		return () => {
			document.body.removeEventListener('setDates', clearDates);
		};
	}, [id]);

	return (
		<>
			<div className={styles.dateFilter}>
				<div>
					<input id={`${id}-asc`} name={`${id}DateFilter`} type="radio" value="asc" />
					<label htmlFor={`${id}-asc`}>{t('filters.earliest')}</label>
				</div>
				<div>
					<input id={`${id}-desc`} name={`${id}DateFilter`} type="radio" value="desc" />
					<label htmlFor={`${id}-desc`}>{t('filters.latest')}</label>
				</div>
			</div>
			<div className={styles.formHeader}>
				<span>{t(`filters.selectRange`)}</span>
			</div>
			<hr />
			<div className={styles.datePickerWrap}>
				<div className={styles.datePicker}>
					<DatePicker
						formatPlaceholder="dd.MM.yy"
						label={t('filters.from')}
						mandatory={false}
						name={`${id}-from`}
						value={fromValue}
					/>
				</div>
				<div className={styles.datePicker}>
					<DatePicker
						formatPlaceholder="dd.MM.yy"
						label={t('filters.to')}
						mandatory={false}
						name={`${id}-to`}
						value={toValue}
					/>
				</div>
			</div>
			<div className={styles.formHeader}>
				<span>{t(`filters.simpleRange`)}</span>
			</div>
			<hr />
			<div className={styles.range}>
				<div>
					<input id={`${id}-lastWeek`} name={`${id}RangeFilter`} type="radio" value="lastWeek" />
					<label htmlFor={`${id}-lastWeek`}>{t('filters.lastWeek')}</label>
				</div>
				<div>
					<input id={`${id}-lastMonth`} name={`${id}RangeFilter`} type="radio" value="lastMonth" />
					<label htmlFor={`${id}-lastMonth`}>{t('filters.lastMonth')}</label>
				</div>
				<div>
					<input
						id={`${id}-lastThreeMonths`}
						name={`${id}RangeFilter`}
						type="radio"
						value="lastThreeMonths"
					/>
					<label htmlFor={`${id}-lastThreeMonths`}>{t('filters.lastThreeMonths')}</label>
				</div>
				<div>
					<input id={`${id}-lastYear`} name={`${id}RangeFilter`} type="radio" value="lastYear" />
					<label htmlFor={`${id}-lastYear`}>{t('filters.lastYear')}</label>
				</div>
			</div>
		</>
	);
};

export default DateFilter;
