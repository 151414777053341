import { graphConfig, loginRequest, msalInstance } from './authConfig';

async function getMSGraph() {
	const account = msalInstance.getActiveAccount();
	if (!account) {
		throw Error(
			'No active account! Verify a user has been signed in and setActiveAccount has been called.'
		);
	}

	const response = await msalInstance.acquireTokenSilent({
		...loginRequest,
		account,
		forceRefresh: true
	});

	const headers = new Headers();
	const bearer = `Bearer ${response.accessToken}`;

	headers.append('Authorization', bearer);

	const options = {
		headers,
		method: 'GET'
	};

	return (
		fetch(graphConfig.graphMeEndpoint, options)
			.then((response) => response.json())
			// eslint-disable-next-line no-console
			.catch((error) => console.log(error))
	);
}

// eslint-disable-next-line import/prefer-default-export
export { getMSGraph };
