import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import getRoutes from '../../routes';
import styles from '../Header/Header.module.scss';
import Icon from '../Icon';
import NavLink from '../NavLink';
import Logo from '../WordAndImageBrand/Logo';

const HeaderPlaceholder = () => {
	const { t } = useTranslation();
	const { PROJECTS_CREATE, PROJECTS } = getRoutes(t);

	const currentPath = window.location.pathname.split('/')[1];
	const notificationsNumber = 0;
	const navLinks = [
		// {
		// 	active: !currentPath,
		// 	children: <a href="/">{t('common.dashboard')}</a>,
		// 	id: 1
		// },
		{
			active: currentPath === PROJECTS,
			children: <a href={`/${PROJECTS}`}>{t('common.projects')}</a>,
			id: 2
		}
	];
	return (
		<header className={styles.header}>
			<div className={classNames(styles.metaNavigationWrapper)}>
				<div className={styles.metaMenuContainer}>
					<div className={styles.leftChildren} />
					<div className={styles.rightChildren}>
						<div className={styles.username} />
						<div />
					</div>
				</div>
			</div>
			<div className={classNames(styles.mainNavigationWrapper)}>
				<div className={styles.navbarContainer}>
					<div className={styles.navbarLeft}>
						<div style={{ display: 'inline-block', height: '145px' }}>
							<Logo />
						</div>
						<div>
							{navLinks.map((navLink) => (
								<NavLink key={navLink.id} {...navLink} />
							))}
						</div>
					</div>
					<div className={styles.navbarRight}>
						<div key={0} className={styles.rightChildrenWrapper}>
							<NavLink active={false} id={1}>
								<a href={PROJECTS_CREATE}>+ {t('common.creation')}</a>
							</NavLink>
							<div className={styles.alertIcon}>
								<Icon height="25px" name="notificationBell" variant="white" width="25px" />
								{notificationsNumber > 0 && (
									<span className={styles.notificationNumber}>{notificationsNumber}</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					backgroundColor: '#ffffff',
					height: '40px',
					width: '100%'
				}}
			/>
		</header>
	);
};

export default HeaderPlaceholder;
