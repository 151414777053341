import type { Configuration, PopupRequest } from '@azure/msal-browser';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		authority: process.env.REACT_APP_IDM_URL,
		clientId: `${process.env.REACT_APP_IDM_CLIENT_ID}`,
		postLogoutRedirectUri: `${process.env.REACT_APP_URL}logout`,
		redirectUri: ''
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage
	},
	system: {
		allowNativeBroker: false // Disables WAM Broker
	}
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: ['User.Read', 'GroupMember.Read.All']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};
