import type { FormEvent } from 'react';
import { useState, useLayoutEffect } from 'react';

import DateFilter from './DateFilter';
import DropdownFilter from './DropdownFilter';
import styles from './Filter.module.scss';
import FilterFormHeader from './FilterFormHeader';
import NumericFilter from './NumericFilter';
import TextFilter from './TextFilter';
import type { FilterType } from '../../types';
import capitalize from '../../utils/capitalize';
import Icon from '../Icon';

const Filter = ({ id, eventName, subType, textFilterId, type }: FilterType) => {
	const [value, setValue] = useState<number>(0);
	const [left, setLeft] = useState<number>(-40);

	useLayoutEffect(() => {
		const { offsetLeft, offsetWidth } = document
			.getElementById(id)!
			.closest('th') as HTMLTableHeaderCellElement;
		if (offsetLeft + offsetWidth > window.innerWidth - offsetWidth) {
			setLeft(-191);
		}
	}, [id]);
	const renderElement = () => {
		switch (type) {
			case 'text':
				return <TextFilter id={textFilterId || ''} subType="TextFilter" />;
			case 'dropdown':
				return <DropdownFilter subType={subType!} />;
			case 'date':
				return <DateFilter id={subType!} />;
			case 'numeric':
				return <NumericFilter id={id} />;
			default:
				return '';
		}
	};

	const informTableAboutFiltering = (e: FormEvent) => {
		const { id: elementId, value: elementValue } = e.target as HTMLInputElement;
		const filterProjectsBySelectedFilterEvent = new CustomEvent(eventName, {
			detail: {
				id,
				type: e.type === 'reset' ? `${e.type}${capitalize(id)}` : elementId,
				value: elementValue
			}
		});
		document.body.dispatchEvent(filterProjectsBySelectedFilterEvent);

		(document.getElementById(`${id}-${subType || type}`) as HTMLInputElement).disabled = false;

		if (['asc', 'desc'].includes(elementValue)) {
			[
				'submissionDate-submissionDate',
				'cRMID-numeric',
				'reminderDate-reminderDate',
				'projectAssignee-text'
			].forEach((filter) => {
				(document.getElementById(filter) as HTMLInputElement).disabled =
					filter !== `${id}-${subType || type}`;
			});
		}

		if (e.type.includes('reset')) {
			setValue(value + 1);
		}
	};

	return (
		<section className={styles.filter}>
			<button aria-label="FilterDown Icon" type="button">
				<Icon name="filter_down" />
			</button>
			<form
				key={`${value}`}
				className={styles.filterContent}
				id={id}
				onChange={informTableAboutFiltering}
				onReset={informTableAboutFiltering}
				style={{ left }}
			>
				<FilterFormHeader id={id} type={subType || type} />
				<div className={styles.filterContent}>{renderElement()}</div>
			</form>
		</section>
	);
};

export default Filter;
