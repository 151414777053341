import { createEntityAdapter } from '@reduxjs/toolkit';

import type {
	ContactPerson,
	Customer,
	HulkUser,
	PMDSupplier,
	Product,
	Project
} from '../interfaces';

export const projectsAdapter = createEntityAdapter<Project>();
export const initialProjectsState = projectsAdapter.getInitialState();

export const productsAdapter = createEntityAdapter<Product>();
export const initialProductsState = productsAdapter.getInitialState();

export const contactPersonAdapter = createEntityAdapter<ContactPerson>();

export const hulkUsersAdapter = createEntityAdapter({
	selectId: (hulkUser: HulkUser) => hulkUser.userPrincipalName
});

export const initialHulkUsersState = hulkUsersAdapter.getInitialState();
export const initialContactPersonState = contactPersonAdapter.getInitialState();

export const customerAdapter = createEntityAdapter<Customer>();
export const initialCustomerState = customerAdapter.getInitialState();

export const supplierAdapter = createEntityAdapter<PMDSupplier>();
export const initialSupplierState = supplierAdapter.getInitialState();

export const tenderItemsAdapter = createEntityAdapter({
	selectId: (tenderItem: any) => tenderItem.sorting,
	// @ts-ignore
	sortComparer: (a, b) => a.sorting > b.sorting
});
export const initialTenderItemsState = tenderItemsAdapter.getInitialState();
