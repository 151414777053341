const stringToPrecisionFloat = (stringNumber: string): number => {
	if (Number.isNaN(parseFloat(stringNumber))) {
		return 0;
	}
	const number = stringNumber.includes(',')
		? parseFloat(parseFloat(stringNumber.replace(',', '.').replaceAll(' ', '')).toFixed(2))
		: parseFloat(parseFloat(stringNumber.replaceAll(' ', '')).toFixed(2));

	return parseFloat(parseFloat(`${number}`).toFixed(2));
};

export default stringToPrecisionFloat;
