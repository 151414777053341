import type { TFunction } from 'i18next';
import type { NodeApi } from 'react-arborist';
import type { NavigateFunction } from 'react-router-dom';

import type { TenderItem } from '../interfaces';
import getRoutes from '../routes';
import type { ThreeDotsMenuOptionType } from '../types';

const shouldRenderMenuItem = (data: TenderItem): boolean =>
	['DECLINED', 'CONFIRMED'].includes(data.status);

const declineItem = (
	data: TenderItem,
	navigateToPath: string,
	navigate: NavigateFunction,
	editOneTenderItem: (value: { status: string; tid: string; id: string }) => void,
	isMultiSelectionOn: boolean,
	redirectionId: string
) => {
	const groupHasChildren = data.isTenderGroup && data.children?.length;
	if (groupHasChildren || isMultiSelectionOn) {
		navigate(`/${navigateToPath}/${redirectionId}/declineWarning`);
	} else {
		navigate(`/${navigateToPath}/${data.tid}`);
		editOneTenderItem({
			id: data.tid,
			status: 'DECLINED',
			tid: data.refinementId!
		});
	}
};

const canAddProduct = (data: TenderItem) => !shouldRenderMenuItem(data) && !data.isTenderGroup;

const handleConfirmClick =
	(
		data: TenderItem,
		isMultiSelectionOn: boolean,
		navigate: NavigateFunction,
		node: NodeApi<TenderItem>,
		redirectionId: string,
		editOneTenderItem: (value: { id: string; status: string; tid: string }) => void,
		navigatePath: string
	) =>
	() => {
		if ((data.isTenderGroup && data.children?.length) || isMultiSelectionOn) {
			navigate(`/${navigatePath}/${redirectionId}/confirmWarning`);
			node.tree.deselectAll();
		} else {
			navigate(`/${navigatePath}/${data.tid}`);
			editOneTenderItem({
				id: data.tid,
				status: 'CONFIRMED',
				tid: data.refinementId!
			});
		}
	};

const getTenderItemThreeDotsMenuOptions = (
	node: NodeApi<TenderItem>,
	t: TFunction,
	projectId: string,
	navigate: NavigateFunction,
	editOneTenderItem: (value: { status: string; tid: string; id: string }) => void,
	contentUUID: string,
	onClickRemoveAllSuppliers: (node: NodeApi<TenderItem>) => void
): ThreeDotsMenuOptionType[] => {
	const { ONE_PAGER_AI_ANALYSIS, PROJECTS } = getRoutes(t);
	const { data } = node;
	const returnMenuItem = shouldRenderMenuItem(data);
	const shouldRenderConfirm = !returnMenuItem && data.children && !data.isTenderGroup;
	const isMultiSelectionOn = node.tree.selectedNodes.length > 1;
	const redirectionId = isMultiSelectionOn
		? node.tree.selectedNodes.map((nodes) => nodes.id).join(',')
		: data.tid;
	const onClick = () => {
		onClickRemoveAllSuppliers(node);
		const event = new CustomEvent('triggerSetPrice', {
			detail: {
				type: 'triggerSetPrice',
				value: ''
			}
		});
		document.body.dispatchEvent(event);
	};

	return [
		...(!returnMenuItem
			? [
					{
						id: `decline-${data.tid}`,
						onClick: () => {
							declineItem(
								data,
								`${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}`,
								navigate,
								editOneTenderItem,
								isMultiSelectionOn,
								redirectionId
							);
						},
						title: t('onePager.decline')
					}
				]
			: []),
		...(shouldRenderConfirm
			? [
					{
						id: `confirm-${data.tid}`,
						onClick: handleConfirmClick(
							data,
							isMultiSelectionOn,
							navigate,
							node,
							redirectionId,
							editOneTenderItem,
							`${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}`
						),
						title: t('onePager.confirm')
					}
				]
			: []),
		...(returnMenuItem
			? [
					{
						id: `unlock-${data.tid}`,
						onClick: () => {
							if ((data.isTenderGroup && data.children) || isMultiSelectionOn) {
								navigate(
									`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${redirectionId}/unlockWarning`
								);
								node.tree.deselectAll();
							} else {
								navigate(`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${data.tid}`);
								editOneTenderItem({
									id: data.tid,
									status: 'IN_PROGRESS',
									tid: data.refinementId!
								});
							}
						},
						title: t('onePager.unlock')
					}
				]
			: []),
		...(canAddProduct(data) && !isMultiSelectionOn
			? [
					{
						id: `addProducts-${data.tid}`,
						onClick: () =>
							navigate(
								`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${data.tid}/addProducts/${contentUUID}`
							),
						title: t('onePager.addProduct')
					}
				]
			: []),
		...(canAddProduct(data)
			? [
					{
						id: `removeSuppliers-${data.tid}`,
						onClick,
						title: t('onePager.removeAllSuppliers')
					}
				]
			: []),
		...(!isMultiSelectionOn
			? [
					{
						id: 6,
						onClick: () => {
							navigate(`/${PROJECTS}/${projectId}/${ONE_PAGER_AI_ANALYSIS}/${data.tid}/preview`);
						},
						title: t('onePager.preview')
					}
				]
			: [])
	];
};

export default getTenderItemThreeDotsMenuOptions;
