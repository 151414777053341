import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, FILE_PERSISTOR } from '../stateUtils';

const filePersistorApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			deleteFile: builder.mutation<{}, string>({
				query: (id) => ({
					method: 'DELETE',
					url: `${FILE_PERSISTOR}persisted-files/${id}`
				})
			}),
			downloadFile: builder.query({
				query: (id) => ({
					responseHandler: (response) => response.blob(),
					url: `${FILE_PERSISTOR}download/file/${id}`
				})
			}),
			fetchFile: builder.query({
				query: (id) => ({
					url: `${FILE_PERSISTOR}persisted-files/${id}`
				})
			})
		};
	},
	reducerPath: 'filePersistor'
});

export const { useLazyDownloadFileQuery, useLazyFetchFileQuery, useDeleteFileMutation } =
	filePersistorApi;

export { filePersistorApi };
