import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import tenderItemsTableHeaderStyles from './TenderItemsTableHeader.module.scss';
import { selectIsExportColumnOn } from '../../state';
import type { TableHeaderCellType } from '../../types';
import { TENDER_ITEM_COLUMNS } from '../../utils/constants';
import getTenderItemsHeaderOptions from '../../utils/getTenderItemsHeaderOptions';
import Filter from '../Filter/Filter';
import styles from '../TableHeader/TableHeader.module.scss';
import TenderItemsColumnFilter from '../TenderItemsColumnFilter';

const TenderItemsTableHeader = () => {
	const [turnedFilters, setTurnedFilters] = useState<string[]>([]);
	const storageName = `${TENDER_ITEM_COLUMNS}`;
	const currentExportSelectedVersion = useSelector(selectIsExportColumnOn);
	const isOnArticleOfferPage = window.location.pathname.includes('article-and-offer');

	useEffect(() => {
		const storageValue = localStorage.getItem(storageName);
		if (storageValue) {
			const turnedOnFilters = storageValue.split(',');
			if (currentExportSelectedVersion) {
				setTurnedFilters([...turnedOnFilters, 'export']);
			} else {
				setTurnedFilters(turnedOnFilters.filter((filter) => filter !== 'export'));
			}
		}
	}, [currentExportSelectedVersion, storageName]);

	return (
		<tr className={styles.tableHeader}>
			<th
				aria-label="Empty Cell"
				className={tenderItemsTableHeaderStyles.emptyHeaderTableCell}
				id="emptyTableHeaderCell"
				tabIndex={-1}
			>
				&nbsp;
			</th>
			{getTenderItemsHeaderOptions()
				.filter((option) => turnedFilters.includes(option.dataStructure))
				.map((col: Omit<TableHeaderCellType, 'width'>) => (
					<th
						key={`${col.headerCellText}`}
						className={classNames(tenderItemsTableHeaderStyles[col.dataStructure])}
						id={`tenderItemHeaderCell-${col.dataStructure}`}
						tabIndex={0}
						title={col.headerCellText}
					>
						<div className={styles.cellText}>{col.headerCellText}</div>
						{col.dataStructure === 'supplier' && !isOnArticleOfferPage && (
							<Filter
								eventName="filteringTenderItems"
								id="tenderItemsSupplierFilter"
								subType="suppliers"
								type="dropdown"
							/>
						)}
					</th>
				))}

			<th
				aria-label="Header Cell Settings"
				id="tenderItemHeaderCell-settings"
				style={{ position: 'relative', width: '40px' }}
			>
				<TenderItemsColumnFilter onNewFilterSet={setTurnedFilters} />
			</th>
		</tr>
	);
};

export default TenderItemsTableHeader;
