import classNames from 'classnames';
import { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import type { ForwardedRef } from 'react';

import styles from './Dropdown.module.scss';
import useKeyboardHandler from '../../hooks/useKeyboardHandler';
import useOnClickHandler from '../../hooks/useOnClickHandler';
import useOnEscapeHandler from '../../hooks/useOnEscapeHandler';
import useOnFocusOutHandler from '../../hooks/useOnFocusOutHandler';
import type {
	DropdownType,
	DropdownInputType,
	KeyboardHandlerFunctionType,
	SelectionItemType
} from '../../types';
import simulateUserSetValue from '../../utils/simulateUserEvent';
import Icon from '../Icon';
import InformationText from '../InformationText';

const DropdownInput = forwardRef((prop: DropdownInputType, ref: ForwardedRef<HTMLInputElement>) => {
	const { name, label, required, value, ...rest } = prop;

	return (
		<>
			<label htmlFor={name}>{label}</label>
			<input ref={ref} id={name} readOnly required={required} value={value} {...rest} />
		</>
	);
});

const Dropdown = ({
	data,
	direction,
	error,
	inactive = false,
	isMultiple = false,
	isOpen = false,
	label,
	name,
	optionalClass,
	placeholder,
	selected,
	onClickDropdownItem,
	required,
	warning,
	...rest
}: DropdownType) => {
	const [value, setValue] = useState<SelectionItemType[]>([]);

	const dropdownRef = useRef<HTMLDetailsElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const closeDropdown = useCallback(() => {
		if (dropdownRef.current) {
			dropdownRef.current.open = false;
		}
	}, []);

	useOnClickHandler({
		insideHandler: () => {},
		outsideHandler: () => {
			if (dropdownRef.current && dropdownRef.current.open) {
				dropdownRef.current.open = false;
			}
		},
		ref: dropdownRef
	});

	useEffect(() => {
		if (inputRef?.current?.defaultValue) {
			setValue(data.filter((akt) => akt.id === inputRef?.current?.defaultValue));
		}
		if (selected) {
			setValue(selected);
		}
	}, [data, selected]);

	function selectElementWithKeyboardArrows<T extends HTMLDetailsElement>({
		ref,
		newPosition
	}: KeyboardHandlerFunctionType<T>): void {
		const dropdownElement = ref?.current?.childNodes[1].childNodes[newPosition] as
			| ChildNode
			| HTMLButtonElement
			| undefined;
		if (ref?.current?.open === false) {
			setValue([
				{
					// @ts-ignore
					id: dropdownElement.getAttribute('data-id'),
					// @ts-ignore
					value: dropdownElement.textContent
				}
			]);
		} else {
			(dropdownElement as HTMLElement)?.focus();
		}
	}

	useKeyboardHandler(dropdownRef, selectElementWithKeyboardArrows, data.length);

	const onClickOption = (option: SelectionItemType) => () => {
		const shouldToggleOption = value.find((value) => value.id === option.id);
		const newSelectedValue = shouldToggleOption
			? [...value.filter((value) => value.id !== option.id)]
			: [...value, option];
		const newStateValue = isMultiple ? newSelectedValue : [option];
		setValue(newStateValue);

		if (onClickDropdownItem) {
			onClickDropdownItem(newStateValue);
		} else if (inputRef.current) {
			simulateUserSetValue(inputRef.current, newStateValue.map((v) => v.id).join(','));
		}

		if (!isMultiple && dropdownRef.current) {
			closeDropdown();
		}
	};

	useOnFocusOutHandler(dropdownRef, closeDropdown);

	useOnEscapeHandler(dropdownRef, closeDropdown);

	return (
		<div className={classNames(styles.dropdownWrapper, optionalClass)}>
			<DropdownInput
				ref={inputRef}
				label={label}
				name={name}
				required={required}
				value={value.map((v) => v.id).join(',')}
				{...rest}
			/>
			<details
				ref={dropdownRef}
				className={classNames(styles.dropdownMenu, {
					[styles.inactive]: inactive,
					[styles.error]: error,
					[styles.up]: direction === 'up'
				})}
				open={isOpen}
			>
				<summary className={classNames(styles.selectedOption, { [styles.up]: direction === 'up' })}>
					<div className={styles.placeholder}>
						{value.length > 0 ? (
							value.map((option) => option.value).join(', ')
						) : (
							<span>{placeholder}</span>
						)}
					</div>
					<div className={styles.icon}>
						<Icon name="navigation_down" />
					</div>
				</summary>
				{!inactive && (
					<div className={classNames(styles.dropdown, { [styles.up]: direction === 'up' })}>
						{data.map((option: SelectionItemType) => (
							<button
								key={option.id}
								className={classNames(styles.option, {
									[styles.selected]: value.find((v) => `${v.id}` === `${option.id}`),
									[styles.up]: direction === 'up'
								})}
								data-id={option.id}
								onClick={onClickOption(option)}
								tabIndex={0}
								type="button"
							>
								{option.value}
							</button>
						))}
					</div>
				)}
			</details>
			{error && <InformationText error={!!error} text={error} />}
			{warning && (
				<InformationText info={!!warning} optionalClass={styles.warning} text={warning} />
			)}
		</div>
	);
};

export default Dropdown;
