import { t } from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export const akts = ['SGC', 'SGV', 'SGN', 'SGS', 'SGE', 'SGW'];

export const tenderTypes = ['BUDERUS', 'COMPETITION', 'NEUTRAL', 'TRADING'];

export const dropdownTypesOptions = {
	positionType: [
		{
			id: 'ZAGN',
			value: t('onePager.ZAGN')
		},
		{
			id: 'ZEVT',
			value: t('onePager.ZEVT')
		},
		{
			id: 'ZALT',
			value: t('onePager.ZALT')
		},
		{
			id: 'ZTAP',
			value: t('onePager.ZTAP')
		},
		{
			id: 'ZATX',
			value: t('onePager.ZATX')
		}
	],
	priceType: [
		{
			id: 'NET',
			value: t('onePager.NET')
		},
		{
			id: 'GROSS',
			value: t('onePager.GROSS')
		}
	]
};

export const TENDER_ITEM_COLUMNS = 'tenderItemColumns';
