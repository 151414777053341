import { createApi, retry } from '@reduxjs/toolkit/query/react';

import type { ChildPrediction, TenderType } from './types';
import { baseQuery } from '../stateUtils';
import { setTenderItems } from '../tenderItems/tenderItemsState';
import { createInitialTreeStructure, mapOverItems } from '../tenderItems/treeDataUtils';

const recurisve = (data: ChildPrediction[]) => {
	const normalizedItems: { entities: { [key: string]: any }; ids: string[] } = {
		entities: {},
		ids: []
	};

	const innerFunction = (
		data: ChildPrediction[],
		parentUUID: undefined | string,
		parentPosition: number
	) => {
		data.forEach((childPrediction: ChildPrediction, index: number) => {
			const tender: TenderType = {
				isOffer: childPrediction.offer,
				isTenderGroup: childPrediction.tenderItemType === 'bo-q-ctgies',
				itemPredictionId: childPrediction.id,
				longText: childPrediction.longText,
				numOfEiwa: 0,
				numOfHawa: 0,
				offer: false,
				parentItem: parentUUID,
				parentUUID,
				position: parentPosition ? `${parentPosition}.${index + 1}` : index + 1,
				probability: childPrediction.offerProbability,
				productCategory: childPrediction.productCategoryPredictions[0]?.categoryName || '',
				productPredictions: childPrediction.productPredictions,
				quantity: 1,
				refinementId: '',
				shortText: childPrediction.shortText,
				sorting: 0,
				status: '',
				tid: childPrediction.tenderItemId!,
				unit: '',
				userItemRefinementCategories: [],
				userItemRefinementSuppliers: [],
				userRefinementProducts: []
			};
			if (childPrediction.tenderItemId) {
				normalizedItems.ids.push(childPrediction.tenderItemId as string);
				normalizedItems.entities[childPrediction.tenderItemId as string] = tender;
				if (childPrediction.childPredictions.length) {
					innerFunction(
						[...childPrediction.childPredictions].sort((a, b) =>
							a.listOrderIndex > b.listOrderIndex ? 1 : -1
						),
						childPrediction.tenderItemId as string,
						tender.position as number
					);
				}
			}
		});
	};
	innerFunction(
		[...data].sort((a, b) => (a.listOrderIndex > b.listOrderIndex ? 1 : -1)),
		'',
		0
	);
	return normalizedItems;
};

const aiPredictionsApi = createApi({
	baseQuery: retry(baseQuery, { maxRetries: 3 }),
	endpoints(builder) {
		return {
			fetchAIPredictions: builder.query({
				async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
					const { data } = await queryFulfilled;
					const normalizedItems = recurisve(data.childPredictions);
					const { treeStructure, parentlessIds } = createInitialTreeStructure(normalizedItems);
					const normalizedData = {
						[id]: {
							data: treeStructure,
							fetchingStatus: 'LOADING',
							ids: normalizedItems.ids,
							parentlessIds,
							status: 'DONE',
							totalCheckedItems: 0,
							totalItems: data.itemCount,
							totalUncheckedItems: 0,
							treeData: parentlessIds?.length ? mapOverItems(treeStructure, parentlessIds) : []
						}
					};
					dispatch(setTenderItems(normalizedData as any));
				},
				query: ({ id, akt }) => `services/aipredictions2/api/predictions/ensure/for/${akt}/${id}`
			}),
			fetchAIPredictionsFirstGeneration: builder.query({
				async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
					const { data } = await queryFulfilled;

					const getNormalizedData = async () => {
						const normalizedData: { entities: { [key: string]: any }; ids: string[] } = {
							entities: {},
							ids: []
						};
						const getChildPredictions = async (
							childPrediction: ChildPrediction,
							parentTender: TenderType
						) => {
							const { data: childPredictions } = await dispatch(
								aiPredictionsApi.endpoints.fetchAIPredictionsFirstGenerationParent.initiate({
									id: childPrediction.id as string,
									tenderItemId: ''
								})
							);
							await Promise.all(
								childPredictions.map(async (childPrediction: ChildPrediction, index: number) => {
									normalizedData.ids.push(childPrediction.tenderItemId as string);
									const tender: any = {
										isOffer: childPrediction.offer,
										isTenderGroup: childPrediction.tenderItemType === 'bo-q-ctgies',
										itemPredictionId: childPrediction.id,
										longText: childPrediction.longText,
										numOfEiwa: 0,
										numOfHawa: 0,
										offer: false,
										parentItem: parentTender.tid,
										parentUUID: parentTender.tid,
										position: `${parentTender.position}.${index + 1}`,
										probability: childPrediction.offerProbability,
										productCategory: childPrediction.productCategoryPredictions[0]?.categoryName,
										productPredictions: childPrediction.productPredictions,
										quantity: 1,
										refinementId: '',
										shortText: childPrediction.shortText,
										sorting: 0,
										status: '',
										tid: childPrediction.tenderItemId,
										unit: '',
										userItemRefinementCategories: [],
										userItemRefinementSuppliers: [],
										userRefinementProducts: []
									};
									normalizedData.entities[tender.tid as string] = tender;

									if (childPrediction.tenderItemType === 'bo-q-ctgies') {
										await getChildPredictions(childPrediction, tender);
									}
								})
							);
						};

						await Promise.all(
							data.childPredictions.map(async (childPrediction: ChildPrediction, index: number) => {
								normalizedData.ids.push(childPrediction.tenderItemId as string);
								const tender: TenderType = {
									isOffer: childPrediction.offer,
									isTenderGroup: childPrediction.tenderItemType === 'bo-q-ctgies',
									itemPredictionId: childPrediction.id,
									longText: childPrediction.longText,
									numOfEiwa: 0,
									numOfHawa: 0,
									offer: false,
									parentItem: '',
									parentUUID: '',
									position: index + 1,
									probability: childPrediction.offerProbability,
									productCategory:
										childPrediction.productCategoryPredictions[0]?.categoryName || '',
									productPredictions: childPrediction.productPredictions,
									quantity: 1,
									refinementId: '',
									shortText: childPrediction.shortText,
									sorting: 0,
									status: '',
									tid: childPrediction.tenderItemId!,
									unit: '',
									userItemRefinementCategories: [],
									userItemRefinementSuppliers: [],
									userRefinementProducts: []
								};
								normalizedData.entities[tender.tid as string] = tender;
								if (childPrediction.tenderItemType === 'bo-q-ctgies') {
									await getChildPredictions(childPrediction, tender);
								}
							})
						);

						return normalizedData;
					};
					const normalizedData = await getNormalizedData();
					const { treeStructure, parentlessIds } = createInitialTreeStructure(normalizedData);
					const tenders = {
						[id]: {
							data: treeStructure,
							fetchingStatus: 'LOADING',
							ids: normalizedData.ids,
							parentlessIds,
							status: 'DONE',
							totalCheckedItems: 0,
							totalItems: data.itemCount,
							totalUncheckedItems: 0,
							treeData: parentlessIds?.length ? mapOverItems(treeStructure, parentlessIds) : []
						}
					};
					dispatch(setTenderItems(tenders as any));
				},
				query: ({ id, akt }) =>
					`services/aipredictions2/api/predictions/ensure/for/${akt}/${id}/first-generation?predictionLevel=Partial`
			}),
			fetchAIPredictionsFirstGenerationParent: builder.query<
				any,
				{ id: string; tenderItemId: string | null }
			>({
				query: ({ id }) => `services/aipredictions2/api/item-predictions/first-generation/${id}`
			}),
			fetchAIPredictionsForItems: builder.query({
				query: ({ predictionId, tenderId }) =>
					`services/aipredictions2/api/item-predictions/from/${predictionId}/${tenderId}`
			}),
			fetchAIPredictionsForItemsByIds: builder.query<any, { predictionId: string; ids: string[] }>({
				query: ({ predictionId, ids }) => ({
					body: {
						ids
					},
					url: `services/aipredictions2/api/item-predictions/from/${predictionId}/ids`
				})
			}),
			fetchAIPredictionsStatus: builder.query({
				query: (id) => `services/aipredictions/api/predictions/no-items/${id}`
			}),
			fetchAIProductCategoryPrediction: builder.query({
				query: (id) => `services/aipredictions2/api/product-category-predictions/${id}`
			}),
			fetchAIProductsPredictions: builder.query({
				query: (id) => `services/aipredictions2/api/product-predictions/${id}`
			}),
			fetchAIProductsPredictionsBulk: builder.query({
				async queryFn(ids, _queryApi, _extraOptions, baseQuery) {
					const response = await Promise.all(
						ids.map((id: string) =>
							baseQuery(`services/aipredictions2/api/product-predictions/${id}`)
						)
					);

					const products = response.map((product: any) => product.data);
					return { data: products };
				}
			})
		};
	},
	reducerPath: 'aiPredictions'
});

export const {
	useFetchAIPredictionsStatusQuery,
	useFetchAIPredictionsQuery,
	useFetchAIProductsPredictionsQuery,
	useFetchAIProductsPredictionsBulkQuery,
	useFetchAIPredictionsForItemsQuery,
	useFetchAIProductCategoryPredictionQuery,
	useLazyFetchAIPredictionsForItemsByIdsQuery,
	useLazyFetchAIPredictionsFirstGenerationParentQuery,
	useFetchAIPredictionsFirstGenerationQuery
} = aiPredictionsApi;

export const selectAiProductsPredictions =
	aiPredictionsApi.endpoints.fetchAIProductsPredictionsBulk.select;

export { aiPredictionsApi };
